const config = {
  api: {
    host: 'https://clubedit-api.kuvo.com',
  },
  layout: {
    wide: `(min-width: 700px)`,
    topwide: `(min-width: 700px)`,
    mobile: `(max-width: 699px)`,
  },
  report: {
    min_year: 2017,
  },
};

export default config;
