import { createPortal } from "react-dom";
import { useRef, useEffect } from "react";
import "./style.scss";

const ContentModal = ({ children, closeModal, isOpen, ...props }) => {
  const bgRef = useRef();

  const onWheel = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (bgRef.current) {
      bgRef.current.addEventListener("wheel", onWheel, { passive: false });
      bgRef.current.addEventListener("touchmove", onWheel, { passive: false });
    }
    return () => {
      if (bgRef.current) {
        bgRef.current.removeEventListener("wheel", onWheel);
        bgRef.current.removeEventListener("touchmove", onWheel);
      }
    };
  }, [isOpen]);

  if (!isOpen) {
    return "";
  }

  return createPortal(
      <div className="ContentModal" {...props}>
        <div className="bg" onClick={closeModal} ref={bgRef}></div>
        <div className="panel">
          <button className="close-btn" onClick={closeModal}></button>

          <div className="panel-content">{children}</div>
        </div>
      </div>,
      document.getElementById("modal-room")
  );
};

export default ContentModal;
