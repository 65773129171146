import styled from 'styled-components';
import config from '../../../config';
import {HeaderLogo} from '../HeaderNav';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const Area = styled.div`
  height: 412px;
  position: relative;
  z-index: 2;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 58px;
  padding-left: 20px;
  padding-right: 20px;
  @media ${config.layout.wide} {
    height: 570px;
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const ATCLink = styled.a`
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  margin-top: 30px;

  &.pioneerdj {
    margin-top: 48px;
  }

  @media ${config.layout.wide} {
    margin-top: 50px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-around;
  margin-top: 35px;
  gap: 8px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

  @media ${config.layout.wide} {
    //justify-content: space-between;
    margin-top: 95px;
  }

  .spacer {
    flex: 0 0 auto;
  }

  a {
    @media ${config.layout.wide} {

    }
    flex: 0 0 auto;
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
  }
`;

const FooterLogoLinkElm = styled.a`
  display: block;
  position: relative;
  left: 8px;

  svg {
    width: 146px;
    @media ${config.layout.wide} {
      width: 220px;
      left: 18px;
    }

    .hlst0 {
      fill: #ffffff;
    }
  }

  @media ${config.layout.mobile} {

  }

  @media ${config.layout.wide} {

  }
`;

const Tagline = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
  @media ${config.layout.wide} {
    font-size: 20px;
    margin-top: 7px;
  }
`;

function FooterLogoLink() {
  const location = useLocation();
  const navigate = useNavigate();
  //console.log(location);

  if (location.pathname == '/') {
    // 同じページ内に既にいる
    return (
        <FooterLogoLinkElm
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              if (location.hash) {
                navigate('/');
              } else {
                window.scrollTo(0, 0);
              }
            }}
        >
          <HeaderLogo/>
        </FooterLogoLinkElm>
    );
  }

  return (
      <FooterLogoLinkElm href="/">
        <HeaderLogo/>
      </FooterLogoLinkElm>
  );
}

export default function Footer() {
  return (
      <Area
      >
        <FooterLogoLink/>
        <Tagline>Empowering Music Through Data</Tagline>
        <Links>
          <a
              href="https://alphatheta.com/company/privacy-policy/"
              target="_blank"
              rel="noopener"
          >Privacy policy</a>
          <div className="spacer">|</div>
          <a
              href="https://alphatheta.com/company/terms-of-use/"
              target="_blank"
              rel="noopener"
          >Terms of use</a>
          <div className="spacer">|</div>
          <a
              href="https://alphatheta.com/company/trademarks/"
              target="_blank"
              rel="noopener"
          >Trademarks </a>


        </Links>
        <ATCLink href="https://pioneerdj.com/" target="_blank" rel="noopener"
                 className={'pioneerdj'}>
          Pioneer DJ
        </ATCLink>
        <ATCLink href="https://alphatheta.com/" target="_blank" rel="noopener"
                 className={'alphatheta'}>
          &copy; AlphaTheta Corporation
        </ATCLink>
      </Area>
  );
}
