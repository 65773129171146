import config from '../config.js';
import axios from 'axios';

//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
export function makeParams(data) {
  return Object.entries(data).reduce((params, [key, value]) => {
    if (value !== null && value !== undefined) {
      params.append(key, value);
    }

    return params;
  }, new URLSearchParams());
}

export function makeURL(path) {
  return new URL(path, config.api.host).href;
}

function buildOptions({params, basic = null, bearer = null}) {
  const options = {
    params,
  };
  if (basic) {
    options.auth = basic;
  }
  if (bearer) {
    options.headers = {
      Authorization: `Bearer ${bearer}`,
    };
  }
  return options;
}

export function fetchPost({
  path,
  data = {},
  params = {},
  basic = null,
  bearer = null,
}) {
  const queryURL = makeURL(path);
  return axios.post(
      queryURL,
      data,
      buildOptions({
        params: makeParams(params),
        basic,
        bearer,
      }),
  ).then((res) => res.data);
}

export function fetchPut({
  path,
  data = {},
  params = {},
  basic = null,
  bearer = null,
}) {
  const queryURL = makeURL(path);
  return axios.put(
      queryURL,
      data,
      buildOptions({
        params: makeParams(params),
        basic,
        bearer,
      }),
  ).then((res) => res.data);
}

export function fetchDelete({
  path,
  data = {},
  params = {},
  basic = null,
  bearer = null,
}) {
  const queryURL = makeURL(path);
  return axios.delete(queryURL, {
    data,
    ...buildOptions({
      params: makeParams(params),
      basic,
      bearer,
    }),
  }).then((res) => res.data);
}

export function fetchGet({path, params = {}, basic = null, bearer = null}) {
  const queryURL = makeURL(path);

  return axios.get(
      queryURL,
      buildOptions({
        params: makeParams(params),
        basic,
        bearer,
      }),
  ).then((res) => res.data);
}
