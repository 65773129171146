import {fetchDelete, fetchGet, fetchPost, fetchPut} from './utils.js';

export async function login({venue_code, email, password}) {
  const res = await fetchPost({
    path: '/api/v1/auth/login',
    data: {
      venue_code,
      email,
      password,
    },
    params: {},
  });
  return res || null;
}

export async function getAllFAQs() {
  const res = await fetchPost({
    path: '/api/v1/faqs/search',
    data: {
      categories: ['new-kuvo'],
    },
    params: {},
  });
  return res && res.faqs || [];
}

export async function sendInquiry({name, email, category, description}) {
  const res = await fetchPost({
    path: `/api/v1/contacts/kuvo`,
    data: {
      name,
      email,
      category,
      description,
    },
  });
  return res || null;
}

export async function sendVenueInquiry({name, email, country}) {
  const res = await fetchPost({
    path: `/api/v1/contacts/kuvo-venue`,
    data: {
      name,
      email,
      country,
    },
  });
  return res || null;
}

export async function getCountryMaster() {
  const res = await fetchGet({
    path: '/api/v1/countries',
    data: {},
    params: {},
  });
  return (res && res.countries) || [];
}