import styled from "styled-components";
import config from "../../../config";

export const SubInner = styled.div`
  @media ${config.layout.wide} {
    width: 500px;
    margin: 0 auto;
  }
`;

const TabHeaderArea = styled.div`
  padding-bottom: 10px;
  @media ${config.layout.wide} {
  }
`;
const TabHeaderButtons = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #d7d7d7;
  @media ${config.layout.wide} {
  }
`;

const TabHeaderButton = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  text-align: center;
  height: 60px;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
            180deg,
            rgba(98, 98, 98, 1) 0%,
            rgba(0, 0, 0, 1) 100%
    );
    opacity: 1;
    transition: opacity 0.15s linear;
  }
  &:hover {
    &:not(.disabled) {
      &::before {
        opacity: 0.7;
      }
    }
  }
  &.disabled {
    cursor: default;
    &::before {
      opacity: 0.05;
    }
  }
  &:first-child {
    &::before {
      border-radius: 6px 0 0 0;
    }
  }
  &:last-child {
    &::before {
      border-radius: 0 6px 0 0;
    }
  }

  h3 {
    width: 100%;
    z-index: 1;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
  }
  &.selected {
    height: 80px;
    cursor: default;

    &::before {
      background: #fff;
      border: 1px solid #d7d7d7;
      border-bottom: none;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    h3 {
      color: #525252;
    }
    &::after {
      content: "";
      position: absolute;
      width: 14.142px;
      height: 14.142px;
      background-color: #fff;
      border-bottom: 1px solid #d7d7d7;
      border-right: 1px solid #d7d7d7;
      transform: translateX(-8px) rotateZ(45deg);
      left: 50%;
      bottom: -8.5px;
    }
  }

  @media ${config.layout.wide} {
  }
`;

/**
 *
 * buttons: [
 *  {
 *    title: string,
 *    disabled: boolean (false),
 *  }
 * ],
 * selectedIndex: number,
 * onClickTab: function(selectedIndex){}
 */
export function TabHeader({
  buttons = [],
  selectedIndex = 0,
  onClickTab = null,
  ...props
}) {
  return (
      <TabHeaderArea {...props}>
        <TabHeaderButtons>
          {buttons.map((b, i) => {
            return (
                <TabHeaderButton
                    key={i}
                    className={`clickable ${b.disabled ? "disabled" : ""} ${
                        selectedIndex == i ? "selected" : ""
                    }`.trim()}
                    onClick={() => {
                      if (onClickTab && selectedIndex != i && !b.disabled) {
                        onClickTab(i);
                      }
                    }}
                    style={{
                      width: Math.floor((1 / buttons.length) * 10000) / 100 + "%",
                    }}
                >
                  <h3>{b.title}</h3>
                </TabHeaderButton>
            );
          })}
        </TabHeaderButtons>
      </TabHeaderArea>
  );
}

const SectionTitleWrap = styled.h3`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  span {
    flex: 0 0 auto;
  }
  pointer-events: none;
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100px;
    flex: 1 0 auto;
    background-color: #d9d9d9;
    margin-left: 10px;
  }

  @media ${config.layout.wide} {
    font-size: 16px;
    line-height: 21px;
  }
`;

export function SectionTitle({ children, ...props }) {
  return <SectionTitleWrap {...props}>{children}</SectionTitleWrap>;
}

const InputLabelElm = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  @media ${config.layout.wide} {
    font-size: 16px;
    line-height: 21px;
  }
  i {
    color: #ff0000;
  }
`;

export function InputLabel({ required = false, children }) {
  return (
      <InputLabelElm>
        {children}
        {required ? <i>*</i> : ""}
      </InputLabelElm>
  );
}

const RequiredLabelElm = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: #000000;
  i {
    color: #ff0000;
    margin-right:0.2em;
    display: inline-block;
  }
`;

export function RequiredLabel() {
  return (
      <RequiredLabelElm>
        Fields marked with <i>*</i> must be filled in
      </RequiredLabelElm>
  );
}

export const FormInput = styled.input`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 46px;
  padding: 0px 20px !important;
  background-color: #fff;
  border-radius: 4px;
  &::placeholder {
    color: #d9d9d9;
  }
  @media ${config.layout.wide} {
    font-size: 14px;
  }
`;

export const FormTextArea = styled.textarea`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 200px;
  padding: 20px !important;
  background-color: #fff;
  border-radius: 4px;
  &::placeholder {
    color: #d9d9d9;
  }
  @media ${config.layout.wide} {
    height: 300px;
    font-size: 14px;
  }
`;

export const AddMoreButton = styled.button`
  height: 46px;
  line-height: 46px;
  font-weight: bold;
  font-size: 14px;
  padding: 0 44px 0 20px;
  border-radius: 23px;
  position: relative;
  border-color: #d7d7d7;
  background-color: transparent;
  transition: background-color 0.15s linear, color 0.15s linear;

  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 4px;
    position: absolute;
    right: 20px;
    background-color: #525252;
    top: 50%;
    margin-top: -2px;
    transition: background-color 0.15s linear, color 0.15s linear;
  }
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 14px;
    position: absolute;
    right: 25px;
    background-color: #525252;
    top: 50%;
    margin-top: -7px;
    transition: background-color 0.15s linear, color 0.15s linear;
  }
  &:hover {
    color: #fff;
    border-color: #525252;
    background-color: #525252;
    &::before {
      background-color: #fff;
    }
    &::after {
      background-color: #fff;
    }
  }
`;

export const RemoveItemButton = styled.button`
  height: 46px;
  width: 46px;
  padding: 0;
  border-radius: 50%;
  position: relative;
  border-color: #d7d7d7;
  background-color: transparent;
  transition: background-color 0.15s linear, color 0.15s linear;
  &::before {
    content: "";
    display: block;
    width: 14px;
    height: 4px;
    position: absolute;
    left: 50%;
    margin-left: -7px;
    background-color: #525252;
    top: 50%;
    margin-top: -2px;
    transition: background-color 0.15s linear, color 0.15s linear;
  }
  &[disabled] {
    opacity: 0.3;
  }
  &:hover {
    &:not([disabled]) {
      color: #fff;
      border-color: #525252;
      background-color: #525252;
      &::before {
        background-color: #fff;
      }
    }
  }
`;

export const PageMainTitle = styled.h1`
  height: 124px;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 26px;
  }
  @media ${config.layout.wide} {
    height: 134px;
  }
`;

export const PageSubTitle = styled.h2`
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  height: 80px;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    width: 100%;
    font-weight: bold;
    font-size: 30px;
  }
  @media ${config.layout.wide} {
    height: 93px;
    span {
      font-size: 40px;
    }
  }
`;
