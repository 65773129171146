import React, {createContext, useEffect, useReducer, useContext} from 'react';
import {
  getAllFAQs,
} from '../../api';

const AppContext = createContext();

const initialState = {
  header: true,
  faqs: [],
};

function reducer(state, action) {
  console.log(state, action);
  let newState = null;
  switch (action.type) {
    case 'set_header': {
      newState = {
        ...state,
        header: action.header,
      };
    }
      break;
    case 'set_faqs': {
      newState = {
        ...state,
        faqs: action.faqs,
      };
    }
      break;
    default:
      throw new Error(`${action.type} is not defined.`);
  }
  //updateHeaderState(newState);
  return newState;
}

function updateHeaderState(state) {
  if (!state.header) {
    document.getElementsByTagName('body')[0].classList.remove('header');
  } else {
    document.getElementsByTagName('body')[0].classList.add('header');
  }
}

export const AppContextProvider = ({api, children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //useEffect(() => {
  //  (async () => {
  //  })();
  //}, []);

  const showHeader = (forceHeaderLink = false) => {
    /*
    dispatch({
      type: "set_header",
      header: true,
    });
    */
    window.forceHeaderLink = forceHeaderLink;
    let classList = document.getElementsByTagName('body')[0].classList;
    classList.add('header');

    if (forceHeaderLink) {
      classList.add('header-link');
    } else {
      classList.remove('header-link');
    }
  };

  const hideHeader = () => {
    /*
    dispatch({
      type: "set_header",
      header: false,
    });
    */
    window.forceHeaderLink = false;
    let classList = document.getElementsByTagName('body')[0].classList;
    classList.remove('header');
    classList.remove('header-link');
    classList.remove('registration-wizard');
  };

  const refreshFAQs = async () => {
    try {
      const faqs = await getAllFAQs();
      faqs.forEach(f => {
        f.category_values = new Set(f.categories.map(c => c.name));
        f.search = f.question_en.toLowerCase() + ' ' +
            f.answer_en.toLowerCase();
      });
      dispatch({
        type: 'set_faqs',
        faqs,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const setTitle = (title) => {
    if (!title) {
      document.title = 'KUVO';
    } else {
      document.title = `${title} | KUVO`;
    }
  };

  const value = {
    state,
    dispatch,
    showHeader,
    hideHeader,
    refreshFAQs,
    setTitle,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  return useContext(AppContext);
};

export default AppContext;
