import ContentModal from '../ContentModal';
import {useCustomDialog} from '../../../contexts/CustomDialogContext';

export default function CustomDialog() {
  const {
    customDialog,
    onCloseButtonClicked,
    onButtonClicked,
    customDialogState,
  } = useCustomDialog();

  if (!customDialogState.show) {
    return '';
  }

  return (
      <ContentModal
          closeModal={() => {
            onCloseButtonClicked();
          }}
          isOpen={true}
          customdialog={1}
          fortop={customDialogState.for_top ? 1 : 0}
          nobuttons={
            !customDialogState.buttons || !customDialogState.buttons.length
                ? 1
                : 0
          }
      >
        <div className="customdialog-content">
          {customDialogState.description ? (
              <div className="description">{customDialogState.description}</div>
          ) : (
              ''
          )}
          {customDialogState.description_element ? (
              <div className="description_element">
                <div>{customDialogState.description_element}</div>
              </div>
          ) : (
              ''
          )}
          {customDialogState.buttons && customDialogState.buttons.length ? (
              <div className="buttons">
                {customDialogState.buttons.map((b, i) => {
                  return (
                      <button
                          key={i}
                          className={b.positive
                              ? 'dark-button'
                              : 'light-button'}
                          onClick={() => {
                            onButtonClicked(i);
                          }}
                      >
                  <span style={{
                    whiteSpace: 'nowrap',
                  }}>{b.label}</span>
                      </button>
                  );
                })}
              </div>
          ) : (
              ''
          )}
        </div>
      </ContentModal>
  );
}
