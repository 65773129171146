import styled from 'styled-components';
import config from '../../../config';

const Page = styled.div`
  padding-top: 165px;
  @media ${config.layout.wide} {
    padding-top: 177px;
  }
`;

const PageFeatureTitle = styled.div`
  position: relative;

  h2 {
    text-align: center;
    font-size: 50px;
    font-weight: bold;

    @media ${config.layout.wide} {
      font-size: 70px;
    }
  }
`;

export default function NotFoundPage() {

  return (
      <Page>
        <PageFeatureTitle>
          <h2>Not found</h2>
        </PageFeatureTitle>
      </Page>
  );
}
