import {useEffect, useState} from 'react';
import {useApp} from '../../../contexts/AppContext';
import {sendVenueInquiry, getCountryMaster} from '../../../api';
import styled from 'styled-components';
import config from '../../../config';
import {
  PageMainTitle,
  SubInner,
  SectionTitle,
  InputLabel,
  FormTextArea,
  RequiredLabel,
  FormInput,
} from '../../atoms/CommonParts';
import DBDPullDown from '../../atoms/DBDPullDown';
import {
  useCustomDialog,
  ButtonLayout,
} from '../../../contexts/CustomDialogContext';
import {Link, useLocation} from 'react-router-dom';
import headerImagePC from './djm_head_pc.webp';
import headerImageSP from './djm_head_sp.webp';
import djmLogoPC from './djmonitor_pc.webp';
import djmLogoSP from './djmonitor_sp.webp';
import mrtImage from './mrt.webp';
import chartImagePC from './chart_pc.webp';
import chartImageSP from './chart_sp.webp';

const Page = styled.div`

`;

const Inner = styled.div`
  margin-top: 47px;
  @media ${config.layout.wide} {
    margin-top: 84px;
    padding: 0 64px;
  }
`;

const ContentsSection = styled.section`

`;

const ContentsHeader = styled.div`
  height: 330px;
  position: relative;
  background-image: url("${headerImageSP}");
  background-size: cover;
  background-position: center;

  @media ${config.layout.wide} {
    height: 420px;
    background-image: url("${headerImagePC}");
  }
`;

const ContentsHeaderText = styled.div`

  position: absolute;
  left: 0;
  width: 100%;
  top: 58px;
  text-align: center;
  font-size: 40px;
  line-height: 54px;
  font-weight: bold;
  color: #fff;

  @media ${config.layout.wide} {
    font-size: 70px;
    line-height: 84px;
    text-align: left;
    left: 50px;
    top: 72px;
    width: auto;
  }
`;

const ContentsHeaderLogo = styled.div`

  position: absolute;
  right: 0;
  width: 100%;
  bottom: 55px;
  text-align: center;

  svg {
    width: 130px;
  }

  @media ${config.layout.wide} {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: unset;
    svg {
      width: 160px;
    }
  }
`;

const DJMSection = styled.section`
  margin-top: 70px;
  @media ${config.layout.wide} {
    margin-top: 100px;
    display: flex;
  }
`;

const DJMLogoBlockPC = styled.div`
  width: 50%;
  flex: 0 0 auto;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
`;

const DJMLogoDescriptionBlock = styled.div`

  @media ${config.layout.mobile} {
    padding: 0 20px;
    .djm-logo-sp {
      display: block;
      width: 100vw;
      margin-left: -20px;
      margin-top: 40px;
    }
  }

  h2 {
    font-size: 40px;
    line-height: 49px;
    font-weight: bold;
  }

  h4 {
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 40px;
  }

  .explanation {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }

  ul {
    margin-top: 30px;
  }

  @media ${config.layout.wide} {
    padding: 0 50px;

    h2 {
      font-size: 70px;
      line-height: 84px;
    }

    h4 {
      font-size: 32px;
      font-weight: bold;
      margin-top: 30px;
    }

    .explanation {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      margin-top: 10px;
    }

    ul {
      margin-top: 32px;
    }

    .dark-button {
      margin-top: 40px;
    }
  }
`;

const SectionTextArea = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;

  p {
    margin-bottom: 1.5em;
  }

  ul {
    padding-left: 16px;

    li {
      margin-bottom: 30px;
      list-style: disc;
    }
  }

  @media ${config.layout.wide} {
    ul {
      padding-left: 16px;

      li {
        font-weight: bold;
        margin-bottom: 32px;
        list-style: disc;
      }
    }
  }
`;

const KUVOSection = styled.section`

  @media ${config.layout.mobile} {
    padding: 0 20px;
  }
  margin-top: 100px;

  h3 {
    font-size: 40px;
    line-height: 49px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  @media ${config.layout.wide} {

    margin-top: 70px;
    padding: 0 50px;

    h3 {
      font-size: 48px;
      line-height: 63px;
      font-weight: bold;
      margin-bottom: 50px;
    }
  }
`;

const MissionSection = styled.section`

  margin-top: 100px;
  padding: 0 20px;

  @media ${config.layout.wide} {
    margin-top: 70px;
    padding: 0 50px;
  }

`;

const MissionSectionHeader = styled.div`

  .pre-title {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
  }

  h3 {
    margin-top: 2px;
    font-size: 48px;
    line-height: 56px;
    font-weight: bold;
  }

  @media ${config.layout.wide} {
    .pre-title {
      font-size: 32px;
      line-height: 32px;
      font-weight: bold;
    }

    h3 {
      margin-top: 8px;
      font-size: 48px;
      line-height: 56px;
      font-weight: bold;
    }

  }

`;

const MissionContentArea = styled.div`

  margin-top: 32px;

  .image-block {
    margin-top: 54px;
    position: relative;
    margin-left: -20px;
    width: 100vw;

    img {
      width: 100%;
      display: block;
    }
  }

  @media ${config.layout.wide} {
    margin-top: 50px;
    display: flex;
    margin-left: -50px;
    width: calc(100% + 100px);
    position: relative;

    .text-block {
      width: 50%;
      padding: 0 50px;
    }

    .image-block {
      width: 50%;
      margin-top: unset;
      margin-left: unset;

      img {
        width: 100%;
        display: block;
      }
    }
  }
`;

const HowWorksSection = styled.section`
  margin-top: 100px;
  padding: 0 20px 70px;
  border-bottom: 1px solid #D9D9D9;

  @media ${config.layout.wide} {
    margin-top: 70px;
    padding: 0 50px 70px;
  }

  & > h4 {
    font-size: 32px;
    line-height: 43px;
    font-weight: bold;

  }

  .description {
    margin-top: 32px;
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;

    @media ${config.layout.wide} {
      margin-top: 50px;
    }
  }

  .chart {
    margin-top: 50px;
    width: 100%;
    margin-bottom: 0;

    @media ${config.layout.wide} {
      margin-top: 45px;
    }
  }
`;

const HowWorksItems = styled.div`
  @media ${config.layout.wide} {
    margin-top: 44px;
    display: flex;
  }
`;

const HowWorksItem = styled.div`
  margin-top: 40px;

  @media ${config.layout.wide} {
    margin-top: 0;
    width: 50%;
    flex: 0 0 auto;
    padding-right: 30px;
    &:last-child {
      padding-right: 0;
      padding-left: 30px;
      border-left: 1px solid #000;
    }
  }

  h5 {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
  }
`;

function Contents() {
  return (
      <ContentsSection>
        <ContentsHeader>
          <ContentsHeaderText>
            KUVO Powered by<br/>
            DJ Monitor
          </ContentsHeaderText>
          <ContentsHeaderLogo>
            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="71.626"
                 viewBox="0 0 160 71.626">
              <g transform="translate(-314.851 -57.585)">
                <path
                    d="M403.757,90.8a.568.568,0,0,0,1.053-.02l10.63-32.317H422.4l-12.555,35.3a5.559,5.559,0,0,1-5.939,3.811c-3.055-.087-4.745-1.311-5.838-4.3L385.676,58.492h7.1Z"
                    transform="translate(9.192 0.114)" fill="#fff"/>
                <rect width="6.375" height="38.832"
                      transform="translate(314.873 58.589)" fill="#fff"/>
                <path
                    d="M383.409,70.283A3.219,3.219,0,0,0,377,70.6h0L376.987,82a9.977,9.977,0,0,1-17.922,5.731,9.856,9.856,0,0,1-1.752-5.994l0-23.262h-6.5l.006,23.243a19.457,19.457,0,0,0,.136,2.434,14.735,14.735,0,0,0,7.254,11.333,17.3,17.3,0,0,0,10.664,2.262,15.783,15.783,0,0,0,10.068-4.442,15.214,15.214,0,0,0,4.532-11.271Z"
                    transform="translate(4.668 0.116)" fill="#fff"/>
                <circle cx="3.248" cy="3.248" r="3.248"
                        transform="translate(381.604 58.632)" fill="#fff"/>
                <path
                    d="M455.177,63.5a20.246,20.246,0,0,0-23.214-3.854l2.761,5.893a13.714,13.714,0,1,1-7.413,14.207h0a3.249,3.249,0,0,0-6.473.407,3.283,3.283,0,0,0,.056.59h-.006A20.212,20.212,0,1,0,455.177,63.5Z"
                    transform="translate(13.755 0)" fill="#fff"/>
                <circle cx="3.275" cy="3.275" r="3.275"
                        transform="translate(435.188 67.831)" fill="#fff"/>
                <path
                    d="M330.765,78.361a1.417,1.417,0,0,1,.024-2.217l15.571-17.675h-7.984L324.988,73.781c-2.316,2.833-2.284,5.1.086,7.88l13.512,15.685,8.348-.011Z"
                    transform="translate(1.093 0.115)" fill="#fff"/>
                <path
                    d="M315.615,112.08v2.264h-.764v-5.782h2.134a2.147,2.147,0,0,1,1.487.484,1.645,1.645,0,0,1,.539,1.283,1.619,1.619,0,0,1-.526,1.3,2.242,2.242,0,0,1-1.508.454Zm0-.623h1.37a1.371,1.371,0,0,0,.937-.289,1.054,1.054,0,0,0,.325-.833,1.09,1.09,0,0,0-.325-.828,1.289,1.289,0,0,0-.894-.323h-1.413Z"
                    transform="translate(0 6.616)" fill="#fff"/>
                <path
                    d="M318.976,111.994a2.529,2.529,0,0,1,.249-1.135,1.848,1.848,0,0,1,.69-.778,1.882,1.882,0,0,1,1.011-.275,1.815,1.815,0,0,1,1.42.608,2.338,2.338,0,0,1,.542,1.617v.051a2.556,2.556,0,0,1-.241,1.126,1.812,1.812,0,0,1-.687.777,1.906,1.906,0,0,1-1.027.278,1.809,1.809,0,0,1-1.416-.608,2.321,2.321,0,0,1-.542-1.609Zm.739.087a1.833,1.833,0,0,0,.332,1.149,1.123,1.123,0,0,0,1.777-.007,2.013,2.013,0,0,0,.33-1.229,1.836,1.836,0,0,0-.337-1.146,1.065,1.065,0,0,0-.891-.438,1.054,1.054,0,0,0-.878.433A1.992,1.992,0,0,0,319.715,112.081Z"
                    transform="translate(0.535 6.777)" fill="#fff"/>
                <path
                    d="M326.959,113.161l.827-3.284h.734l-1.251,4.3h-.6l-1.045-3.256-1.017,3.256h-.6l-1.247-4.3h.731l.846,3.217,1-3.217h.593Z"
                    transform="translate(1.028 6.787)" fill="#fff"/>
                <path
                    d="M330.181,114.263a1.878,1.878,0,0,1-1.421-.574,2.129,2.129,0,0,1-.548-1.535v-.136a2.592,2.592,0,0,1,.243-1.141,1.915,1.915,0,0,1,.684-.787,1.727,1.727,0,0,1,.952-.284,1.605,1.605,0,0,1,1.3.552,2.392,2.392,0,0,1,.465,1.581v.306h-2.911a1.53,1.53,0,0,0,.371,1.027,1.172,1.172,0,0,0,.9.391,1.277,1.277,0,0,0,.66-.159,1.649,1.649,0,0,0,.472-.42l.449.349A1.8,1.8,0,0,1,330.181,114.263Zm-.09-3.853a.977.977,0,0,0-.747.323,1.574,1.574,0,0,0-.375.907h2.155v-.055a1.41,1.41,0,0,0-.3-.868A.92.92,0,0,0,330.091,110.41Z"
                    transform="translate(1.734 6.777)" fill="#fff"/>
                <path
                    d="M334.269,110.545a2.256,2.256,0,0,0-.362-.028.97.97,0,0,0-.982.616v3.05h-.734v-4.3h.715l.012.5a1.143,1.143,0,0,1,1.025-.576.746.746,0,0,1,.325.055Z"
                    transform="translate(2.25 6.777)" fill="#fff"/>
                <path
                    d="M336.432,114.263a1.878,1.878,0,0,1-1.421-.574,2.129,2.129,0,0,1-.548-1.535v-.136a2.592,2.592,0,0,1,.243-1.141,1.915,1.915,0,0,1,.684-.787,1.727,1.727,0,0,1,.952-.284,1.605,1.605,0,0,1,1.3.552,2.392,2.392,0,0,1,.465,1.581v.306H335.2a1.53,1.53,0,0,0,.371,1.027,1.172,1.172,0,0,0,.9.391,1.277,1.277,0,0,0,.66-.159,1.648,1.648,0,0,0,.472-.42l.449.349A1.8,1.8,0,0,1,336.432,114.263Zm-.09-3.853a.977.977,0,0,0-.747.323,1.575,1.575,0,0,0-.375.907h2.155v-.055a1.41,1.41,0,0,0-.3-.868A.92.92,0,0,0,336.342,110.41Z"
                    transform="translate(2.545 6.777)" fill="#fff"/>
                <path
                    d="M338.284,112.2a2.52,2.52,0,0,1,.469-1.591,1.594,1.594,0,0,1,2.422-.086v-2.24h.736v6.1h-.676l-.036-.46a1.5,1.5,0,0,1-1.227.54,1.467,1.467,0,0,1-1.217-.612,2.544,2.544,0,0,1-.471-1.6Zm.734.082a1.9,1.9,0,0,0,.3,1.144.975.975,0,0,0,.834.414,1.073,1.073,0,0,0,1.02-.628v-1.974a1.078,1.078,0,0,0-1.012-.608.98.98,0,0,0-.842.417A2.092,2.092,0,0,0,339.018,112.279Z"
                    transform="translate(3.041 6.579)" fill="#fff"/>
                <path
                    d="M347.924,112.279a2.546,2.546,0,0,1-.454,1.583,1.445,1.445,0,0,1-1.215.6,1.5,1.5,0,0,1-1.26-.576l-.036.5h-.674v-6.1h.734v2.277a1.49,1.49,0,0,1,1.227-.552,1.458,1.458,0,0,1,1.23.592,2.628,2.628,0,0,1,.447,1.62Zm-.735-.082a1.989,1.989,0,0,0-.29-1.16.96.96,0,0,0-.834-.409,1.079,1.079,0,0,0-1.045.676v1.858a1.109,1.109,0,0,0,1.053.676.953.953,0,0,0,.821-.409A2.109,2.109,0,0,0,347.189,112.2Z"
                    transform="translate(3.82 6.579)" fill="#fff"/>
                <path
                    d="M349.783,113.1l1-3.221h.785l-1.727,4.96q-.4,1.073-1.274,1.072l-.139-.011-.275-.052v-.6l.2.016a.972.972,0,0,0,.583-.151,1.088,1.088,0,0,0,.343-.551l.163-.437-1.533-4.249h.8Z"
                    transform="translate(4.29 6.787)" fill="#fff"/>
                <g transform="translate(367.146 107.961)">
                  <path
                      d="M370.948,105.727V103.4a1.087,1.087,0,0,1,1.23-1.23h.817a1.083,1.083,0,0,1,1.219,1.23v10.211a4.908,4.908,0,0,1-4.9,4.91h-3.266a4.908,4.908,0,0,1-4.91-4.91V110.35a4.918,4.918,0,0,1,4.91-4.91h3.266A4.774,4.774,0,0,1,370.948,105.727Zm-1.633,2.991h-3.266a1.647,1.647,0,0,0-1.644,1.633v3.265a1.649,1.649,0,0,0,1.644,1.645h3.266a1.661,1.661,0,0,0,1.633-1.645v-3.3a1.611,1.611,0,0,0-.5-1.126A1.555,1.555,0,0,0,369.315,108.718Z"
                      transform="translate(-361.139 -102.174)" fill="#fff"/>
                  <path
                      d="M375.253,114.421V108.3a1.087,1.087,0,0,1,1.23-1.23h.817a1.087,1.087,0,0,1,1.23,1.23v10.222a4.691,4.691,0,0,1-1.455,3.462,4.746,4.746,0,0,1-3.455,1.437h-2.036a.139.139,0,0,1-.04-.006.157.157,0,0,0-.052-.006h-.023a1.084,1.084,0,0,1-1.1-1.208v-.839a1.077,1.077,0,0,1,1.218-1.219h1.622a3.5,3.5,0,0,0,1.126-.16,1.588,1.588,0,0,0,.92-1.45v-4.116Zm1.23-12.247h.817a1.087,1.087,0,0,1,1.23,1.23v.817a1.083,1.083,0,0,1-1.23,1.219h-.817a1.083,1.083,0,0,1-1.23-1.219V103.4A1.087,1.087,0,0,1,376.484,102.174Z"
                      transform="translate(-359.941 -102.174)" fill="#fff"/>
                  <path
                      d="M386.415,105.065h1.633a4.729,4.729,0,0,1,3.266,1.264,4.738,4.738,0,0,1,3.276-1.264h3.266a4.928,4.928,0,0,1,4.91,4.91v6.945a1.087,1.087,0,0,1-1.23,1.23h-.817a1.087,1.087,0,0,1-1.23-1.23v-6.945a1.647,1.647,0,0,0-1.633-1.633H394.59a1.647,1.647,0,0,0-1.644,1.633v6.945a1.083,1.083,0,0,1-1.219,1.23h-.816a1.088,1.088,0,0,1-1.231-1.23v-6.945a1.657,1.657,0,0,0-1.633-1.633h-3.265a1.649,1.649,0,0,0-1.645,1.633v6.945a1.082,1.082,0,0,1-1.219,1.23H381.1a1.087,1.087,0,0,1-1.23-1.23v-6.945a4.916,4.916,0,0,1,4.91-4.91Z"
                      transform="translate(-358.708 -101.799)" fill="#fff"/>
                  <path
                      d="M406.053,105.065h3.266a4.916,4.916,0,0,1,4.9,4.91v3.265a4.907,4.907,0,0,1-4.9,4.91h-3.266a4.908,4.908,0,0,1-4.91-4.91v-3.265a4.918,4.918,0,0,1,4.91-4.91Zm0,3.277a1.647,1.647,0,0,0-1.644,1.633v3.265a1.649,1.649,0,0,0,1.644,1.645h3.266a1.661,1.661,0,0,0,1.633-1.645v-3.265a1.659,1.659,0,0,0-1.633-1.633Z"
                      transform="translate(-355.947 -101.799)" fill="#fff"/>
                  <path
                      d="M420.409,105.065h1.633a4.918,4.918,0,0,1,4.9,4.91v6.945a1.083,1.083,0,0,1-1.219,1.23H424.9a1.087,1.087,0,0,1-1.23-1.23v-6.945a1.657,1.657,0,0,0-1.633-1.633h-3.266a1.647,1.647,0,0,0-1.644,1.633v6.945a1.083,1.083,0,0,1-1.219,1.23H415.1a1.087,1.087,0,0,1-1.23-1.23v-6.945a4.918,4.918,0,0,1,4.91-4.91Z"
                      transform="translate(-354.296 -101.799)" fill="#fff"/>
                  <path
                      d="M427.818,102.174h.816a1.083,1.083,0,0,1,1.219,1.23v.817a1.078,1.078,0,0,1-1.219,1.219h-.816a1.083,1.083,0,0,1-1.23-1.219V103.4A1.087,1.087,0,0,1,427.818,102.174Zm0,4.91h.816a1.078,1.078,0,0,1,1.219,1.219V117.3a1.083,1.083,0,0,1-1.219,1.23h-.816a1.087,1.087,0,0,1-1.23-1.23V108.3A1.083,1.083,0,0,1,427.818,107.084Z"
                      transform="translate(-352.645 -102.174)" fill="#fff"/>
                  <path
                      d="M433.751,113.615a1.352,1.352,0,0,0,.616,1.323,2.339,2.339,0,0,0,1.029.322v-.012h.4a1.088,1.088,0,0,1,1.23,1.23v.817a1.083,1.083,0,0,1-1.23,1.219h-.253a.771.771,0,0,1-.149.011,4.906,4.906,0,0,1-4.91-4.91V103.4a1.087,1.087,0,0,1,1.23-1.23h.816a1.083,1.083,0,0,1,1.219,1.23v2.036H435.8a1.087,1.087,0,0,1,1.23,1.23v.817a1.087,1.087,0,0,1-1.23,1.23h-2.047Z"
                      transform="translate(-352.139 -102.174)" fill="#fff"/>
                  <path
                      d="M442.357,105.065h3.266a4.916,4.916,0,0,1,4.9,4.91v3.265a4.907,4.907,0,0,1-4.9,4.91h-3.266a4.907,4.907,0,0,1-4.91-4.91v-3.265a4.916,4.916,0,0,1,4.91-4.91Zm0,3.277a1.647,1.647,0,0,0-1.644,1.633v3.265a1.649,1.649,0,0,0,1.644,1.645h3.266a1.661,1.661,0,0,0,1.633-1.645v-3.265a1.559,1.559,0,0,0-.489-1.15,1.575,1.575,0,0,0-1.143-.482Z"
                      transform="translate(-351.235 -101.799)" fill="#fff"/>
                  <path
                      d="M453.435,109.975v6.945a1.083,1.083,0,0,1-1.219,1.23H451.4a1.087,1.087,0,0,1-1.23-1.23v-6.945a4.916,4.916,0,0,1,4.909-4.91.459.459,0,0,1,.069.006.574.574,0,0,0,.081.006h.253a1.087,1.087,0,0,1,1.23,1.23v.817a1.083,1.083,0,0,1-1.23,1.219h-.4a2.38,2.38,0,0,0-1.029.315A1.337,1.337,0,0,0,453.435,109.975Z"
                      transform="translate(-349.584 -101.799)" fill="#fff"/>
                </g>
              </g>
            </svg>
          </ContentsHeaderLogo>
        </ContentsHeader>
        <DJMSection>
          <DJMLogoBlockPC className={'pc'}>
            <img src={djmLogoPC}/>
          </DJMLogoBlockPC>
          <DJMLogoDescriptionBlock>

            <h2>Partnership with<br/><span
                className={'djm-name'}>DJ Monitor</span></h2>
            <img src={djmLogoSP} className={'djm-logo-sp sp'}/>
            <h4>- the global leader in electronic music recognition
              technology</h4>
            <SectionTextArea>
              <ul>
                <li>Established in 2005, DJ Monitor stands as the platform of
                  choice for leading Collective Management Organizations (CMO)
                  across the globe.
                </li>
                <li>Trusted by DJ event organisers and venues around the
                  world,{' '}
                  <span className={'djm-name'}>DJ Monitor</span> guarantees
                  unparalleled precision in music
                  monitoring through its Music Recognition Technology (MRT) and
                  Direct Metadata Capture (DMC) services.
                </li>
                <li>DJ Monitor serves venues, festivals and livestreams,
                  monitoring over 1 million DJ sets annually with a 93% average
                  identification rate.
                </li>
              </ul>
              <a
                  className="dark-button new-window compact"
                  href={'https://djmonitor.com/'}
                  target={'_blank'}
              >
                <span>DJ Monitor</span>
              </a>
            </SectionTextArea>
          </DJMLogoDescriptionBlock>
        </DJMSection>
        <KUVOSection>
          <h3>KUVO Is Powered by <span className={'djm-name'}>DJ Monitor</span>
          </h3>
          <SectionTextArea>
            <p>The system enables DJs to support the creators of the music they
              play without needing to do anything more than play their tracks.
              No action from DJs is required for KUVO to function – it captures
              only the track data of the music played. The data is never shared
              publicly without consent and it doesn’t include details of
              performing DJ.
            </p>
            <p>KUVO is a positive, creator-supporting initiative which provides
              insights and benefits for venues that participate, and it creates
              no negative impact or inconvenience for DJs.
            </p>
            <p>
              For transparency on how KUVO will generate income to sustain
              itself: the proposed model involves charging selected industry
              partners for data enhancement, music play insights, and analytics
              services. To clarify: no charges for KUVO services are incurred by
              venues, music creators, or DJs (currently, no KUVO services are
              provided for DJs).
            </p>
          </SectionTextArea>
        </KUVOSection>
        <MissionSection>
          <MissionSectionHeader>
            <div className={'pre-title'}>We have a shared mission:</div>
            <h3>Empowering Music Through Data</h3>
          </MissionSectionHeader>
          <MissionContentArea>
            <SectionTextArea className={'text-block'}>
              <ul>
                <li>Support Music Creators - Empower the industry with reliable
                  data to enable accurate royalty payments for the music played
                  in venues and events.
                </li>
                <li>Support Venues With Music Insights and Incentives to Enhance
                  Venue Businesses.
                </li>
                <li>Respect DJ Setlist Privacy - No publicized playlists and no
                  details of which DJ played which tracks are captured by the
                  technology.
                </li>
              </ul>
            </SectionTextArea>
            <div className={'image-block'}>
              <img src={mrtImage}/>
            </div>

          </MissionContentArea>
        </MissionSection>
        <HowWorksSection>
          <h4>How the Technology Works</h4>
          <div className={'description'}>
            <i>KUVO Powered by{' '}<span
                className={'djm-name'}>DJ Monitor</span></i> brings
            together the strengths of
            Music
            Recognition Technology (MRT) and Direct Metadata Capture (DMC)
            technology.
          </div>
          <img src={chartImagePC} className={'chart pc'}/>
          <img src={chartImageSP} className={'chart sp'}/>
          <HowWorksItems>
            <HowWorksItem>
              <h5>Music Recognition Technology (MRT)</h5>
              <p>Music identification process where an an encrypted fingerprint
                of the audio in a performance is compared to a database of music
                to identify the tracks being played.</p>
            </HowWorksItem>
            <HowWorksItem>
              <h5>Direct Metadata Capture (DMC)</h5>
              <p>Track title, artist name, and other metadata is captured when
                music is played on Pioneer DJ CDJ-3000 or CDJ-2000 NXS2 units
                are connected.</p>
            </HowWorksItem>
          </HowWorksItems>
        </HowWorksSection>
      </ContentsSection>
  );
}

const ContactSection = styled.section`
  position: relative;
  padding: 55px 20px 150px;

  .form-anchor {
    position: absolute;
    top: 10px;
    @media ${config.layout.wide} {
      top: -60px;
    }
  }
  
  @media ${config.layout.wide} {
    padding: 0 50px 100px;
    min-width: 1200px;
    
    & > .hash {
      position:relative;
      top: -80px;
    }
  }
`;

const ContactSectionInner = styled.section`
  position: relative;

  @media ${config.layout.wide} {
    max-width: 930px;
    margin: 0 auto;
  }

  h3 {
    font-size: 40px;
    line-height: 43px;
    font-weight: bold;
    text-align: center;

    margin-top: 50px;
    margin-bottom: 50px;
    @media ${config.layout.wide} {
      margin-top: 70px;
      line-height: 56px;
    }
  }

  .form-description {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;

const ThanksTitle = styled.h3`
  font-size: 40px;
  line-height: 43px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${config.layout.wide} {
    margin-top: 70px;
    line-height: 56px;
  }
`;

const ThanksDescription = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  padding-bottom: 50px;
  @media ${config.layout.wide} {
    margin-bottom: 100px;
  }

  a {
    color: #000;
  }
`;

const SubmitButtonWrap = styled.div`
  @media ${config.layout.wide} {
    width: 350px;
    margin: 0 auto;
  }
`;

const KeepMeLogin = styled.div`
  text-align: center;
  margin: 50px auto;
  max-width: calc(100% - 30px);
  @media ${config.layout.wide} {
    max-width: 100%;
  }

  input {
    display: none;

    &:checked {
      & + label {
        &:before {
        }
      }
    }
  }

  label {
    position: relative;
    font-size: 12px;
    @media ${config.layout.wide} {
      font-size: 14px;
    }
    font-weight: 500;
    margin-left: 7px;
    padding-left: 28px;
    text-align: left;

    &:before {
      position: absolute;
      left: 0;
      top: 0px;
      @media ${config.layout.wide} {
        top: 2px;
      }
    }

    a {
      color: #000;
    }
  }
`;

function ContactSent() {

  useEffect(() => {

    const isMobile = window.matchMedia(
        `${config.layout.mobile}`).matches;
    if (isMobile) {
      setTimeout(() => {
        global.scrollToHash('thanks');
      }, 300);
    } else {
      global.scrollToHash('thanks-pc');
    }

  }, []);

  return (
      <ContactSection data-hash={'thanks'}>
        <div className={'hash'}  data-hash={'thanks-pc'}/>
        <ContactSectionInner>
          <ThanksTitle>Thank You for Joining Us! </ThanksTitle>
          <ThanksDescription>
            Welcome to KUVO’s newsletter! Get ready for exclusive updates and
            news will be delivered straight to your inbox. Explore more about us
            on our website. Any questions? Reach out to us at{' '}
            <Link to={'/contact'}>Contact</Link>.
          </ThanksDescription>
        </ContactSectionInner>
      </ContactSection>
  );
}

function ContactUnauth({setSent}) {
  const {state} = useLocation();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(
      'default',
  );
  const {
    errorDialogWithAPIError,
    alertDialog,
  } = useCustomDialog();
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {

    (async () => {

      const data = await getCountryMaster();
      setCountries(data.map(c => {
        return {
          value: c.country,
          label: c.country_name,
        };
      }));
    })();

  }, []);

  /*
    const categories = [
      'Press',
      'KUVO account (created before Sep 2022)',
      'New venue account',
      'CMO account',
      'Others',
    ].map((v) => {
      return {
        value: v,
        label: v,
      };
    });
  */

  function handleSubmit(event) {
    event.preventDefault();

    if (!agreed) {
      return;
    }

    let formData = new FormData(event.currentTarget);
    let name = (formData.get('name') || '').trim().substr(0, 256);
    let email = (formData.get('email') || '').trim().substr(0, 256);
    let errors = [];
    if (!name) {
      errors.push('- Name is required.');
    }
    if (!email) {
      errors.push('- Email is required.');
    }
    if (country === 'default') {
      errors.push('- Choose a country.');
    }

    if (errors.length) {
      (async function() {
        await alertDialog({
          //buttons: ButtonLayout.None,
          description_element: (
              <div className={'errors'}>
                <ul>
                  {errors.map((e, i) => {
                    return <li key={i}>{e}</li>;
                  })}
                </ul>
              </div>
          ),
        });
      })();
      return;
    }

    (async () => {
      try {
        await sendVenueInquiry({
          name,
          email,
          country,
        });
        setSent(true);
      } catch (e) {
        console.error(e);
        await errorDialogWithAPIError(e);
      }
    })();
  }

  const onCountryPullDownChange = (newValue) => {
    console.log(newValue);
    setCountry(newValue);
  };

  return (
      <ContactSection>
        <a name={"form"} className={'form-anchor'} data-hash="form"></a>
        <ContactSectionInner>
          <h3>Contact Us About Participating</h3>
          <div className={'form-description'}>
            We’re looking for venue participants for active pilots in the UK,
            Netherlands, and Australia, with more territories to be activated
            soon. If your venue is interested in participating, please fill in
            your details below.
          </div>
          <form onSubmit={handleSubmit}>
            <RequiredLabel/>
            <InputLabel required={true}>Country</InputLabel>
            <DBDPullDown
                defaultLabel={'Select one'}
                value={country}
                onChange={onCountryPullDownChange}
                items={countries}
                style={{
                  minWidth: '100%',
                }}
            ></DBDPullDown>
            <InputLabel required={true}>Venue name</InputLabel>
            <FormInput type="text" required name="name" maxLength={64}
                       onInvalid={(e) => {
                         //if (window.scrollY > 140) {
                         //  window.scrollTo(0, 140);
                         //}
                       }}/>
            <InputLabel required={true}>E-mail address</InputLabel>
            <FormInput type="email" required name="email" maxLength={128}
                       onInvalid={(e) => {
                         //if (window.scrollY > 140) {
                         //  window.scrollTo(0, 140);
                         //}
                       }}/>

            <KeepMeLogin>
              <input
                  id="keep_me_logged_in"
                  name="keep"
                  type="checkbox"
                  defaultChecked={agreed}
                  onChange={(e) => {
                    setAgreed(e.target.checked);
                  }}
              />
              <label htmlFor="keep_me_logged_in">
                I agree to the{' '}
                <a
                    href="https://alphatheta.com/company/privacy-policy/"
                    target="_blank"
                    rel="noopener"
                >
                  Privacy Policy
                </a>, including the transfer of my personal information to Japan
                and the U.S.
                for hosting purpose and to provide you with the service. I
                understand that these countries may not have
                the same data protection laws as the country from which I
                provide my personal information. I have the
                right to withdraw my consent at any time.
              </label>
            </KeepMeLogin>

            <SubmitButtonWrap>
              <button
                  type="submit"
                  className="dark-button center-fixed"
                  style={{
                    width: '100%',
                  }}
                  disabled={!agreed}
              >
                <span>Submit</span>
              </button>
            </SubmitButtonWrap>
          </form>
        </ContactSectionInner>
      </ContactSection>
  );
}

export default function DJMonitorPage() {
  const app = useApp();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    app.setTitle('Partnership with DJ Monitor');

    if (/form/.test(`${window.location.hash || ''}`) ) {
      global.scrollToHash('form');
    }

  }, []);

  return (
      <Page>
        <Contents/>
        {
          sent ? (
              <ContactSent/>
          ) : (
              <ContactUnauth setSent={setSent}/>
          )
        }
      </Page>
  );
}
