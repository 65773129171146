import React, {createContext, useEffect, useReducer, useContext} from 'react';
import {useLocation} from 'react-router-dom';

const CustomDialogContext = createContext();

export const ButtonLayout = {
  None: [],
  OK: [
    {
      label: 'OK',
      positive: true,
    },
  ],
  OKCancel: [
    {
      label: 'OK',
      positive: true,
    },
    {
      label: 'Cancel',
      positive: false,
    },
  ],
};

const initialState = {
  show: false,
  description: '',
  description_element: null,
  closable: true,
  buttons: ButtonLayout.OK,
  for_top: false,
};

const reducer = (state = initialState, action) => {
  //  console.log(action);
  switch (action.type) {
    case 'show': {
      let newState = {
        ...state,
        show: true,
        for_top: false,
      };
      if (action.description) {
        newState.description = action.description;
      }
      if (action.description_element) {
        newState.description_element = action.description_element;
      }
      if (!action.closable) {
        newState.closable = true;
      }
      if (action.buttons) {
        newState.buttons = action.buttons;
      }
      if (action.for_top) {
        newState.for_top = action.for_top;
      }
      return newState;
    }
    case 'hide':
      return initialState;
    default:
      return initialState;
  }
};

export const CustomDialogContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  useEffect(() => {
    if (state.show) {
      dispatch({
        type: 'hide',
      });
    }
  }, [location.pathname, location.search]);

  return (
      <CustomDialogContext.Provider value={[state, dispatch]}>
        {children}
      </CustomDialogContext.Provider>
  );
};

let resolveCallback;

export function useCustomDialog() {
  const [customDialogState, dispatch] = useContext(CustomDialogContext);

  const onCloseButtonClicked = () => {
    closeCustomDialog();
    resolveCallback && resolveCallback(-1);
  };

  const onButtonClicked = (buttonIndex) => {
    closeCustomDialog();
    resolveCallback && resolveCallback(buttonIndex);
  };

  const customDialog = ({
    description = '',
    description_element = null,
    closable = true,
    buttons = null,
    for_top = false,
  }) => {
    dispatch({
      type: 'show',
      description,
      description_element,
      closable,
      buttons,
      for_top,
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const alertDialog = function({
    description = '',
    description_element = null,
  }) {
    dispatch({
      type: 'show',
      description,
      description_element,
      closable: true,
      buttons: ButtonLayout.None,
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const okDialog = function({description = '', description_element = null}) {
    dispatch({
      type: 'show',
      description,
      description_element,
      closable: true,
      buttons: ButtonLayout.OK,
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const confirmDialog = function({
    description = '',
    description_element = null,
  }) {
    dispatch({
      type: 'show',
      description,
      description_element,
      closable: true,
      buttons: ButtonLayout.OKCancel,
    });
    return new Promise((res, rej) => {
      resolveCallback = (index) => {
        res(index == 0);
      };
    });
  };

  const closeCustomDialog = () => {
    dispatch({
      type: 'hide',
    });
  };

  const errorDialogWithAPIError = (err) => {
    //const status = (err.response && er.response.status) || 500;
    //const code =
    //  (err.response && err.response.data && err.response.data.code) || 500;

    let customError =
        err && err.response && err.response.data && err.response.data.error;
    if (customError) {
      return alertDialog({
        description_element: <span>{customError.message || ''}</span>,
      });
    } else {
      return alertDialog({
        description_element: (
            <span>{'Unknown error. Please retry later.'}</span>
        ),
      });
    }
  };

  return {
    customDialog,
    alertDialog,
    okDialog,
    confirmDialog,
    errorDialogWithAPIError,
    onCloseButtonClicked,
    onButtonClicked,
    customDialogState,
  };
}

export default CustomDialogContext;
