import React from 'react';
import {createRoot} from 'react-dom/client';
import './destyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import 'simplebar/dist/simplebar.min.css';

import App from './routings/App';
import {BrowserRouter} from 'react-router-dom';

const root = createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App/>
    </BrowserRouter>,
);
