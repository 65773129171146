import styled from 'styled-components';
import {useRef, useState} from 'react';
import config from '../../../config';
import {
  useCustomDialog,
  ButtonLayout,
} from '../../../contexts/CustomDialogContext';

const PCWrap = styled.div`
  padding: 50px 0;
  display: none;
  height: 700px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  @media ${config.layout.topwide} {
    display: block;
  }

  svg {
    pointer-events: none;
    display: block;
    margin: 0 auto;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    color: #fff;
    position: absolute;
    top: 587px;
    right: 20px;
    left: calc(50% + 252px);
    max-width: 382px;
  }
`;

const SPWrap = styled.div`
  //height: 600px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0px 20px 0;
  @media ${config.layout.topwide} {
    display: none;
  }

  svg {
    pointer-events: none;
    width: 100%;
    height: auto;
    max-width: 350px;
    display: block;
    margin: 0 auto;

    #Venues {
      pointer-events: auto;
    }
  }

  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0;
    padding-bottom: 31px;
    color: #fff;
  }
`;

const SPTopMargin = styled.div`
  width: 20.8%;
  max-height: 71px;
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }
`;

const SPKUVOLogo = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='107.661' height='27.203' viewBox='0 0 107.661 27.203'%3E%3Cg id='グループ_960' data-name='グループ 960' transform='translate(-1928.44 -65.697)'%3E%3Cpath id='パス_95' data-name='パス 95' d='M1994.443,88.127a.382.382,0,0,0,.708-.014l7.154-21.749h4.681l-8.449,23.756a3.741,3.741,0,0,1-4,2.566,3.812,3.812,0,0,1-3.929-2.9l-8.337-23.4h4.778Z' fill='%23fff'/%3E%3Crect id='長方形_574' data-name='長方形 574' width='4.29' height='26.133' transform='translate(1928.44 66.374)' fill='%23fff'/%3E%3Cpath id='パス_96' data-name='パス 96' d='M1977.7,74.322a2.166,2.166,0,0,0-4.315.211h0l0,7.676a6.407,6.407,0,0,1-1.215,3.92,6.92,6.92,0,0,1-10.847-.063,6.636,6.636,0,0,1-1.178-4.034l0-15.655h-4.372l0,15.643a12.95,12.95,0,0,0,.092,1.637,9.914,9.914,0,0,0,4.882,7.627,11.644,11.644,0,0,0,7.176,1.522,10.618,10.618,0,0,0,6.775-2.989,10.239,10.239,0,0,0,3.05-7.585Z' fill='%23fff'/%3E%3Ccircle id='楕円形_33' data-name='楕円形 33' cx='2.186' cy='2.186' r='2.186' transform='translate(1973.348 66.402)' fill='%23fff'/%3E%3Cpath id='パス_97' data-name='パス 97' d='M2032.118,69.681a13.62,13.62,0,0,0-15.622-2.593l1.858,3.965a9.23,9.23,0,1,1-4.988,9.562h0a2.187,2.187,0,0,0-4.356.274,2.148,2.148,0,0,0,.038.4h0a13.6,13.6,0,1,0,23.076-11.605Z' fill='%23fff'/%3E%3Ccircle id='楕円形_34' data-name='楕円形 34' cx='2.204' cy='2.204' r='2.204' transform='translate(2009.409 72.593)' fill='%23fff'/%3E%3Cpath id='パス_98' data-name='パス 98' d='M1939.87,79.757a.953.953,0,0,1,.016-1.492l10.479-11.895h-5.373l-9.01,10.3c-1.558,1.907-1.537,3.433.058,5.3l9.093,10.555,5.619-.007Z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
  width: 107.66px;
  height: 27.2px;
  margin: 38px auto 26px;

  @media ${config.layout.topwide} {
    margin: 0;
    position: absolute;
    bottom: 133px;
    left: calc(50% + 252px);
  }
`;

export function EcoSystemImage() {
  const {customDialog} = useCustomDialog();
  const dialogTimerRef = useRef(null);

  const openDialog = async (description_element) => {
    await customDialog({
      buttons: ButtonLayout.None,
      description_element,
      for_top: true,
    });
  };

  const openDialogForMusicCreators = async () => {
    if (dialogTimerRef.current) {
      clearTimeout(dialogTimerRef.current);
      dialogTimerRef.current = null;
    }
    dialogTimerRef.current = setTimeout(() => {
      openDialog((
              <>
                <h3><span>Music Creators</span></h3>
                <p>
                  KUVO supports music creators (producers, recording artists,
                  songwriters, composers, and producer/DJs). It empowers the
                  industry with reliable music play reporting, enabling more
                  accurate royalty payments to the right creators.
                </p>
              </>
          ),
      );
    }, 100);
  };

  const openDialogForVenues = async () => {
    if (dialogTimerRef.current) {
      clearTimeout(dialogTimerRef.current);
      dialogTimerRef.current = null;
    }
    dialogTimerRef.current = setTimeout(() => {
      openDialog((
              <>
                <h3><span>Venues</span></h3>
                <p>
                  Venues are the heart of the electronic music scene. By registering
                  for KUVO, venues can privately access insights on the music played
                  in their establishment, receive exclusive benefits relating to
                  Pioneer DJ equipment, and enable more accurate payments of
                  royalties to music creators.<br/>
                  *The KUVO activation process requires at least 1 Pioneer DJ
                  CDJ-3000 connected to the internet.
                </p>
              </>
          ),
      );
    }, 100);
  };

  const openDialogForCMOs = async () => {
    if (dialogTimerRef.current) {
      clearTimeout(dialogTimerRef.current);
      dialogTimerRef.current = null;
    }
    dialogTimerRef.current = setTimeout(() => {
      openDialog((
              <>
                <h3><span>Rights holders & CMOs</span></h3>
                <p>
                  Music creators and other rights holders join CMOs (AKA collection
                  societies) to offer blanket licenses for whenever their music is
                  played or performed at venues or events. KUVO provides secure
                  music play reports to enable more accurate distribution of
                  royalties to the creators and rights holders.
                </p>
              </>
          ),
      );
    }, 100);
  };

  const openDialogForDJs = async () => {
    if (dialogTimerRef.current) {
      clearTimeout(dialogTimerRef.current);
      dialogTimerRef.current = null;
    }
    dialogTimerRef.current = setTimeout(() => {
      openDialog((
              <>
                <h3><span>DJs</span></h3>
                <p>
                  DJs are front-and-center of the electronic music industry. KUVO
                  automates music play reporting for royalty purposes through
                  technology that works in the background. It captures only the
                  track data, which is never shared publicly, and no details of
                  which DJ played which tracks.
                </p>
              </>
          ),
      );
    }, 100);
  };

  return (
      <>
        <EcoSystemImagePC
            openDialogForMusicCreators={openDialogForMusicCreators}
            openDialogForVenues={openDialogForVenues}
            openDialogForCMOs={openDialogForCMOs}
            openDialogForDJs={openDialogForDJs}
        />
        <EcoSystemImageSP
            openDialogForMusicCreators={openDialogForMusicCreators}
            openDialogForVenues={openDialogForVenues}
            openDialogForCMOs={openDialogForCMOs}
            openDialogForDJs={openDialogForDJs}
        />
      </>
  );
}

export function EcoSystemImagePC({
  openDialogForMusicCreators,
  openDialogForVenues,
  openDialogForCMOs,
  openDialogForDJs,
}) {
  const [hoverVenue, setHoverVenue] = useState(false);
  const [hoverDJ, setHoverDJ] = useState(false);
  const [hoverMusicCreators, setHoverMusicCreators] = useState(false);
  const [hoverCMOs, setHoverCMOs] = useState(false);

  return (
      <PCWrap>
        <svg xmlns="http://www.w3.org/2000/svg" width="624.353" height="592.715"
             viewBox="0 0 624.353 592.715">
          <defs>
            <filter id="ell_52" x="88.176" y="54.659" width="448.926"
                    height="448.926" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood floodOpacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="cr_1" x="185.349" y="0" width="253.654"
                    height="409.115"
                    filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-2"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-2"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="cr_2" x="63.795" y="153.713" width="374.249"
                    height="300.272" filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-3"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-3"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="cr_3" x="186.308" y="153.713" width="374.249"
                    height="300.272" filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-4"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-4"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <clipPath id="clip-path">
              <path id="mix_3" data-name="合体 3"
                    d="M187.929,287.723l-.09.077-.089-.077A189.53,189.53,0,0,1,0,321.324,189.455,189.455,0,0,1,122.53,165.2l-.017-.146.163-.06a191.254,191.254,0,0,1-1.163-21.1A188.824,188.824,0,0,1,187.84,0a188.825,188.825,0,0,1,66.327,143.9A191.246,191.246,0,0,1,253,165l.163.06-.017.146A189.453,189.453,0,0,1,375.68,321.323a189.525,189.525,0,0,1-187.75-33.6Z"
                    transform="translate(2712.42 1940.677)"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10" strokeWidth="1"/>
            </clipPath>
          </defs>
          <g id="gr_1486" data-name="グループ 1486"
             transform="translate(-387.824 -1880.018)">
            <g id="ell_50" data-name="楕円形 50"
               transform="translate(633.674 1895)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="189.252" cy="189.252" r="189.252" stroke="none"/>
              <circle cx="189.252" cy="189.252" r="188.752" fill="none"/>
            </g>
            <g id="ell_54" data-name="楕円形 54"
               transform="translate(511 2094.23)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="189.252" cy="189.252" r="189.252" stroke="none"/>
              <circle cx="189.252" cy="189.252" r="188.752" fill="none"/>
            </g>
            <g id="ell_53" data-name="楕円形 53"
               transform="translate(387.824 1895)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="189.252" cy="189.252" r="189.252" stroke="none"/>
              <circle cx="189.252" cy="189.252" r="188.752" fill="none"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 387.82, 1880.02)"
               filter="url(#ell_52)">
              <g id="ell_52-2" data-name="楕円形 52"
                 transform="translate(97.18 60.66)" fill="rgba(255,255,255,0.1)"
                 stroke="rgba(255,255,255,0.3)" strokeMiterlimit="10"
                 strokeWidth="0.991">
                <circle cx="215.463" cy="215.463" r="215.463" stroke="none"/>
                <circle cx="215.463" cy="215.463" r="214.967" fill="none"/>
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 387.82, 1880.02)"
               filter="url(#cr_1)">
              <path id="cr_1-2" data-name="交差 1"
                    d="M245.849,189.251a188.824,188.824,0,0,1,66.327-143.9,189.262,189.262,0,0,1,0,287.8A188.826,188.826,0,0,1,245.849,189.251Z"
                    transform="translate(0 15.31)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.2"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 387.82, 1880.02)"
               filter="url(#cr_2)">
              <path id="cr_2-2" data-name="交差 2"
                    d="M124.13,367c10.67-94.4,90.791-167.77,188.045-167.77a188.879,188.879,0,0,1,65.121,11.5c-10.67,94.4-90.791,167.77-188.046,167.77A188.874,188.874,0,0,1,124.13,367Z"
                    transform="translate(0.21 14.98)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.2"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 387.82, 1880.02)"
               filter="url(#cr_3)">
              <path id="cr_3-2" data-name="交差 3"
                    d="M124.13,210.733a188.874,188.874,0,0,1,65.121-11.5c97.255,0,177.377,73.368,188.046,167.77a188.878,188.878,0,0,1-65.122,11.5C214.921,378.5,134.8,305.136,124.13,210.733Z"
                    transform="translate(122.72 14.98)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.2"/>
            </g>
            <text id="DJs" transform="translate(869 1951)" fill="#fff"
                  fontSize="20" fontFamily="Roboto-Bold, Roboto"
                  fontWeight="700"
                  onClick={() => {
                    openDialogForDJs();
                  }}
                  onMouseOver={() => {
                    setHoverDJ(true);
                  }}
                  onMouseLeave={() => {
                    setHoverDJ(false);
                  }}
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
            >
              <tspan x="0" y="21">DJs</tspan>
            </text>
            <g id="gr_1035" data-name="グループ 1035"
               transform="translate(126 -1293)"
               onClick={() => {
                 openDialogForDJs();
               }}
               onMouseOver={() => {
                 setHoverDJ(true);
               }}
               onMouseLeave={() => {
                 setHoverDJ(false);
               }}
               style={{
                 pointerEvents: 'auto', cursor: 'pointer',
               }}
            >
              <circle id="ell_48-3" data-name="楕円形 48" cx="9" cy="9"
                      r={hoverDJ ? 11 : 9}
                      transform="translate(788 3249)" fill="#fff"/>
              <g id="gr_1022-3" data-name="グループ 1022"
                 transform="translate(16 1)">
                <rect id="rect_863-3" data-name="長方形 863" width="10"
                      height="2" transform="translate(776 3256)"/>
                <rect id="rect_864-3" data-name="長方形 864" width="2"
                      height="10" transform="translate(780 3252)"/>
              </g>
            </g>
            <text id="Venues" transform="translate(468 1951)" fill="#fff"
                  fontSize="20" fontFamily="Roboto-Bold, Roboto"
                  fontWeight="700"
                  onClick={() => {
                    openDialogForVenues();
                  }}
                  onMouseOver={() => {
                    setHoverVenue(true);
                  }}
                  onMouseLeave={() => {
                    setHoverVenue(false);
                  }}
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
            >
              <tspan x="0.313" y="21">Venues</tspan>
            </text>
            <g id="gr_1034" data-name="グループ 1034"
               transform="translate(-242 -1293)"
               onClick={() => {
                 openDialogForVenues();
               }}
               onMouseOver={() => {
                 setHoverVenue(true);
               }}
               onMouseLeave={() => {
                 setHoverVenue(false);
               }}
               style={{
                 pointerEvents: 'auto', cursor: 'pointer',
               }}
            >
              <circle id="ell_48" data-name="楕円形 48" cx="9" cy="9"
                      r={hoverVenue ? 11 : 9}
                      transform="translate(788 3249)" fill="#fff"/>
              <g id="gr_1022" data-name="グループ 1022"
                 transform="translate(16 1)">
                <rect id="rect_863" data-name="長方形 863" width="10"
                      height="2"
                      transform="translate(776 3256)"/>
                <rect id="rect_864" data-name="長方形 864" width="2"
                      height="10"
                      transform="translate(780 3252)"/>
              </g>
            </g>
            <g id="gr_1039" data-name="グループ 1039"
               transform="translate(-10 -10)">
              <text id="CMOs" transform="translate(666 2417)" fill="#fff"
                    fontSize="20" fontFamily="Roboto-Bold, Roboto"
                    fontWeight="700"
                    onClick={() => {
                      openDialogForCMOs();
                    }}
                    onMouseOver={() => {
                      setHoverCMOs(true);
                    }}
                    onMouseLeave={() => {
                      setHoverCMOs(false);
                    }}
                    style={{
                      pointerEvents: 'auto', cursor: 'pointer',
                    }}
              >
                <tspan x="6.651" y="21">CMOs</tspan>
              </text>
              <g id="gr_1038" data-name="グループ 1038"
                 transform="translate(-51 -827)"
                 onClick={() => {
                   openDialogForCMOs();
                 }}
                 onMouseOver={() => {
                   setHoverCMOs(true);
                 }}
                 onMouseLeave={() => {
                   setHoverCMOs(false);
                 }}
                 style={{
                   pointerEvents: 'auto', cursor: 'pointer',
                 }}
              >
                <circle id="ell_48-2" data-name="楕円形 48" cx="9" cy="9"
                        r={hoverCMOs ? 11 : 9}
                        transform="translate(788 3249)" fill="#fff"/>
                <g id="gr_1022-2" data-name="グループ 1022"
                   transform="translate(16 1)">
                  <rect id="rect_863-2" data-name="長方形 863" width="10"
                        height="2" transform="translate(776 3256)"/>
                  <rect id="rect_864-2" data-name="長方形 864" width="2"
                        height="10" transform="translate(780 3252)"/>
                </g>
              </g>
            </g>

            <g id="マスクgr_79" data-name="マスクグループ 79"
               transform="translate(-2200.26)" opacity="0.3"
               clipPath="url(#clip-path)">
              <g id="gr_1468" data-name="グループ 1468"
                 transform="translate(-345.327 2174.996) rotate(-45)">
                <g id="gr_1040" data-name="グループ 1040"
                   transform="translate(42.4 2186.405)">
                  <path id="path_95" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1223" data-name="グループ 1223"
                   transform="translate(42.4 2491.221)">
                  <path id="path_95-2" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-2" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-2" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-2" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-2" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-2" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-2" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1057" data-name="グループ 1057"
                   transform="translate(42.4 2135.602)">
                  <path id="path_95-3" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-3" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-3" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-3" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-3" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-3" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-3" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1224" data-name="グループ 1224"
                   transform="translate(42.4 2440.418)">
                  <path id="path_95-4" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-4" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-4" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-4" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-4" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-4" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-4" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1066" data-name="グループ 1066"
                   transform="translate(42.4 2084.799)">
                  <path id="path_95-5" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-5" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-5" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-5" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-5" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-5" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-5" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1225" data-name="グループ 1225"
                   transform="translate(42.4 2389.616)">
                  <path id="path_95-6" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-6" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-6" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-6" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-6" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-6" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-6" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1083" data-name="グループ 1083"
                   transform="translate(42.4 2033.997)">
                  <path id="path_95-7" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-7" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-7" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-7" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-7" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-7" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-7" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1226" data-name="グループ 1226"
                   transform="translate(42.4 2338.813)">
                  <path id="path_95-8" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-8" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-8" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-8" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-8" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-8" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-8" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1100" data-name="グループ 1100"
                   transform="translate(42.4 1983.194)">
                  <path id="path_95-9" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-9" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-9" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-9" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-9" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-9" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-9" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1227" data-name="グループ 1227"
                   transform="translate(42.4 2288.01)">
                  <path id="path_95-10" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-10" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-10" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-10" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-10" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-10" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-10" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1117" data-name="グループ 1117"
                   transform="translate(42.4 1932.391)">
                  <path id="path_95-11" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-11" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-11" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-11" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-11" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-11" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-11" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1236" data-name="グループ 1236"
                   transform="translate(42.4 2237.208)">
                  <path id="path_95-12" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-12" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-12" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-12" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-12" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-12" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-12" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1134" data-name="グループ 1134"
                   transform="translate(42.4 1881.588)">
                  <path id="path_95-13" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-13" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-13" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-13" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-13" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-13" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-13" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1051" data-name="グループ 1051"
                   transform="translate(528.12 2186.405)">
                  <path id="path_95-14" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-14" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-14" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-14" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-14" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-14" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-14" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1154" data-name="グループ 1154"
                   transform="translate(528.12 2491.221)">
                  <path id="path_95-15" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-15" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-15" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-15" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-15" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-15" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-15" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1058" data-name="グループ 1058"
                   transform="translate(528.12 2135.602)">
                  <path id="path_95-16" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-16" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-16" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-16" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-16" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-16" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-16" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1155" data-name="グループ 1155"
                   transform="translate(528.12 2440.418)">
                  <path id="path_95-17" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-17" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-17" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-17" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-17" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-17" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-17" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1072" data-name="グループ 1072"
                   transform="translate(528.12 2084.799)">
                  <path id="path_95-18" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-18" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-18" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-18" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-18" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-18" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-18" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1163" data-name="グループ 1163"
                   transform="translate(528.12 2389.616)">
                  <path id="path_95-19" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-19" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-19" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-19" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-19" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-19" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-19" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1089" data-name="グループ 1089"
                   transform="translate(528.12 2033.997)">
                  <path id="path_95-20" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-20" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-20" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-20" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-20" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-20" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-20" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1171" data-name="グループ 1171"
                   transform="translate(528.12 2338.813)">
                  <path id="path_95-21" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-21" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-21" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-21" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-21" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-21" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-21" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1101" data-name="グループ 1101"
                   transform="translate(528.12 1983.194)">
                  <path id="path_95-22" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-22" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-22" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-22" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-22" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-22" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-22" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1203" data-name="グループ 1203"
                   transform="translate(528.12 2288.01)">
                  <path id="path_95-23" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-23" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-23" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-23" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-23" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-23" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-23" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1118" data-name="グループ 1118"
                   transform="translate(528.12 1932.391)">
                  <path id="path_95-24" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-24" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-24" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-24" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-24" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-24" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-24" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1237" data-name="グループ 1237"
                   transform="translate(528.12 2237.208)">
                  <path id="path_95-25" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-25" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-25" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-25" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-25" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-25" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-25" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1140" data-name="グループ 1140"
                   transform="translate(528.12 1881.588)">
                  <path id="path_95-26" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-26" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-26" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-26" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-26" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-26" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-26" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1046" data-name="グループ 1046"
                   transform="translate(82.4 2161.003)">
                  <path id="path_95-27" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-27" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-27" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-27" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-27" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-27" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-27" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1219" data-name="グループ 1219"
                   transform="translate(82.4 2465.82)">
                  <path id="path_95-28" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-28" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-28" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-28" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-28" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-28" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-28" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1075" data-name="グループ 1075"
                   transform="translate(82.4 2110.201)">
                  <path id="path_95-29" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-29" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-29" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-29" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-29" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-29" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-29" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1220" data-name="グループ 1220"
                   transform="translate(82.4 2415.017)">
                  <path id="path_95-30" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-30" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-30" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-30" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-30" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-30" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-30" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1092" data-name="グループ 1092"
                   transform="translate(82.4 2059.398)">
                  <path id="path_95-31" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-31" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-31" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-31" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-31" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-31" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-31" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1221" data-name="グループ 1221"
                   transform="translate(82.4 2364.214)">
                  <path id="path_95-32" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-32" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-32" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-32" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-32" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-32" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-32" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1109" data-name="グループ 1109"
                   transform="translate(82.4 2008.595)">
                  <path id="path_95-33" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-33" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-33" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-33" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-33" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-33" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-33" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1222" data-name="グループ 1222"
                   transform="translate(82.4 2313.412)">
                  <path id="path_95-34" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-34" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-34" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-34" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-34" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-34" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-34" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1126" data-name="グループ 1126"
                   transform="translate(82.4 1957.792)">
                  <path id="path_95-35" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-35" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-35" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-35" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-35" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-35" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-35" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1228" data-name="グループ 1228"
                   transform="translate(82.4 2262.609)">
                  <path id="path_95-36" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-36" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-36" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-36" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-36" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-36" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-36" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1142" data-name="グループ 1142"
                   transform="translate(82.4 1906.99)">
                  <path id="path_95-37" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-37" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-37" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-37" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-37" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-37" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-37" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1245" data-name="グループ 1245"
                   transform="translate(82.4 2211.806)">
                  <path id="path_95-38" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-38" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-38" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-38" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-38" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-38" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-38" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1054" data-name="グループ 1054"
                   transform="translate(487.167 2161.003)">
                  <path id="path_95-39" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-39" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-39" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-39" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-39" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-39" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-39" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1156" data-name="グループ 1156"
                   transform="translate(487.167 2465.82)">
                  <path id="path_95-40" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-40" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-40" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-40" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-40" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-40" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-40" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1076" data-name="グループ 1076"
                   transform="translate(487.167 2110.201)">
                  <path id="path_95-41" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-41" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-41" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-41" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-41" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-41" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-41" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1157" data-name="グループ 1157"
                   transform="translate(487.167 2415.017)">
                  <path id="path_95-42" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-42" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-42" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-42" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-42" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-42" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-42" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1093" data-name="グループ 1093"
                   transform="translate(487.167 2059.398)">
                  <path id="path_95-43" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-43" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-43" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-43" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-43" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-43" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-43" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1172" data-name="グループ 1172"
                   transform="translate(487.167 2364.214)">
                  <path id="path_95-44" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-44" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-44" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-44" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-44" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-44" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-44" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1110" data-name="グループ 1110"
                   transform="translate(487.167 2008.595)">
                  <path id="path_95-45" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-45" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-45" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-45" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-45" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-45" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-45" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1190" data-name="グループ 1190"
                   transform="translate(487.167 2313.412)">
                  <path id="path_95-46" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-46" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-46" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-46" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-46" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-46" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-46" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1127" data-name="グループ 1127"
                   transform="translate(487.167 1957.792)">
                  <path id="path_95-47" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-47" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-47" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-47" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-47" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-47" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-47" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1229" data-name="グループ 1229"
                   transform="translate(487.167 2262.609)">
                  <path id="path_95-48" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-48" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-48" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-48" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-48" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-48" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-48" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1143" data-name="グループ 1143"
                   transform="translate(487.167 1906.99)">
                  <path id="path_95-49" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-49" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-49" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-49" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-49" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-49" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-49" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1246" data-name="グループ 1246"
                   transform="translate(487.167 2211.806)">
                  <path id="path_95-50" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-50" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-50" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-50" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-50" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-50" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-50" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1043" data-name="グループ 1043"
                   transform="translate(204.307 2186.405)">
                  <path id="path_95-51" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-51" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-51" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-51" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-51" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-51" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-51" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1191" data-name="グループ 1191"
                   transform="translate(204.307 2491.221)">
                  <path id="path_95-52" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-52" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-52" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-52" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-52" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-52" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-52" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1059" data-name="グループ 1059"
                   transform="translate(204.307 2135.602)">
                  <path id="path_95-53" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-53" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-53" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-53" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-53" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-53" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-53" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1192" data-name="グループ 1192"
                   transform="translate(204.307 2440.418)">
                  <path id="path_95-54" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-54" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-54" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-54" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-54" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-54" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-54" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1067" data-name="グループ 1067"
                   transform="translate(204.307 2084.799)">
                  <path id="path_95-55" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-55" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-55" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-55" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-55" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-55" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-55" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1193" data-name="グループ 1193"
                   transform="translate(204.307 2389.616)">
                  <path id="path_95-56" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-56" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-56" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-56" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-56" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-56" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-56" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1084" data-name="グループ 1084"
                   transform="translate(204.307 2033.997)">
                  <path id="path_95-57" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-57" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-57" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-57" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-57" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-57" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-57" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1194" data-name="グループ 1194"
                   transform="translate(204.307 2338.813)">
                  <path id="path_95-58" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-58" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-58" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-58" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-58" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-58" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-58" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1102" data-name="グループ 1102"
                   transform="translate(204.307 1983.194)">
                  <path id="path_95-59" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-59" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-59" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-59" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-59" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-59" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-59" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1204" data-name="グループ 1204"
                   transform="translate(204.307 2288.01)">
                  <path id="path_95-60" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-60" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-60" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-60" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-60" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-60" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-60" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1119" data-name="グループ 1119"
                   transform="translate(204.307 1932.391)">
                  <path id="path_95-61" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-61" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-61" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-61" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-61" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-61" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-61" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1238" data-name="グループ 1238"
                   transform="translate(204.307 2237.208)">
                  <path id="path_95-62" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-62" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-62" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-62" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-62" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-62" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-62" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1136" data-name="グループ 1136"
                   transform="translate(204.307 1881.588)">
                  <path id="path_95-63" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-63" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-63" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-63" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-63" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-63" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-63" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1053" data-name="グループ 1053"
                   transform="translate(690.027 2186.405)">
                  <path id="path_95-64" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-64" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-64" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-64" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-64" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-64" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-64" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1151" data-name="グループ 1151"
                   transform="translate(690.027 2491.221)">
                  <path id="path_95-65" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-65" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-65" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-65" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-65" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-65" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-65" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1060" data-name="グループ 1060"
                   transform="translate(690.027 2135.602)">
                  <path id="path_95-66" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-66" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-66" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-66" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-66" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-66" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-66" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1158" data-name="グループ 1158"
                   transform="translate(690.027 2440.418)">
                  <path id="path_95-67" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-67" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-67" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-67" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-67" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-67" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-67" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1074" data-name="グループ 1074"
                   transform="translate(690.027 2084.799)">
                  <path id="path_95-68" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-68" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-68" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-68" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-68" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-68" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-68" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1164" data-name="グループ 1164"
                   transform="translate(690.027 2389.616)">
                  <path id="path_95-69" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-69" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-69" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-69" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-69" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-69" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-69" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1091" data-name="グループ 1091"
                   transform="translate(690.027 2033.997)">
                  <path id="path_95-70" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-70" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-70" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-70" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-70" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-70" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-70" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1173" data-name="グループ 1173"
                   transform="translate(690.027 2338.813)">
                  <path id="path_95-71" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-71" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-71" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-71" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-71" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-71" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-71" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1103" data-name="グループ 1103"
                   transform="translate(690.027 1983.194)">
                  <path id="path_95-72" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-72" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-72" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-72" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-72" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-72" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-72" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1205" data-name="グループ 1205"
                   transform="translate(690.027 2288.01)">
                  <path id="path_95-73" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-73" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-73" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-73" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-73" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-73" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-73" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1120" data-name="グループ 1120"
                   transform="translate(690.027 1932.391)">
                  <path id="path_95-74" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-74" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-74" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-74" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-74" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-74" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-74" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1239" data-name="グループ 1239"
                   transform="translate(690.027 2237.208)">
                  <path id="path_95-75" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-75" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-75" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-75" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-75" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-75" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-75" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1150" data-name="グループ 1150"
                   transform="translate(690.027 1881.588)">
                  <path id="path_95-76" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-76" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-76" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-76" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-76" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-76" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-76" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1048" data-name="グループ 1048"
                   transform="translate(244.307 2161.003)">
                  <path id="path_95-77" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-77" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-77" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-77" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-77" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-77" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-77" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1195" data-name="グループ 1195"
                   transform="translate(244.307 2465.82)">
                  <path id="path_95-78" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-78" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-78" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-78" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-78" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-78" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-78" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1077" data-name="グループ 1077"
                   transform="translate(244.307 2110.201)">
                  <path id="path_95-79" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-79" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-79" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-79" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-79" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-79" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-79" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1196" data-name="グループ 1196"
                   transform="translate(244.307 2415.017)">
                  <path id="path_95-80" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-80" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-80" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-80" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-80" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-80" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-80" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1094" data-name="グループ 1094"
                   transform="translate(244.307 2059.398)">
                  <path id="path_95-81" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-81" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-81" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-81" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-81" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-81" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-81" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1197" data-name="グループ 1197"
                   transform="translate(244.307 2364.214)">
                  <path id="path_95-82" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-82" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-82" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-82" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-82" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-82" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-82" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1111" data-name="グループ 1111"
                   transform="translate(244.307 2008.595)">
                  <path id="path_95-83" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-83" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-83" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-83" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-83" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-83" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-83" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1198" data-name="グループ 1198"
                   transform="translate(244.307 2313.412)">
                  <path id="path_95-84" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-84" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-84" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-84" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-84" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-84" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-84" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1128" data-name="グループ 1128"
                   transform="translate(244.307 1957.792)">
                  <path id="path_95-85" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-85" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-85" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-85" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-85" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-85" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-85" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1230" data-name="グループ 1230"
                   transform="translate(244.307 2262.609)">
                  <path id="path_95-86" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-86" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-86" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-86" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-86" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-86" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-86" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1144" data-name="グループ 1144"
                   transform="translate(244.307 1906.99)">
                  <path id="path_95-87" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-87" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-87" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-87" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-87" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-87" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-87" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1247" data-name="グループ 1247"
                   transform="translate(244.307 2211.806)">
                  <path id="path_95-88" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-88" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-88" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-88" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-88" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-88" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-88" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1056" data-name="グループ 1056"
                   transform="translate(649.074 2161.003)">
                  <path id="path_95-89" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-89" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-89" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-89" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-89" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-89" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-89" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1152" data-name="グループ 1152"
                   transform="translate(649.074 2465.82)">
                  <path id="path_95-90" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-90" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-90" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-90" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-90" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-90" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-90" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1078" data-name="グループ 1078"
                   transform="translate(649.074 2110.201)">
                  <path id="path_95-91" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-91" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-91" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-91" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-91" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-91" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-91" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1159" data-name="グループ 1159"
                   transform="translate(649.074 2415.017)">
                  <path id="path_95-92" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-92" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-92" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-92" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-92" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-92" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-92" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1095" data-name="グループ 1095"
                   transform="translate(649.074 2059.398)">
                  <path id="path_95-93" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-93" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-93" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-93" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-93" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-93" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-93" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1174" data-name="グループ 1174"
                   transform="translate(649.074 2364.214)">
                  <path id="path_95-94" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-94" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-94" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-94" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-94" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-94" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-94" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1112" data-name="グループ 1112"
                   transform="translate(649.074 2008.595)">
                  <path id="path_95-95" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-95" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-95" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-95" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-95" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-95" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-95" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1199" data-name="グループ 1199"
                   transform="translate(649.074 2313.412)">
                  <path id="path_95-96" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-96" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-96" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-96" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-96" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-96" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-96" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1129" data-name="グループ 1129"
                   transform="translate(649.074 1957.792)">
                  <path id="path_95-97" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-97" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-97" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-97" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-97" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-97" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-97" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1231" data-name="グループ 1231"
                   transform="translate(649.074 2262.609)">
                  <path id="path_95-98" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-98" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-98" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-98" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-98" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-98" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-98" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1145" data-name="グループ 1145"
                   transform="translate(649.074 1906.99)">
                  <path id="path_95-99" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-99" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-99" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-99" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-99" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-99" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-99" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1248" data-name="グループ 1248"
                   transform="translate(649.074 2211.806)">
                  <path id="path_95-100" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-100" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-100" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-100" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-100" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-100" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-100" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1045" data-name="グループ 1045"
                   transform="translate(366.214 2186.405)">
                  <path id="path_95-101" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-101" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-101" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-101" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-101" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-101" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-101" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1175" data-name="グループ 1175"
                   transform="translate(366.214 2491.221)">
                  <path id="path_95-102" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-102" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-102" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-102" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-102" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-102" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-102" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1061" data-name="グループ 1061"
                   transform="translate(366.214 2135.602)">
                  <path id="path_95-103" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-103" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-103" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-103" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-103" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-103" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-103" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1176" data-name="グループ 1176"
                   transform="translate(366.214 2440.418)">
                  <path id="path_95-104" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-104" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-104" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-104" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-104" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-104" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-104" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1068" data-name="グループ 1068"
                   transform="translate(366.214 2084.799)">
                  <path id="path_95-105" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-105" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-105" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-105" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-105" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-105" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-105" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1177" data-name="グループ 1177"
                   transform="translate(366.214 2389.616)">
                  <path id="path_95-106" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-106" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-106" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-106" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-106" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-106" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-106" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1085" data-name="グループ 1085"
                   transform="translate(366.214 2033.997)">
                  <path id="path_95-107" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-107" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-107" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-107" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-107" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-107" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-107" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1178" data-name="グループ 1178"
                   transform="translate(366.214 2338.813)">
                  <path id="path_95-108" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-108" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-108" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-108" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-108" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-108" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-108" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1104" data-name="グループ 1104"
                   transform="translate(366.214 1983.194)">
                  <path id="path_95-109" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-109" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-109" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-109" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-109" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-109" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-109" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1206" data-name="グループ 1206"
                   transform="translate(366.214 2288.01)">
                  <path id="path_95-110" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-110" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-110" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-110" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-110" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-110" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-110" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1121" data-name="グループ 1121"
                   transform="translate(366.214 1932.391)">
                  <path id="path_95-111" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-111" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-111" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-111" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-111" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-111" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-111" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1240" data-name="グループ 1240"
                   transform="translate(366.214 2237.208)">
                  <path id="path_95-112" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-112" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-112" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-112" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-112" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-112" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-112" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1137" data-name="グループ 1137"
                   transform="translate(366.214 1881.588)">
                  <path id="path_95-113" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-113" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-113" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-113" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-113" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-113" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-113" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1050" data-name="グループ 1050"
                   transform="translate(406.214 2161.003)">
                  <path id="path_95-114" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-114" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-114" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-114" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-114" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-114" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-114" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1165" data-name="グループ 1165"
                   transform="translate(406.214 2465.82)">
                  <path id="path_95-115" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-115" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-115" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-115" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-115" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-115" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-115" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1079" data-name="グループ 1079"
                   transform="translate(406.214 2110.201)">
                  <path id="path_95-116" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-116" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-116" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-116" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-116" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-116" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-116" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1166" data-name="グループ 1166"
                   transform="translate(406.214 2415.017)">
                  <path id="path_95-117" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-117" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-117" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-117" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-117" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-117" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-117" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1096" data-name="グループ 1096"
                   transform="translate(406.214 2059.398)">
                  <path id="path_95-118" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-118" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-118" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-118" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-118" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-118" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-118" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1179" data-name="グループ 1179"
                   transform="translate(406.214 2364.214)">
                  <path id="path_95-119" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-119" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-119" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-119" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-119" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-119" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-119" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1113" data-name="グループ 1113"
                   transform="translate(406.214 2008.595)">
                  <path id="path_95-120" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-120" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-120" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-120" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-120" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-120" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-120" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1200" data-name="グループ 1200"
                   transform="translate(406.214 2313.412)">
                  <path id="path_95-121" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-121" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-121" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-121" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-121" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-121" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-121" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1130" data-name="グループ 1130"
                   transform="translate(406.214 1957.792)">
                  <path id="path_95-122" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-122" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-122" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-122" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-122" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-122" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-122" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1232" data-name="グループ 1232"
                   transform="translate(406.214 2262.609)">
                  <path id="path_95-123" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-123" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-123" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-123" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-123" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-123" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-123" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1146" data-name="グループ 1146"
                   transform="translate(406.214 1906.99)">
                  <path id="path_95-124" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-124" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-124" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-124" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-124" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-124" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-124" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1249" data-name="グループ 1249"
                   transform="translate(406.214 2211.806)">
                  <path id="path_95-125" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-125" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-125" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-125" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-125" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-125" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-125" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1041" data-name="グループ 1041"
                   transform="translate(123.354 2186.405)">
                  <path id="path_95-126" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-126" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-126" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-126" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-126" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-126" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-126" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1214" data-name="グループ 1214"
                   transform="translate(123.354 2491.221)">
                  <path id="path_95-127" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-127" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-127" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-127" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-127" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-127" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-127" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1062" data-name="グループ 1062"
                   transform="translate(123.354 2135.602)">
                  <path id="path_95-128" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-128" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-128" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-128" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-128" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-128" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-128" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1215" data-name="グループ 1215"
                   transform="translate(123.354 2440.418)">
                  <path id="path_95-129" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-129" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-129" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-129" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-129" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-129" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-129" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1069" data-name="グループ 1069"
                   transform="translate(123.354 2084.799)">
                  <path id="path_95-130" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-130" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-130" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-130" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-130" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-130" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-130" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1216" data-name="グループ 1216"
                   transform="translate(123.354 2389.616)">
                  <path id="path_95-131" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-131" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-131" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-131" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-131" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-131" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-131" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1086" data-name="グループ 1086"
                   transform="translate(123.354 2033.997)">
                  <path id="path_95-132" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-132" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-132" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-132" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-132" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-132" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-132" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1217" data-name="グループ 1217"
                   transform="translate(123.354 2338.813)">
                  <path id="path_95-133" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-133" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-133" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-133" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-133" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-133" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-133" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1105" data-name="グループ 1105"
                   transform="translate(123.354 1983.194)">
                  <path id="path_95-134" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-134" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-134" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-134" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-134" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-134" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-134" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1218" data-name="グループ 1218"
                   transform="translate(123.354 2288.01)">
                  <path id="path_95-135" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-135" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-135" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-135" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-135" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-135" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-135" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1122" data-name="グループ 1122"
                   transform="translate(123.354 1932.391)">
                  <path id="path_95-136" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-136" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-136" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-136" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-136" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-136" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-136" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1241" data-name="グループ 1241"
                   transform="translate(123.354 2237.208)">
                  <path id="path_95-137" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-137" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-137" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-137" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-137" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-137" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-137" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1135" data-name="グループ 1135"
                   transform="translate(123.354 1881.588)">
                  <path id="path_95-138" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-138" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-138" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-138" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-138" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-138" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-138" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1052" data-name="グループ 1052"
                   transform="translate(609.074 2186.405)">
                  <path id="path_95-139" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-139" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-139" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-139" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-139" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-139" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-139" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1153" data-name="グループ 1153"
                   transform="translate(609.074 2491.221)">
                  <path id="path_95-140" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-140" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-140" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-140" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-140" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-140" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-140" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1063" data-name="グループ 1063"
                   transform="translate(609.074 2135.602)">
                  <path id="path_95-141" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-141" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-141" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-141" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-141" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-141" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-141" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1160" data-name="グループ 1160"
                   transform="translate(609.074 2440.418)">
                  <path id="path_95-142" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-142" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-142" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-142" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-142" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-142" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-142" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1073" data-name="グループ 1073"
                   transform="translate(609.074 2084.799)">
                  <path id="path_95-143" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-143" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-143" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-143" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-143" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-143" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-143" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1167" data-name="グループ 1167"
                   transform="translate(609.074 2389.616)">
                  <path id="path_95-144" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-144" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-144" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-144" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-144" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-144" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-144" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1090" data-name="グループ 1090"
                   transform="translate(609.074 2033.997)">
                  <path id="path_95-145" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-145" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-145" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-145" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-145" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-145" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-145" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1180" data-name="グループ 1180"
                   transform="translate(609.074 2338.813)">
                  <path id="path_95-146" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-146" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-146" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-146" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-146" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-146" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-146" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1106" data-name="グループ 1106"
                   transform="translate(609.074 1983.194)">
                  <path id="path_95-147" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-147" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-147" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-147" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-147" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-147" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-147" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1207" data-name="グループ 1207"
                   transform="translate(609.074 2288.01)">
                  <path id="path_95-148" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-148" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-148" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-148" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-148" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-148" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-148" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1123" data-name="グループ 1123"
                   transform="translate(609.074 1932.391)">
                  <path id="path_95-149" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-149" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-149" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-149" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-149" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-149" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-149" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1242" data-name="グループ 1242"
                   transform="translate(609.074 2237.208)">
                  <path id="path_95-150" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-150" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-150" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-150" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-150" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-150" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-150" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1141" data-name="グループ 1141"
                   transform="translate(609.074 1881.588)">
                  <path id="path_95-151" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-151" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-151" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-151" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-151" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-151" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-151" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1047" data-name="グループ 1047"
                   transform="translate(163.354 2161.003)">
                  <path id="path_95-152" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-152" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-152" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-152" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-152" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-152" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-152" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1208" data-name="グループ 1208"
                   transform="translate(163.354 2465.82)">
                  <path id="path_95-153" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-153" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-153" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-153" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-153" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-153" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-153" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1080" data-name="グループ 1080"
                   transform="translate(163.354 2110.201)">
                  <path id="path_95-154" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-154" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-154" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-154" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-154" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-154" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-154" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1209" data-name="グループ 1209"
                   transform="translate(163.354 2415.017)">
                  <path id="path_95-155" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-155" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-155" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-155" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-155" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-155" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-155" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1097" data-name="グループ 1097"
                   transform="translate(163.354 2059.398)">
                  <path id="path_95-156" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-156" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-156" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-156" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-156" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-156" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-156" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1210" data-name="グループ 1210"
                   transform="translate(163.354 2364.214)">
                  <path id="path_95-157" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-157" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-157" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-157" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-157" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-157" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-157" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1114" data-name="グループ 1114"
                   transform="translate(163.354 2008.595)">
                  <path id="path_95-158" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-158" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-158" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-158" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-158" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-158" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-158" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1211" data-name="グループ 1211"
                   transform="translate(163.354 2313.412)">
                  <path id="path_95-159" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-159" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-159" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-159" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-159" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-159" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-159" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1131" data-name="グループ 1131"
                   transform="translate(163.354 1957.792)">
                  <path id="path_95-160" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-160" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-160" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-160" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-160" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-160" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-160" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1233" data-name="グループ 1233"
                   transform="translate(163.354 2262.609)">
                  <path id="path_95-161" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-161" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-161" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-161" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-161" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-161" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-161" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1147" data-name="グループ 1147"
                   transform="translate(163.354 1906.99)">
                  <path id="path_95-162" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-162" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-162" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-162" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-162" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-162" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-162" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1250" data-name="グループ 1250"
                   transform="translate(163.354 2211.806)">
                  <path id="path_95-163" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-163" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-163" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-163" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-163" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-163" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-163" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1055" data-name="グループ 1055"
                   transform="translate(568.12 2161.003)">
                  <path id="path_95-164" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-164" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-164" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-164" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-164" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-164" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-164" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1161" data-name="グループ 1161"
                   transform="translate(568.12 2465.82)">
                  <path id="path_95-165" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-165" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-165" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-165" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-165" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-165" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-165" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1081" data-name="グループ 1081"
                   transform="translate(568.12 2110.201)">
                  <path id="path_95-166" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-166" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-166" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-166" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-166" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-166" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-166" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1162" data-name="グループ 1162"
                   transform="translate(568.12 2415.017)">
                  <path id="path_95-167" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-167" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-167" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-167" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-167" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-167" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-167" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1098" data-name="グループ 1098"
                   transform="translate(568.12 2059.398)">
                  <path id="path_95-168" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-168" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-168" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-168" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-168" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-168" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-168" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1181" data-name="グループ 1181"
                   transform="translate(568.12 2364.214)">
                  <path id="path_95-169" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-169" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-169" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-169" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-169" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-169" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-169" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1115" data-name="グループ 1115"
                   transform="translate(568.12 2008.595)">
                  <path id="path_95-170" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-170" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-170" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-170" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-170" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-170" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-170" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1201" data-name="グループ 1201"
                   transform="translate(568.12 2313.412)">
                  <path id="path_95-171" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-171" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-171" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-171" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-171" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-171" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-171" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1132" data-name="グループ 1132"
                   transform="translate(568.12 1957.792)">
                  <path id="path_95-172" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-172" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-172" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-172" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-172" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-172" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-172" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1234" data-name="グループ 1234"
                   transform="translate(568.12 2262.609)">
                  <path id="path_95-173" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-173" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-173" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-173" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-173" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-173" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-173" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1148" data-name="グループ 1148"
                   transform="translate(568.12 1906.99)">
                  <path id="path_95-174" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-174" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-174" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-174" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-174" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-174" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-174" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1251" data-name="グループ 1251"
                   transform="translate(568.12 2211.806)">
                  <path id="path_95-175" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-175" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-175" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-175" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-175" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-175" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-175" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1042" data-name="グループ 1042"
                   transform="translate(285.26 2186.405)">
                  <path id="path_95-176" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-176" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-176" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-176" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-176" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-176" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-176" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1182" data-name="グループ 1182"
                   transform="translate(285.26 2491.221)">
                  <path id="path_95-177" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-177" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-177" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-177" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-177" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-177" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-177" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1064" data-name="グループ 1064"
                   transform="translate(285.26 2135.602)">
                  <path id="path_95-178" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-178" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-178" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-178" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-178" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-178" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-178" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1183" data-name="グループ 1183"
                   transform="translate(285.26 2440.418)">
                  <path id="path_95-179" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-179" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-179" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-179" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-179" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-179" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-179" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1070" data-name="グループ 1070"
                   transform="translate(285.26 2084.799)">
                  <path id="path_95-180" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-180" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-180" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-180" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-180" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-180" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-180" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1184" data-name="グループ 1184"
                   transform="translate(285.26 2389.616)">
                  <path id="path_95-181" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-181" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-181" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-181" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-181" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-181" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-181" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1087" data-name="グループ 1087"
                   transform="translate(285.26 2033.997)">
                  <path id="path_95-182" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-182" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-182" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-182" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-182" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-182" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-182" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1185" data-name="グループ 1185"
                   transform="translate(285.26 2338.813)">
                  <path id="path_95-183" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-183" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-183" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-183" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-183" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-183" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-183" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1107" data-name="グループ 1107"
                   transform="translate(285.26 1983.194)">
                  <path id="path_95-184" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-184" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-184" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-184" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-184" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-184" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-184" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1212" data-name="グループ 1212"
                   transform="translate(285.26 2288.01)">
                  <path id="path_95-185" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-185" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-185" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-185" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-185" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-185" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-185" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1124" data-name="グループ 1124"
                   transform="translate(285.26 1932.391)">
                  <path id="path_95-186" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-186" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-186" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-186" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-186" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-186" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-186" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1243" data-name="グループ 1243"
                   transform="translate(285.26 2237.208)">
                  <path id="path_95-187" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-187" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-187" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-187" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-187" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-187" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-187" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1138" data-name="グループ 1138"
                   transform="translate(285.26 1881.588)">
                  <path id="path_95-188" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-188" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-188" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-188" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-188" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-188" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-188" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1049" data-name="グループ 1049"
                   transform="translate(325.26 2161.003)">
                  <path id="path_95-189" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-189" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-189" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-189" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-189" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-189" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-189" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1186" data-name="グループ 1186"
                   transform="translate(325.26 2465.82)">
                  <path id="path_95-190" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-190" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-190" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-190" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-190" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-190" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-190" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1082" data-name="グループ 1082"
                   transform="translate(325.26 2110.201)">
                  <path id="path_95-191" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-191" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-191" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-191" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-191" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-191" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-191" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1187" data-name="グループ 1187"
                   transform="translate(325.26 2415.017)">
                  <path id="path_95-192" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-192" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-192" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-192" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-192" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-192" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-192" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1099" data-name="グループ 1099"
                   transform="translate(325.26 2059.398)">
                  <path id="path_95-193" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-193" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-193" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-193" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-193" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-193" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-193" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1188" data-name="グループ 1188"
                   transform="translate(325.26 2364.214)">
                  <path id="path_95-194" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-194" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-194" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-194" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-194" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-194" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-194" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1116" data-name="グループ 1116"
                   transform="translate(325.26 2008.595)">
                  <path id="path_95-195" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-195" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-195" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-195" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-195" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-195" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-195" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1202" data-name="グループ 1202"
                   transform="translate(325.26 2313.412)">
                  <path id="path_95-196" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-196" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-196" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-196" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-196" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-196" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-196" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1133" data-name="グループ 1133"
                   transform="translate(325.26 1957.792)">
                  <path id="path_95-197" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-197" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-197" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-197" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-197" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-197" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-197" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1235" data-name="グループ 1235"
                   transform="translate(325.26 2262.609)">
                  <path id="path_95-198" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-198" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-198" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-198" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-198" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-198" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-198" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1149" data-name="グループ 1149"
                   transform="translate(325.26 1906.99)">
                  <path id="path_95-199" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-199" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-199" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-199" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-199" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-199" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-199" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1252" data-name="グループ 1252"
                   transform="translate(325.26 2211.806)">
                  <path id="path_95-200" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-200" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-200" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-200" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-200" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-200" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-200" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1044" data-name="グループ 1044"
                   transform="translate(447.167 2186.405)">
                  <path id="path_95-201" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-201" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-201" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-201" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-201" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-201" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-201" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1168" data-name="グループ 1168"
                   transform="translate(447.167 2491.221)">
                  <path id="path_95-202" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-202" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-202" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-202" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-202" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-202" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-202" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1065" data-name="グループ 1065"
                   transform="translate(447.167 2135.602)">
                  <path id="path_95-203" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-203" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-203" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-203" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-203" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-203" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-203" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1169" data-name="グループ 1169"
                   transform="translate(447.167 2440.418)">
                  <path id="path_95-204" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-204" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-204" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-204" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-204" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-204" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-204" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1071" data-name="グループ 1071"
                   transform="translate(447.167 2084.799)">
                  <path id="path_95-205" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-205" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-205" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-205" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-205" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-205" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-205" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1170" data-name="グループ 1170"
                   transform="translate(447.167 2389.616)">
                  <path id="path_95-206" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-206" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-206" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-206" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-206" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-206" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-206" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1088" data-name="グループ 1088"
                   transform="translate(447.167 2033.997)">
                  <path id="path_95-207" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-207" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-207" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-207" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-207" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-207" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-207" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1189" data-name="グループ 1189"
                   transform="translate(447.167 2338.813)">
                  <path id="path_95-208" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-208" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-208" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-208" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-208" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-208" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-208" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1108" data-name="グループ 1108"
                   transform="translate(447.167 1983.194)">
                  <path id="path_95-209" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-209" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-209" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-209" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-209" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-209" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-209" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1213" data-name="グループ 1213"
                   transform="translate(447.167 2288.01)">
                  <path id="path_95-210" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-210" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-210" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-210" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-210" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-210" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-210" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1125" data-name="グループ 1125"
                   transform="translate(447.167 1932.391)">
                  <path id="path_95-211" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-211" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-211" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-211" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-211" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-211" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-211" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1244" data-name="グループ 1244"
                   transform="translate(447.167 2237.208)">
                  <path id="path_95-212" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-212" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-212" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-212" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-212" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-212" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-212" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
                <g id="gr_1139" data-name="グループ 1139"
                   transform="translate(447.167 1881.588)">
                  <path id="path_95-213" data-name="パス 95"
                        d="M1989.164,78.685a.216.216,0,0,0,.4-.008l4.05-12.313h2.65l-4.783,13.45a2.118,2.118,0,0,1-2.263,1.453,2.158,2.158,0,0,1-2.224-1.64l-4.72-13.251h2.705Z"
                        transform="translate(-23.355 -0.289)"/>
                  <rect id="rect_574-213" data-name="長方形 574" width="2.429"
                        height="14.795" transform="translate(1928.44 66.08)"/>
                  <path id="path_96-213" data-name="パス 96"
                        d="M1968.189,70.875a1.226,1.226,0,0,0-2.443.119h0l0,4.346a3.627,3.627,0,0,1-.688,2.219,3.918,3.918,0,0,1-6.141-.036,3.757,3.757,0,0,1-.667-2.284V66.377h-2.475l0,8.856a7.319,7.319,0,0,0,.052.927,5.613,5.613,0,0,0,2.764,4.318,6.592,6.592,0,0,0,4.063.862,6.012,6.012,0,0,0,3.836-1.692,5.8,5.8,0,0,0,1.727-4.294Z"
                        transform="translate(-11.858 -0.295)"/>
                  <circle id="ell_33-213" data-name="楕円形 33" cx="1.237"
                          cy="1.237" r="1.237"
                          transform="translate(1953.865 66.096)"/>
                  <path id="path_97-213" data-name="パス 97"
                        d="M2022.092,67.953a7.711,7.711,0,0,0-8.844-1.468l1.052,2.245a5.225,5.225,0,1,1-2.824,5.414h0a1.238,1.238,0,0,0-2.466.155,1.211,1.211,0,0,0,.021.224h0a7.7,7.7,0,1,0,13.065-6.57Z"
                        transform="translate(-34.954)"/>
                  <circle id="ell_34-213" data-name="楕円形 34" cx="1.248"
                          cy="1.248" r="1.248"
                          transform="translate(1974.281 69.601)"/>
                  <path id="path_98-213" data-name="パス 98"
                        d="M1937.683,73.949a.539.539,0,0,1,.009-.845l5.933-6.734h-3.042l-5.1,5.834a2.019,2.019,0,0,0,.033,3l5.148,5.976,3.181,0Z"
                        transform="translate(-2.772 -0.292)"/>
                </g>
              </g>
            </g>
            <g id="gr_1037" data-name="グループ 1037"
               transform="translate(-14 143)">
              <text id="Music_Creators" data-name="Music Creators"
                    transform="translate(617 2001)" fill="#fff" fontSize="24"
                    fontFamily="Roboto-Bold, Roboto" fontWeight="700"
                    onClick={() => {
                      openDialogForMusicCreators();
                    }}
                    onMouseOver={() => {
                      setHoverMusicCreators(true);
                    }}
                    onMouseLeave={() => {
                      setHoverMusicCreators(false);
                    }}
                    style={{
                      pointerEvents: 'auto', cursor: 'pointer',
                    }}
              >
                <tspan x="0" y="25">Music Creators</tspan>
              </text>
              <g id="gr_1036" data-name="グループ 1036"
                 transform="translate(5 -1241)"
                 onClick={() => {
                   openDialogForMusicCreators();
                 }}
                 onMouseOver={() => {
                   setHoverMusicCreators(true);
                 }}
                 onMouseLeave={() => {
                   setHoverMusicCreators(false);
                 }}
                 style={{
                   pointerEvents: 'auto', cursor: 'pointer',
                 }}
              >
                <circle id="ell_48-4" data-name="楕円形 48" cx="9" cy="9"
                        r={hoverMusicCreators ? 11 : 9}
                        transform="translate(788 3249)" fill="#fff"/>
                <g id="gr_1022-4" data-name="グループ 1022"
                   transform="translate(16 1)">
                  <rect id="rect_863-4" data-name="長方形 863" width="10"
                        height="2" transform="translate(776 3256)"/>
                  <rect id="rect_864-4" data-name="長方形 864" width="2"
                        height="10" transform="translate(780 3252)"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <SPKUVOLogo/>
        <p>KUVO supports music creators by empowering the industry with reliable
          data.</p>
      </PCWrap>
  );
}

export function EcoSystemImageSP({
  openDialogForMusicCreators,
  openDialogForVenues,
  openDialogForCMOs,
  openDialogForDJs,
}) {
  return (
      <SPWrap>
        <SPTopMargin/>
        <svg xmlns="http://www.w3.org/2000/svg" width="349.998" height="358.919"
             viewBox="0 0 349.998 358.919">
          <defs>
            <filter id="楕円形_52" x="45.475" y="54.658" width="259.568"
                    height="259.568" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood floodOpacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="交差_1" x="77.317" y="0" width="195.363" height="282.65"
                    filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-2"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-2"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="交差_2" x="9.158" y="86.238" width="263.002"
                    height="221.496" filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-3"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-3"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <filter id="交差_3" x="77.837" y="86.238" width="263.002"
                    height="221.496" filterUnits="userSpaceOnUse">
              <feOffset input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="20" result="blur-4"/>
              <feFlood floodOpacity="0.569"/>
              <feComposite operator="in" in2="blur-4"/>
              <feComposite in="SourceGraphic"/>
            </filter>
            <clipPath id="clip-path">
              <path id="合体_3" data-name="合体 3"
                    d="M105.816,163.233l-.05.043-.05-.043A106.061,106.061,0,0,1,0,182.3,107.339,107.339,0,0,1,68.992,93.724l-.009-.083.092-.034a109.316,109.316,0,0,1-.655-11.969A107.459,107.459,0,0,1,105.766,0a107.459,107.459,0,0,1,37.346,81.638,109.31,109.31,0,0,1-.655,11.969l.092.034-.009.083A107.338,107.338,0,0,1,211.531,182.3a106.058,106.058,0,0,1-105.715-19.063Z"
                    transform="translate(2712.42 1940.677)"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1"/>
            </clipPath>
          </defs>
          <g id="グループ_1488" data-name="グループ 1488"
             transform="translate(-20.001 -2205.08)">
            <g id="楕円形_50" data-name="楕円形 50"
               transform="translate(157.818 2240.133)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="106.09" cy="106.09" r="106.09" stroke="none"/>
              <circle cx="106.09" cy="106.09" r="105.59" fill="none"/>
            </g>
            <g id="楕円形_54" data-name="楕円形 54"
               transform="translate(89.051 2351.818)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="106.09" cy="106.09" r="106.09" stroke="none"/>
              <circle cx="106.09" cy="106.09" r="105.59" fill="none"/>
            </g>
            <g id="楕円形_53" data-name="楕円形 53"
               transform="translate(20.001 2240.133)" fill="rgba(0,0,0,0.5)"
               stroke="rgba(255,255,255,0.5)" strokeMiterlimit="10"
               strokeWidth="1">
              <circle cx="106.09" cy="106.09" r="106.09" stroke="none"/>
              <circle cx="106.09" cy="106.09" r="105.59" fill="none"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 20, 2205.08)"
               filter="url(#楕円形_52)">
              <g id="楕円形_52-2" data-name="楕円形 52"
                 transform="translate(54.47 60.66)" fill="rgba(255,255,255,0.1)"
                 stroke="rgba(255,255,255,0.3)" strokeMiterlimit="10"
                 strokeWidth="0.991">
                <circle cx="120.784" cy="120.784" r="120.784" stroke="none"/>
                <circle cx="120.784" cy="120.784" r="120.288" fill="none"/>
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 20, 2205.08)"
               filter="url(#交差_1)">
              <path id="交差_1-2" data-name="交差 1"
                    d="M0,80.667A105.851,105.851,0,0,1,37.181,0,105.851,105.851,0,0,1,74.363,80.667a105.852,105.852,0,0,1-37.181,80.667A105.852,105.852,0,0,1,0,80.667Z"
                    transform="translate(137.82 60.66)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1" opacity="0.2"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 20, 2205.08)"
               filter="url(#交差_2)">
              <path id="交差_2-2" data-name="交差 2"
                    d="M0,94.048a106.178,106.178,0,0,1,141.92-87.6A106.111,106.111,0,0,1,36.505,100.5,105.879,105.879,0,0,1,0,94.048Z"
                    transform="translate(69.7 146.74)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1" opacity="0.2"/>
            </g>
            <g transform="matrix(1, 0, 0, 1, 20, 2205.08)"
               filter="url(#交差_3)">
              <path id="交差_3-2" data-name="交差 3"
                    d="M0,6.448a106.178,106.178,0,0,1,141.92,87.6,105.881,105.881,0,0,1-36.506,6.448A106.111,106.111,0,0,1,0,6.448Z"
                    transform="translate(138.38 146.74)" fill="#fff"
                    stroke="rgba(0,0,0,0)" strokeMiterlimit="10"
                    strokeWidth="1" opacity="0.2"/>
            </g>
            <text id="Venues" transform="translate(60 2267.01)" fill="#fff"
                  fontSize="14" fontFamily="Roboto-Bold, Roboto"
                  fontWeight="700"
            >
              <tspan x="0" y="15">Venues</tspan>
            </text>


            <text id="DJs" transform="translate(285.269 2267.01)" fill="#fff"
                  fontSize="14" fontFamily="Roboto-Bold, Roboto"
                  fontWeight="700"
            >
              <tspan x="0.614" y="15">DJs</tspan>
            </text>
            <g id="グループ_1035" data-name="グループ 1035"
               transform="translate(-674.681 -978.547)"
            >
              <circle id="楕円形_48" data-name="楕円形 48" cx="7" cy="7" r="7"
                      transform="translate(787.681 3248.681)" fill="#fff"/>
              <g id="グループ_1022" data-name="グループ 1022"
                 transform="translate(790.969 3251.969)">
                <rect id="長方形_863" data-name="長方形 863" width="8"
                      height="2" transform="translate(-0.288 2.712)"/>
                <rect id="長方形_864" data-name="長方形 864" width="2"
                      height="8" transform="translate(2.712 -0.288)"/>
              </g>
            </g>
            <g id="グループ_1470" data-name="グループ 1470"
               transform="translate(-472.681 -978.547)"
            >
              <circle id="楕円形_48-2" data-name="楕円形 48" cx="7" cy="7" r="7"
                      transform="translate(787.681 3248.681)" fill="#fff"/>
              <g id="グループ_1022-2" data-name="グループ 1022"
                 transform="translate(790.969 3251.969)">
                <rect id="長方形_863-2" data-name="長方形 863" width="8"
                      height="2" transform="translate(-0.288 2.712)"/>
                <rect id="長方形_864-2" data-name="長方形 864" width="2"
                      height="8" transform="translate(2.712 -0.288)"/>
              </g>
            </g>
            <g id="グループ_1474" data-name="グループ 1474"
               transform="translate(1 10.133)">
              <text id="CMOs" transform="translate(165 2512.676)" fill="#fff"
                    fontSize="14" fontFamily="Roboto-Bold, Roboto"
                    fontWeight="700"
              >
                <tspan x="0.399" y="15">CMOs</tspan>
              </text>
              <g id="グループ_1473" data-name="グループ 1473"
                 transform="translate(-578.681 -733.68)"
              >
                <circle id="楕円形_48-3" data-name="楕円形 48" cx="7" cy="7"
                        r="7" transform="translate(787.681 3248.681)"
                        fill="#fff"/>
                <g id="グループ_1022-3" data-name="グループ 1022"
                   transform="translate(790.969 3251.969)">
                  <rect id="長方形_863-3" data-name="長方形 863" width="8"
                        height="2" transform="translate(-0.288 2.712)"/>
                  <rect id="長方形_864-3" data-name="長方形 864" width="2"
                        height="8" transform="translate(2.712 -0.288)"/>
                </g>
              </g>
            </g>
            <g id="マスクグループ_79" data-name="マスクグループ 79"
               transform="translate(-2622.719 323.641)" opacity="0.3"
               clipPath="url(#clip-path)">
              <g id="グループ_1468" data-name="グループ 1468"
                 transform="translate(2550.701 2064.163) rotate(-45)">
                <g id="グループ_1040" data-name="グループ 1040"
                   transform="translate(-0.65 172.28)">
                  <path id="パス_95" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1210" data-name="パス 1210"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1211" data-name="パス 1211"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1212" data-name="パス 1212"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1223" data-name="グループ 1223"
                   transform="translate(-1.3 344.561)">
                  <path id="パス_95-2" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1213" data-name="パス 1213"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-2" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1214" data-name="パス 1214"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-2" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1215" data-name="パス 1215"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-2" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1057" data-name="グループ 1057"
                   transform="translate(-0.542 143.567)">
                  <path id="パス_95-3" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1216" data-name="パス 1216"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-3" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1217" data-name="パス 1217"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-3" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1218" data-name="パス 1218"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-3" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1224" data-name="グループ 1224"
                   transform="translate(-1.191 315.848)">
                  <path id="パス_95-4" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1219" data-name="パス 1219"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-4" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1220" data-name="パス 1220"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-4" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1221" data-name="パス 1221"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-4" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1066" data-name="グループ 1066"
                   transform="translate(-0.433 114.854)">
                  <path id="パス_95-5" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1222" data-name="パス 1222"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-5" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1223" data-name="パス 1223"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-5" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1224" data-name="パス 1224"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-5" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1225" data-name="グループ 1225"
                   transform="translate(-1.083 287.134)">
                  <path id="パス_95-6" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1225" data-name="パス 1225"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-6" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1226" data-name="パス 1226"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-6" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1227" data-name="パス 1227"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-6" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1083" data-name="グループ 1083"
                   transform="translate(-0.325 86.14)">
                  <path id="パス_95-7" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1228" data-name="パス 1228"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-7" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1229" data-name="パス 1229"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-7" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1230" data-name="パス 1230"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-7" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1226" data-name="グループ 1226"
                   transform="translate(-0.975 258.421)">
                  <path id="パス_95-8" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1231" data-name="パス 1231"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-8" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1232" data-name="パス 1232"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-8" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1233" data-name="パス 1233"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-8" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1100" data-name="グループ 1100"
                   transform="translate(-0.217 57.427)">
                  <path id="パス_95-9" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1234" data-name="パス 1234"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-9" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1235" data-name="パス 1235"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-9" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1236" data-name="パス 1236"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-9" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1227" data-name="グループ 1227"
                   transform="translate(-0.866 229.707)">
                  <path id="パス_95-10" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1237" data-name="パス 1237"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-10" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1238" data-name="パス 1238"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-10" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1239" data-name="パス 1239"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-10" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1117" data-name="グループ 1117"
                   transform="translate(-0.108 28.713)">
                  <path id="パス_95-11" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1240" data-name="パス 1240"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-11" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1241" data-name="パス 1241"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-11" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1242" data-name="パス 1242"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-11" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1236" data-name="グループ 1236"
                   transform="translate(-0.758 200.994)">
                  <path id="パス_95-12" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1243" data-name="パス 1243"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-12" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1244" data-name="パス 1244"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-12" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1245" data-name="パス 1245"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-12" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1134" data-name="グループ 1134"
                   transform="translate(0 0)">
                  <path id="パス_95-13" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1246" data-name="パス 1246"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-13" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1247" data-name="パス 1247"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-13" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1248" data-name="パス 1248"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-13" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1051" data-name="グループ 1051"
                   transform="translate(273.876 171.245)">
                  <path id="パス_95-14" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1249" data-name="パス 1249"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-14" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1250" data-name="パス 1250"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-14" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1251" data-name="パス 1251"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-14" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1154" data-name="グループ 1154"
                   transform="translate(273.227 343.525)">
                  <path id="パス_95-15" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1252" data-name="パス 1252"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-15" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1253" data-name="パス 1253"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-15" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1254" data-name="パス 1254"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-15" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1058" data-name="グループ 1058"
                   transform="translate(273.985 142.532)">
                  <path id="パス_95-16" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1255" data-name="パス 1255"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-16" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1256" data-name="パス 1256"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-16" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1257" data-name="パス 1257"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-16" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1155" data-name="グループ 1155"
                   transform="translate(273.335 314.812)">
                  <path id="パス_95-17" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1258" data-name="パス 1258"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-17" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1259" data-name="パス 1259"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-17" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1260" data-name="パス 1260"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-17" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1072" data-name="グループ 1072"
                   transform="translate(274.093 113.818)">
                  <path id="パス_95-18" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1261" data-name="パス 1261"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-18" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1262" data-name="パス 1262"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-18" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1263" data-name="パス 1263"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-18" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1163" data-name="グループ 1163"
                   transform="translate(273.443 286.099)">
                  <path id="パス_95-19" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1264" data-name="パス 1264"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-19" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1265" data-name="パス 1265"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-19" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1266" data-name="パス 1266"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-19" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1089" data-name="グループ 1089"
                   transform="translate(274.201 85.105)">
                  <path id="パス_95-20" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1267" data-name="パス 1267"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-20" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1268" data-name="パス 1268"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-20" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1269" data-name="パス 1269"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-20" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1171" data-name="グループ 1171"
                   transform="translate(273.551 257.385)">
                  <path id="パス_95-21" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1270" data-name="パス 1270"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-21" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1271" data-name="パス 1271"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-21" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1272" data-name="パス 1272"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-21" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1101" data-name="グループ 1101"
                   transform="translate(274.31 56.391)">
                  <path id="パス_95-22" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1273" data-name="パス 1273"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-22" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1274" data-name="パス 1274"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-22" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1275" data-name="パス 1275"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-22" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1203" data-name="グループ 1203"
                   transform="translate(273.66 228.672)">
                  <path id="パス_95-23" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1276" data-name="パス 1276"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-23" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1277" data-name="パス 1277"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-23" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1278" data-name="パス 1278"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-23" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1118" data-name="グループ 1118"
                   transform="translate(274.418 27.678)">
                  <path id="パス_95-24" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1279" data-name="パス 1279"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-24" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1280" data-name="パス 1280"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-24" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1281" data-name="パス 1281"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-24" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1237" data-name="グループ 1237"
                   transform="translate(273.768 199.958)">
                  <path id="パス_95-25" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1282" data-name="パス 1282"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-25" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1283" data-name="パス 1283"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-25" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1284" data-name="パス 1284"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-25" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1140" data-name="グループ 1140"
                   transform="translate(274.526 -1.035)">
                  <path id="パス_95-26" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1285" data-name="パス 1285"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-26" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1286" data-name="パス 1286"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-26" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1287" data-name="パス 1287"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-26" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1046" data-name="グループ 1046"
                   transform="translate(22.012 157.839)">
                  <path id="パス_95-27" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1288" data-name="パス 1288"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-27" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1289" data-name="パス 1289"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-27" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1290" data-name="パス 1290"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-27" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1219" data-name="グループ 1219"
                   transform="translate(21.362 330.119)">
                  <path id="パス_95-28" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1291" data-name="パス 1291"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-28" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1292" data-name="パス 1292"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-28" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1293" data-name="パス 1293"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-28" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1075" data-name="グループ 1075"
                   transform="translate(22.12 129.125)">
                  <path id="パス_95-29" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1294" data-name="パス 1294"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-29" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1295" data-name="パス 1295"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-29" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1296" data-name="パス 1296"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-29" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1220" data-name="グループ 1220"
                   transform="translate(21.471 301.406)">
                  <path id="パス_95-30" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1297" data-name="パス 1297"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-30" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1298" data-name="パス 1298"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-30" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1299" data-name="パス 1299"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-30" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1092" data-name="グループ 1092"
                   transform="translate(22.229 100.412)">
                  <path id="パス_95-31" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1300" data-name="パス 1300"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-31" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1301" data-name="パス 1301"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-31" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1302" data-name="パス 1302"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-31" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1221" data-name="グループ 1221"
                   transform="translate(21.579 272.692)">
                  <path id="パス_95-32" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1303" data-name="パス 1303"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-32" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1304" data-name="パス 1304"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-32" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1305" data-name="パス 1305"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-32" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1109" data-name="グループ 1109"
                   transform="translate(22.337 71.698)">
                  <path id="パス_95-33" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1306" data-name="パス 1306"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-33" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1307" data-name="パス 1307"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-33" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1308" data-name="パス 1308"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-33" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1222" data-name="グループ 1222"
                   transform="translate(21.687 243.979)">
                  <path id="パス_95-34" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1309" data-name="パス 1309"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-34" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1310" data-name="パス 1310"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-34" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1311" data-name="パス 1311"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-34" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1126" data-name="グループ 1126"
                   transform="translate(22.445 42.985)">
                  <path id="パス_95-35" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1312" data-name="パス 1312"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-35" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1313" data-name="パス 1313"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-35" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1314" data-name="パス 1314"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-35" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1228" data-name="グループ 1228"
                   transform="translate(21.795 215.265)">
                  <path id="パス_95-36" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1315" data-name="パス 1315"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-36" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1316" data-name="パス 1316"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-36" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1317" data-name="パス 1317"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-36" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1142" data-name="グループ 1142"
                   transform="translate(22.554 14.271)">
                  <path id="パス_95-37" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1318" data-name="パス 1318"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-37" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1319" data-name="パス 1319"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-37" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1320" data-name="パス 1320"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-37" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1245" data-name="グループ 1245"
                   transform="translate(21.904 186.552)">
                  <path id="パス_95-38" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1321" data-name="パス 1321"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-38" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1322" data-name="パス 1322"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-38" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1323" data-name="パス 1323"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-38" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1054" data-name="グループ 1054"
                   transform="translate(250.784 156.976)">
                  <path id="パス_95-39" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1324" data-name="パス 1324"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-39" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1325" data-name="パス 1325"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-39" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1326" data-name="パス 1326"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-39" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1156" data-name="グループ 1156"
                   transform="translate(250.134 329.256)">
                  <path id="パス_95-40" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1327" data-name="パス 1327"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-40" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1328" data-name="パス 1328"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-40" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1329" data-name="パス 1329"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-40" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1076" data-name="グループ 1076"
                   transform="translate(250.892 128.262)">
                  <path id="パス_95-41" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1330" data-name="パス 1330"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-41" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1331" data-name="パス 1331"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-41" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1332" data-name="パス 1332"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-41" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1157" data-name="グループ 1157"
                   transform="translate(250.242 300.543)">
                  <path id="パス_95-42" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1333" data-name="パス 1333"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-42" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1334" data-name="パス 1334"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-42" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1335" data-name="パス 1335"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-42" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1093" data-name="グループ 1093"
                   transform="translate(251.001 99.549)">
                  <path id="パス_95-43" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1336" data-name="パス 1336"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-43" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1337" data-name="パス 1337"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-43" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1338" data-name="パス 1338"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-43" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1172" data-name="グループ 1172"
                   transform="translate(250.351 271.829)">
                  <path id="パス_95-44" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1339" data-name="パス 1339"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-44" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1340" data-name="パス 1340"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-44" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1341" data-name="パス 1341"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-44" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1110" data-name="グループ 1110"
                   transform="translate(251.109 70.835)">
                  <path id="パス_95-45" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1342" data-name="パス 1342"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-45" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1343" data-name="パス 1343"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-45" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1344" data-name="パス 1344"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-45" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1190" data-name="グループ 1190"
                   transform="translate(250.459 243.116)">
                  <path id="パス_95-46" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1345" data-name="パス 1345"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-46" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1346" data-name="パス 1346"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-46" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1347" data-name="パス 1347"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-46" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1127" data-name="グループ 1127"
                   transform="translate(251.217 42.122)">
                  <path id="パス_95-47" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1348" data-name="パス 1348"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-47" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1349" data-name="パス 1349"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-47" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1350" data-name="パス 1350"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-47" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1229" data-name="グループ 1229"
                   transform="translate(250.567 214.402)">
                  <path id="パス_95-48" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1351" data-name="パス 1351"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-48" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1352" data-name="パス 1352"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-48" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1353" data-name="パス 1353"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-48" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1143" data-name="グループ 1143"
                   transform="translate(251.325 13.409)">
                  <path id="パス_95-49" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1354" data-name="パス 1354"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-49" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1355" data-name="パス 1355"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-49" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1356" data-name="パス 1356"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-49" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1246" data-name="グループ 1246"
                   transform="translate(250.676 185.689)">
                  <path id="パス_95-50" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1357" data-name="パス 1357"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-50" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1358" data-name="パス 1358"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-50" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1359" data-name="パス 1359"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-50" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1043" data-name="グループ 1043"
                   transform="translate(90.859 171.935)">
                  <path id="パス_95-51" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1360" data-name="パス 1360"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-51" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1361" data-name="パス 1361"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-51" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1362" data-name="パス 1362"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-51" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1191" data-name="グループ 1191"
                   transform="translate(90.209 344.216)">
                  <path id="パス_95-52" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1363" data-name="パス 1363"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-52" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1364" data-name="パス 1364"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-52" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1365" data-name="パス 1365"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-52" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1059" data-name="グループ 1059"
                   transform="translate(90.967 143.222)">
                  <path id="パス_95-53" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1366" data-name="パス 1366"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-53" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1367" data-name="パス 1367"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-53" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1368" data-name="パス 1368"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-53" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1192" data-name="グループ 1192"
                   transform="translate(90.317 315.502)">
                  <path id="パス_95-54" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1369" data-name="パス 1369"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-54" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1370" data-name="パス 1370"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-54" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1371" data-name="パス 1371"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-54" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1067" data-name="グループ 1067"
                   transform="translate(91.076 114.508)">
                  <path id="パス_95-55" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1372" data-name="パス 1372"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-55" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1373" data-name="パス 1373"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-55" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1374" data-name="パス 1374"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-55" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1193" data-name="グループ 1193"
                   transform="translate(90.426 286.789)">
                  <path id="パス_95-56" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1375" data-name="パス 1375"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-56" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1376" data-name="パス 1376"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-56" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1377" data-name="パス 1377"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-56" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1084" data-name="グループ 1084"
                   transform="translate(91.184 85.795)">
                  <path id="パス_95-57" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1378" data-name="パス 1378"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-57" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1379" data-name="パス 1379"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-57" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1380" data-name="パス 1380"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-57" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1194" data-name="グループ 1194"
                   transform="translate(90.534 258.076)">
                  <path id="パス_95-58" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1381" data-name="パス 1381"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-58" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1382" data-name="パス 1382"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-58" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1383" data-name="パス 1383"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-58" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1102" data-name="グループ 1102"
                   transform="translate(91.292 57.082)">
                  <path id="パス_95-59" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1384" data-name="パス 1384"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-59" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1385" data-name="パス 1385"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-59" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1386" data-name="パス 1386"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-59" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1204" data-name="グループ 1204"
                   transform="translate(90.642 229.362)">
                  <path id="パス_95-60" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1387" data-name="パス 1387"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-60" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1388" data-name="パス 1388"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-60" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1389" data-name="パス 1389"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-60" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1119" data-name="グループ 1119"
                   transform="translate(91.4 28.368)">
                  <path id="パス_95-61" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1390" data-name="パス 1390"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-61" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1391" data-name="パス 1391"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-61" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1392" data-name="パス 1392"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-61" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1238" data-name="グループ 1238"
                   transform="translate(90.751 200.649)">
                  <path id="パス_95-62" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1393" data-name="パス 1393"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-62" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1394" data-name="パス 1394"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-62" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1395" data-name="パス 1395"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-62" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1136" data-name="グループ 1136"
                   transform="translate(91.509 -0.345)">
                  <path id="パス_95-63" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1396" data-name="パス 1396"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-63" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1397" data-name="パス 1397"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-63" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1398" data-name="パス 1398"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-63" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1053" data-name="グループ 1053"
                   transform="translate(365.385 170.9)">
                  <path id="パス_95-64" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1399" data-name="パス 1399"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-64" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1400" data-name="パス 1400"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-64" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1401" data-name="パス 1401"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-64" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1151" data-name="グループ 1151"
                   transform="translate(364.735 343.18)">
                  <path id="パス_95-65" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1402" data-name="パス 1402"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-65" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1403" data-name="パス 1403"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-65" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1404" data-name="パス 1404"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-65" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1060" data-name="グループ 1060"
                   transform="translate(365.493 142.186)">
                  <path id="パス_95-66" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1405" data-name="パス 1405"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-66" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1406" data-name="パス 1406"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-66" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1407" data-name="パス 1407"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-66" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1158" data-name="グループ 1158"
                   transform="translate(364.844 314.467)">
                  <path id="パス_95-67" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1408" data-name="パス 1408"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-67" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1409" data-name="パス 1409"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-67" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1410" data-name="パス 1410"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-67" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1074" data-name="グループ 1074"
                   transform="translate(365.602 113.473)">
                  <path id="パス_95-68" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1411" data-name="パス 1411"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-68" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1412" data-name="パス 1412"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-68" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1413" data-name="パス 1413"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-68" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1164" data-name="グループ 1164"
                   transform="translate(364.952 285.754)">
                  <path id="パス_95-69" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1414" data-name="パス 1414"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-69" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1415" data-name="パス 1415"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-69" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1416" data-name="パス 1416"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-69" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1091" data-name="グループ 1091"
                   transform="translate(365.71 84.76)">
                  <path id="パス_95-70" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1417" data-name="パス 1417"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-70" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1418" data-name="パス 1418"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-70" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1419" data-name="パス 1419"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-70" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1173" data-name="グループ 1173"
                   transform="translate(365.06 257.04)">
                  <path id="パス_95-71" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1420" data-name="パス 1420"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-71" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1421" data-name="パス 1421"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-71" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1422" data-name="パス 1422"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-71" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1103" data-name="グループ 1103"
                   transform="translate(365.818 56.046)">
                  <path id="パス_95-72" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1423" data-name="パス 1423"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-72" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1424" data-name="パス 1424"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-72" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1425" data-name="パス 1425"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-72" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1205" data-name="グループ 1205"
                   transform="translate(365.169 228.327)">
                  <path id="パス_95-73" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1426" data-name="パス 1426"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-73" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1427" data-name="パス 1427"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-73" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1428" data-name="パス 1428"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-73" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1120" data-name="グループ 1120"
                   transform="translate(365.927 27.333)">
                  <path id="パス_95-74" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1429" data-name="パス 1429"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-74" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1430" data-name="パス 1430"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-74" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1431" data-name="パス 1431"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-74" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1239" data-name="グループ 1239"
                   transform="translate(365.277 199.613)">
                  <path id="パス_95-75" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1432" data-name="パス 1432"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-75" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1433" data-name="パス 1433"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-75" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1434" data-name="パス 1434"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-75" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1150" data-name="グループ 1150"
                   transform="translate(366.035 -1.381)">
                  <path id="パス_95-76" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1435" data-name="パス 1435"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-76" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1436" data-name="パス 1436"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-76" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1437" data-name="パス 1437"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-76" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1048" data-name="グループ 1048"
                   transform="translate(113.521 157.493)">
                  <path id="パス_95-77" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1438" data-name="パス 1438"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-77" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1439" data-name="パス 1439"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-77" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1440" data-name="パス 1440"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-77" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1195" data-name="グループ 1195"
                   transform="translate(112.871 329.774)">
                  <path id="パス_95-78" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1441" data-name="パス 1441"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-78" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1442" data-name="パス 1442"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-78" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1443" data-name="パス 1443"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-78" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1077" data-name="グループ 1077"
                   transform="translate(113.629 128.78)">
                  <path id="パス_95-79" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1444" data-name="パス 1444"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-79" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1445" data-name="パス 1445"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-79" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1446" data-name="パス 1446"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-79" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1196" data-name="グループ 1196"
                   transform="translate(112.979 301.06)">
                  <path id="パス_95-80" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1447" data-name="パス 1447"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-80" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1448" data-name="パス 1448"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-80" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1449" data-name="パス 1449"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-80" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1094" data-name="グループ 1094"
                   transform="translate(113.737 100.067)">
                  <path id="パス_95-81" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1450" data-name="パス 1450"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-81" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1451" data-name="パス 1451"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-81" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1452" data-name="パス 1452"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-81" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1197" data-name="グループ 1197"
                   transform="translate(113.088 272.347)">
                  <path id="パス_95-82" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1453" data-name="パス 1453"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-82" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1454" data-name="パス 1454"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-82" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1455" data-name="パス 1455"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-82" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1111" data-name="グループ 1111"
                   transform="translate(113.846 71.353)">
                  <path id="パス_95-83" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1456" data-name="パス 1456"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-83" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1457" data-name="パス 1457"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-83" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1458" data-name="パス 1458"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-83" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1198" data-name="グループ 1198"
                   transform="translate(113.196 243.634)">
                  <path id="パス_95-84" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1459" data-name="パス 1459"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-84" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1460" data-name="パス 1460"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-84" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1461" data-name="パス 1461"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-84" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1128" data-name="グループ 1128"
                   transform="translate(113.954 42.64)">
                  <path id="パス_95-85" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1462" data-name="パス 1462"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-85" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1463" data-name="パス 1463"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-85" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1464" data-name="パス 1464"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-85" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1230" data-name="グループ 1230"
                   transform="translate(113.304 214.92)">
                  <path id="パス_95-86" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1465" data-name="パス 1465"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-86" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1466" data-name="パス 1466"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-86" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1467" data-name="パス 1467"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-86" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1144" data-name="グループ 1144"
                   transform="translate(114.062 13.926)">
                  <path id="パス_95-87" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1468" data-name="パス 1468"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-87" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1469" data-name="パス 1469"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-87" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1470" data-name="パス 1470"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-87" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1247" data-name="グループ 1247"
                   transform="translate(113.413 186.207)">
                  <path id="パス_95-88" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1471" data-name="パス 1471"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-88" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1472" data-name="パス 1472"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-88" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1473" data-name="パス 1473"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-88" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1056" data-name="グループ 1056"
                   transform="translate(342.293 156.63)">
                  <path id="パス_95-89" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1474" data-name="パス 1474"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-89" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1475" data-name="パス 1475"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-89" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1476" data-name="パス 1476"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-89" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1152" data-name="グループ 1152"
                   transform="translate(341.643 328.911)">
                  <path id="パス_95-90" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1477" data-name="パス 1477"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-90" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1478" data-name="パス 1478"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-90" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1479" data-name="パス 1479"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-90" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1078" data-name="グループ 1078"
                   transform="translate(342.401 127.917)">
                  <path id="パス_95-91" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1480" data-name="パス 1480"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-91" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1481" data-name="パス 1481"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-91" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1482" data-name="パス 1482"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-91" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1159" data-name="グループ 1159"
                   transform="translate(341.751 300.197)">
                  <path id="パス_95-92" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1483" data-name="パス 1483"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-92" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1484" data-name="パス 1484"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-92" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1485" data-name="パス 1485"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-92" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1095" data-name="グループ 1095"
                   transform="translate(342.509 99.204)">
                  <path id="パス_95-93" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1486" data-name="パス 1486"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-93" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1487" data-name="パス 1487"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-93" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1488" data-name="パス 1488"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-93" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1174" data-name="グループ 1174"
                   transform="translate(341.859 271.484)">
                  <path id="パス_95-94" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1489" data-name="パス 1489"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-94" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1490" data-name="パス 1490"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-94" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1491" data-name="パス 1491"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-94" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1112" data-name="グループ 1112"
                   transform="translate(342.618 70.49)">
                  <path id="パス_95-95" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1492" data-name="パス 1492"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-95" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1493" data-name="パス 1493"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-95" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1494" data-name="パス 1494"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-95" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1199" data-name="グループ 1199"
                   transform="translate(341.968 242.771)">
                  <path id="パス_95-96" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1495" data-name="パス 1495"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-96" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1496" data-name="パス 1496"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-96" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1497" data-name="パス 1497"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-96" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1129" data-name="グループ 1129"
                   transform="translate(342.726 41.777)">
                  <path id="パス_95-97" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1498" data-name="パス 1498"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-97" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1499" data-name="パス 1499"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-97" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1500" data-name="パス 1500"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-97" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1231" data-name="グループ 1231"
                   transform="translate(342.076 214.057)">
                  <path id="パス_95-98" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1501" data-name="パス 1501"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-98" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1502" data-name="パス 1502"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-98" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1503" data-name="パス 1503"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-98" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1145" data-name="グループ 1145"
                   transform="translate(342.834 13.063)">
                  <path id="パス_95-99" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1504" data-name="パス 1504"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-99" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1505" data-name="パス 1505"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-99" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1506" data-name="パス 1506"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-99" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1248" data-name="グループ 1248"
                   transform="translate(342.184 185.344)">
                  <path id="パス_95-100" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1507" data-name="パス 1507"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-100" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1508" data-name="パス 1508"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-100" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1509" data-name="パス 1509"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-100" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1045" data-name="グループ 1045"
                   transform="translate(182.368 171.59)">
                  <path id="パス_95-101" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1510" data-name="パス 1510"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-101" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1511" data-name="パス 1511"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-101" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1512" data-name="パス 1512"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-101" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1175" data-name="グループ 1175"
                   transform="translate(181.718 343.871)">
                  <path id="パス_95-102" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1513" data-name="パス 1513"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-102" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1514" data-name="パス 1514"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-102" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1515" data-name="パス 1515"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-102" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1061" data-name="グループ 1061"
                   transform="translate(182.476 142.877)">
                  <path id="パス_95-103" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1516" data-name="パス 1516"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-103" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1517" data-name="パス 1517"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-103" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1518" data-name="パス 1518"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-103" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1176" data-name="グループ 1176"
                   transform="translate(181.826 315.157)">
                  <path id="パス_95-104" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1519" data-name="パス 1519"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-104" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1520" data-name="パス 1520"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-104" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1521" data-name="パス 1521"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-104" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1068" data-name="グループ 1068"
                   transform="translate(182.584 114.163)">
                  <path id="パス_95-105" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1522" data-name="パス 1522"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-105" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1523" data-name="パス 1523"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-105" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1524" data-name="パス 1524"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-105" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1177" data-name="グループ 1177"
                   transform="translate(181.934 286.444)">
                  <path id="パス_95-106" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1525" data-name="パス 1525"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-106" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1526" data-name="パス 1526"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-106" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1527" data-name="パス 1527"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-106" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1085" data-name="グループ 1085"
                   transform="translate(182.693 85.45)">
                  <path id="パス_95-107" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1528" data-name="パス 1528"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-107" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1529" data-name="パス 1529"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-107" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1530" data-name="パス 1530"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-107" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1178" data-name="グループ 1178"
                   transform="translate(182.043 257.73)">
                  <path id="パス_95-108" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1531" data-name="パス 1531"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-108" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1532" data-name="パス 1532"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-108" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1533" data-name="パス 1533"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-108" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1104" data-name="グループ 1104"
                   transform="translate(182.801 56.737)">
                  <path id="パス_95-109" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1534" data-name="パス 1534"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-109" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1535" data-name="パス 1535"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-109" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1536" data-name="パス 1536"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-109" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1206" data-name="グループ 1206"
                   transform="translate(182.151 229.017)">
                  <path id="パス_95-110" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1537" data-name="パス 1537"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-110" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1538" data-name="パス 1538"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-110" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1539" data-name="パス 1539"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-110" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1121" data-name="グループ 1121"
                   transform="translate(182.909 28.023)">
                  <path id="パス_95-111" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1540" data-name="パス 1540"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-111" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1541" data-name="パス 1541"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-111" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1542" data-name="パス 1542"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-111" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1240" data-name="グループ 1240"
                   transform="translate(182.259 200.304)">
                  <path id="パス_95-112" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1543" data-name="パス 1543"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-112" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1544" data-name="パス 1544"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-112" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1545" data-name="パス 1545"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-112" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1137" data-name="グループ 1137"
                   transform="translate(183.017 -0.69)">
                  <path id="パス_95-113" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1546" data-name="パス 1546"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-113" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1547" data-name="パス 1547"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-113" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1548" data-name="パス 1548"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-113" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1050" data-name="グループ 1050"
                   transform="translate(205.03 157.148)">
                  <path id="パス_95-114" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1549" data-name="パス 1549"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-114" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1550" data-name="パス 1550"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-114" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1551" data-name="パス 1551"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-114" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1165" data-name="グループ 1165"
                   transform="translate(204.38 329.429)">
                  <path id="パス_95-115" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1552" data-name="パス 1552"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-115" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1553" data-name="パス 1553"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-115" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1554" data-name="パス 1554"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-115" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1079" data-name="グループ 1079"
                   transform="translate(205.138 128.435)">
                  <path id="パス_95-116" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1555" data-name="パス 1555"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-116" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1556" data-name="パス 1556"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-116" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1557" data-name="パス 1557"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-116" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1166" data-name="グループ 1166"
                   transform="translate(204.488 300.715)">
                  <path id="パス_95-117" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1558" data-name="パス 1558"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-117" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1559" data-name="パス 1559"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-117" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1560" data-name="パス 1560"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-117" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1096" data-name="グループ 1096"
                   transform="translate(205.246 99.721)">
                  <path id="パス_95-118" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1561" data-name="パス 1561"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-118" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1562" data-name="パス 1562"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-118" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1563" data-name="パス 1563"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-118" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1179" data-name="グループ 1179"
                   transform="translate(204.596 272.002)">
                  <path id="パス_95-119" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1564" data-name="パス 1564"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-119" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1565" data-name="パス 1565"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-119" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1566" data-name="パス 1566"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-119" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1113" data-name="グループ 1113"
                   transform="translate(205.354 71.008)">
                  <path id="パス_95-120" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1567" data-name="パス 1567"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-120" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1568" data-name="パス 1568"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-120" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1569" data-name="パス 1569"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-120" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1200" data-name="グループ 1200"
                   transform="translate(204.705 243.288)">
                  <path id="パス_95-121" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1570" data-name="パス 1570"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-121" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1571" data-name="パス 1571"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-121" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1572" data-name="パス 1572"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-121" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1130" data-name="グループ 1130"
                   transform="translate(205.463 42.295)">
                  <path id="パス_95-122" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1573" data-name="パス 1573"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-122" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1574" data-name="パス 1574"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-122" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1575" data-name="パス 1575"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-122" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1232" data-name="グループ 1232"
                   transform="translate(204.813 214.575)">
                  <path id="パス_95-123" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1576" data-name="パス 1576"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-123" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1577" data-name="パス 1577"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-123" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1578" data-name="パス 1578"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-123" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1146" data-name="グループ 1146"
                   transform="translate(205.571 13.581)">
                  <path id="パス_95-124" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1579" data-name="パス 1579"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-124" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1580" data-name="パス 1580"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-124" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1581" data-name="パス 1581"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-124" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1249" data-name="グループ 1249"
                   transform="translate(204.921 185.862)">
                  <path id="パス_95-125" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1582" data-name="パス 1582"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-125" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1583" data-name="パス 1583"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-125" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1584" data-name="パス 1584"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-125" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1041" data-name="グループ 1041"
                   transform="translate(45.105 172.108)">
                  <path id="パス_95-126" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1585" data-name="パス 1585"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-126" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1586" data-name="パス 1586"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-126" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1587" data-name="パス 1587"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-126" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1214" data-name="グループ 1214"
                   transform="translate(44.455 344.388)">
                  <path id="パス_95-127" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1588" data-name="パス 1588"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-127" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1589" data-name="パス 1589"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-127" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1590" data-name="パス 1590"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-127" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1062" data-name="グループ 1062"
                   transform="translate(45.213 143.394)">
                  <path id="パス_95-128" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1591" data-name="パス 1591"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-128" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1592" data-name="パス 1592"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-128" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1593" data-name="パス 1593"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-128" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1215" data-name="グループ 1215"
                   transform="translate(44.563 315.675)">
                  <path id="パス_95-129" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1594" data-name="パス 1594"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-129" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1595" data-name="パス 1595"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-129" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1596" data-name="パス 1596"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-129" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1069" data-name="グループ 1069"
                   transform="translate(45.321 114.681)">
                  <path id="パス_95-130" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1597" data-name="パス 1597"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-130" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1598" data-name="パス 1598"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-130" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1599" data-name="パス 1599"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-130" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1216" data-name="グループ 1216"
                   transform="translate(44.671 286.962)">
                  <path id="パス_95-131" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1600" data-name="パス 1600"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-131" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1601" data-name="パス 1601"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-131" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1602" data-name="パス 1602"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-131" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1086" data-name="グループ 1086"
                   transform="translate(45.429 85.968)">
                  <path id="パス_95-132" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1603" data-name="パス 1603"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-132" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1604" data-name="パス 1604"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-132" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1605" data-name="パス 1605"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-132" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1217" data-name="グループ 1217"
                   transform="translate(44.78 258.248)">
                  <path id="パス_95-133" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1606" data-name="パス 1606"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-133" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1607" data-name="パス 1607"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-133" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1608" data-name="パス 1608"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-133" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1105" data-name="グループ 1105"
                   transform="translate(45.538 57.254)">
                  <path id="パス_95-134" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1609" data-name="パス 1609"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-134" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1610" data-name="パス 1610"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-134" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1611" data-name="パス 1611"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-134" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1218" data-name="グループ 1218"
                   transform="translate(44.888 229.535)">
                  <path id="パス_95-135" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1612" data-name="パス 1612"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-135" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1613" data-name="パス 1613"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-135" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1614" data-name="パス 1614"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-135" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1122" data-name="グループ 1122"
                   transform="translate(45.646 28.541)">
                  <path id="パス_95-136" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1615" data-name="パス 1615"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-136" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1616" data-name="パス 1616"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-136" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1617" data-name="パス 1617"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-136" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1241" data-name="グループ 1241"
                   transform="translate(44.996 200.821)">
                  <path id="パス_95-137" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1618" data-name="パス 1618"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-137" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1619" data-name="パス 1619"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-137" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1620" data-name="パス 1620"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-137" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1135" data-name="グループ 1135"
                   transform="translate(45.754 -0.173)">
                  <path id="パス_95-138" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1621" data-name="パス 1621"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-138" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1622" data-name="パス 1622"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-138" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1623" data-name="パス 1623"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-138" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1052" data-name="グループ 1052"
                   transform="translate(319.631 171.072)">
                  <path id="パス_95-139" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1624" data-name="パス 1624"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-139" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1625" data-name="パス 1625"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-139" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1626" data-name="パス 1626"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-139" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1153" data-name="グループ 1153"
                   transform="translate(318.981 343.353)">
                  <path id="パス_95-140" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1627" data-name="パス 1627"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-140" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1628" data-name="パス 1628"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-140" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1629" data-name="パス 1629"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-140" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1063" data-name="グループ 1063"
                   transform="translate(319.739 142.359)">
                  <path id="パス_95-141" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1630" data-name="パス 1630"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-141" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1631" data-name="パス 1631"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-141" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1632" data-name="パス 1632"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-141" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1160" data-name="グループ 1160"
                   transform="translate(319.089 314.639)">
                  <path id="パス_95-142" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1633" data-name="パス 1633"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-142" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1634" data-name="パス 1634"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-142" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1635" data-name="パス 1635"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-142" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1073" data-name="グループ 1073"
                   transform="translate(319.847 113.646)">
                  <path id="パス_95-143" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1636" data-name="パス 1636"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-143" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1637" data-name="パス 1637"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-143" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1638" data-name="パス 1638"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-143" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1167" data-name="グループ 1167"
                   transform="translate(319.198 285.926)">
                  <path id="パス_95-144" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1639" data-name="パス 1639"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-144" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1640" data-name="パス 1640"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-144" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1641" data-name="パス 1641"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-144" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1090" data-name="グループ 1090"
                   transform="translate(319.956 84.932)">
                  <path id="パス_95-145" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1642" data-name="パス 1642"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-145" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1643" data-name="パス 1643"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-145" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1644" data-name="パス 1644"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-145" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1180" data-name="グループ 1180"
                   transform="translate(319.306 257.213)">
                  <path id="パス_95-146" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1645" data-name="パス 1645"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-146" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1646" data-name="パス 1646"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-146" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1647" data-name="パス 1647"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-146" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1106" data-name="グループ 1106"
                   transform="translate(320.064 56.219)">
                  <path id="パス_95-147" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1648" data-name="パス 1648"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-147" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1649" data-name="パス 1649"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-147" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1650" data-name="パス 1650"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-147" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1207" data-name="グループ 1207"
                   transform="translate(319.414 228.499)">
                  <path id="パス_95-148" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1651" data-name="パス 1651"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-148" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1652" data-name="パス 1652"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-148" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1653" data-name="パス 1653"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-148" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1123" data-name="グループ 1123"
                   transform="translate(320.172 27.505)">
                  <path id="パス_95-149" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1654" data-name="パス 1654"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-149" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1655" data-name="パス 1655"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-149" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1656" data-name="パス 1656"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-149" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1242" data-name="グループ 1242"
                   transform="translate(319.522 199.786)">
                  <path id="パス_95-150" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1657" data-name="パス 1657"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-150" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1658" data-name="パス 1658"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-150" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1659" data-name="パス 1659"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-150" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1141" data-name="グループ 1141"
                   transform="translate(320.281 -1.208)">
                  <path id="パス_95-151" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1660" data-name="パス 1660"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-151" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1661" data-name="パス 1661"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-151" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1662" data-name="パス 1662"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-151" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1047" data-name="グループ 1047"
                   transform="translate(67.766 157.666)">
                  <path id="パス_95-152" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1663" data-name="パス 1663"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-152" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1664" data-name="パス 1664"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-152" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1665" data-name="パス 1665"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-152" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1208" data-name="グループ 1208"
                   transform="translate(67.117 329.946)">
                  <path id="パス_95-153" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1666" data-name="パス 1666"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-153" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1667" data-name="パス 1667"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-153" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1668" data-name="パス 1668"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-153" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1080" data-name="グループ 1080"
                   transform="translate(67.875 128.952)">
                  <path id="パス_95-154" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1669" data-name="パス 1669"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-154" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1670" data-name="パス 1670"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-154" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1671" data-name="パス 1671"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-154" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1209" data-name="グループ 1209"
                   transform="translate(67.225 301.233)">
                  <path id="パス_95-155" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1672" data-name="パス 1672"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-155" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1673" data-name="パス 1673"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-155" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1674" data-name="パス 1674"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-155" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1097" data-name="グループ 1097"
                   transform="translate(67.983 100.239)">
                  <path id="パス_95-156" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1675" data-name="パス 1675"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-156" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1676" data-name="パス 1676"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-156" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1677" data-name="パス 1677"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-156" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1210" data-name="グループ 1210"
                   transform="translate(67.333 272.52)">
                  <path id="パス_95-157" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1678" data-name="パス 1678"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-157" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1679" data-name="パス 1679"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-157" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1680" data-name="パス 1680"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-157" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1114" data-name="グループ 1114"
                   transform="translate(68.091 71.526)">
                  <path id="パス_95-158" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1681" data-name="パス 1681"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-158" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1682" data-name="パス 1682"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-158" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1683" data-name="パス 1683"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-158" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1211" data-name="グループ 1211"
                   transform="translate(67.442 243.806)">
                  <path id="パス_95-159" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1684" data-name="パス 1684"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-159" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1685" data-name="パス 1685"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-159" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1686" data-name="パス 1686"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-159" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1131" data-name="グループ 1131"
                   transform="translate(68.2 42.812)">
                  <path id="パス_95-160" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1687" data-name="パス 1687"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-160" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1688" data-name="パス 1688"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-160" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1689" data-name="パス 1689"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-160" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1233" data-name="グループ 1233"
                   transform="translate(67.55 215.093)">
                  <path id="パス_95-161" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1690" data-name="パス 1690"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-161" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1691" data-name="パス 1691"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-161" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1692" data-name="パス 1692"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-161" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1147" data-name="グループ 1147"
                   transform="translate(68.308 14.099)">
                  <path id="パス_95-162" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1693" data-name="パス 1693"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-162" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1694" data-name="パス 1694"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-162" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1695" data-name="パス 1695"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-162" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1250" data-name="グループ 1250"
                   transform="translate(67.658 186.379)">
                  <path id="パス_95-163" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1696" data-name="パス 1696"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-163" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1697" data-name="パス 1697"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-163" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1698" data-name="パス 1698"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-163" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1055" data-name="グループ 1055"
                   transform="translate(296.538 156.803)">
                  <path id="パス_95-164" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1699" data-name="パス 1699"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-164" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1700" data-name="パス 1700"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-164" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1701" data-name="パス 1701"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-164" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1161" data-name="グループ 1161"
                   transform="translate(295.889 329.083)">
                  <path id="パス_95-165" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1702" data-name="パス 1702"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-165" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1703" data-name="パス 1703"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-165" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1704" data-name="パス 1704"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-165" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1081" data-name="グループ 1081"
                   transform="translate(296.647 128.09)">
                  <path id="パス_95-166" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1705" data-name="パス 1705"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-166" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1706" data-name="パス 1706"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-166" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1707" data-name="パス 1707"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-166" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1162" data-name="グループ 1162"
                   transform="translate(295.997 300.37)">
                  <path id="パス_95-167" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1708" data-name="パス 1708"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-167" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1709" data-name="パス 1709"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-167" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1710" data-name="パス 1710"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-167" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1098" data-name="グループ 1098"
                   transform="translate(296.755 99.376)">
                  <path id="パス_95-168" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1711" data-name="パス 1711"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-168" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1712" data-name="パス 1712"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-168" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1713" data-name="パス 1713"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-168" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1181" data-name="グループ 1181"
                   transform="translate(296.105 271.657)">
                  <path id="パス_95-169" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1714" data-name="パス 1714"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-169" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1715" data-name="パス 1715"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-169" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1716" data-name="パス 1716"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-169" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1115" data-name="グループ 1115"
                   transform="translate(296.863 70.663)">
                  <path id="パス_95-170" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1717" data-name="パス 1717"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-170" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1718" data-name="パス 1718"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-170" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1719" data-name="パス 1719"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-170" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1201" data-name="グループ 1201"
                   transform="translate(296.213 242.943)">
                  <path id="パス_95-171" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1720" data-name="パス 1720"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-171" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1721" data-name="パス 1721"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-171" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1722" data-name="パス 1722"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-171" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1132" data-name="グループ 1132"
                   transform="translate(296.972 41.949)">
                  <path id="パス_95-172" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1723" data-name="パス 1723"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-172" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1724" data-name="パス 1724"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-172" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1725" data-name="パス 1725"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-172" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1234" data-name="グループ 1234"
                   transform="translate(296.322 214.23)">
                  <path id="パス_95-173" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1726" data-name="パス 1726"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-173" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1727" data-name="パス 1727"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-173" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1728" data-name="パス 1728"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-173" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1148" data-name="グループ 1148"
                   transform="translate(297.08 13.236)">
                  <path id="パス_95-174" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1729" data-name="パス 1729"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-174" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1730" data-name="パス 1730"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-174" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1731" data-name="パス 1731"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-174" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1251" data-name="グループ 1251"
                   transform="translate(296.43 185.516)">
                  <path id="パス_95-175" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1732" data-name="パス 1732"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-175" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1733" data-name="パス 1733"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-175" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1734" data-name="パス 1734"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-175" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1042" data-name="グループ 1042"
                   transform="translate(136.613 171.763)">
                  <path id="パス_95-176" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1735" data-name="パス 1735"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-176" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1736" data-name="パス 1736"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-176" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1737" data-name="パス 1737"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-176" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1182" data-name="グループ 1182"
                   transform="translate(135.963 344.043)">
                  <path id="パス_95-177" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1738" data-name="パス 1738"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-177" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1739" data-name="パス 1739"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-177" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1740" data-name="パス 1740"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-177" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1064" data-name="グループ 1064"
                   transform="translate(136.722 143.049)">
                  <path id="パス_95-178" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1741" data-name="パス 1741"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-178" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1742" data-name="パス 1742"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-178" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1743" data-name="パス 1743"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-178" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1183" data-name="グループ 1183"
                   transform="translate(136.072 315.33)">
                  <path id="パス_95-179" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1744" data-name="パス 1744"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-179" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1745" data-name="パス 1745"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-179" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1746" data-name="パス 1746"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-179" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1070" data-name="グループ 1070"
                   transform="translate(136.83 114.336)">
                  <path id="パス_95-180" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1747" data-name="パス 1747"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-180" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1748" data-name="パス 1748"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-180" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1749" data-name="パス 1749"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-180" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1184" data-name="グループ 1184"
                   transform="translate(136.18 286.616)">
                  <path id="パス_95-181" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1750" data-name="パス 1750"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-181" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1751" data-name="パス 1751"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-181" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1752" data-name="パス 1752"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-181" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1087" data-name="グループ 1087"
                   transform="translate(136.938 85.622)">
                  <path id="パス_95-182" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1753" data-name="パス 1753"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-182" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1754" data-name="パス 1754"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-182" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1755" data-name="パス 1755"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-182" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1185" data-name="グループ 1185"
                   transform="translate(136.288 257.903)">
                  <path id="パス_95-183" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1756" data-name="パス 1756"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-183" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1757" data-name="パス 1757"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-183" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1758" data-name="パス 1758"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-183" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1107" data-name="グループ 1107"
                   transform="translate(137.047 56.909)">
                  <path id="パス_95-184" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1759" data-name="パス 1759"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-184" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1760" data-name="パス 1760"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-184" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1761" data-name="パス 1761"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-184" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1212" data-name="グループ 1212"
                   transform="translate(136.397 229.19)">
                  <path id="パス_95-185" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1762" data-name="パス 1762"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-185" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1763" data-name="パス 1763"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-185" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1764" data-name="パス 1764"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-185" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1124" data-name="グループ 1124"
                   transform="translate(137.155 28.196)">
                  <path id="パス_95-186" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1765" data-name="パス 1765"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-186" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1766" data-name="パス 1766"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-186" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1767" data-name="パス 1767"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-186" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1243" data-name="グループ 1243"
                   transform="translate(136.505 200.476)">
                  <path id="パス_95-187" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1768" data-name="パス 1768"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-187" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1769" data-name="パス 1769"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-187" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1770" data-name="パス 1770"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-187" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1138" data-name="グループ 1138"
                   transform="translate(137.263 -0.518)">
                  <path id="パス_95-188" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1771" data-name="パス 1771"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-188" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1772" data-name="パス 1772"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-188" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1773" data-name="パス 1773"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-188" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1049" data-name="グループ 1049"
                   transform="translate(159.275 157.321)">
                  <path id="パス_95-189" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1774" data-name="パス 1774"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-189" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1775" data-name="パス 1775"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-189" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1776" data-name="パス 1776"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-189" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1186" data-name="グループ 1186"
                   transform="translate(158.625 329.601)">
                  <path id="パス_95-190" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1777" data-name="パス 1777"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-190" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1778" data-name="パス 1778"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-190" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1779" data-name="パス 1779"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-190" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1082" data-name="グループ 1082"
                   transform="translate(159.384 128.607)">
                  <path id="パス_95-191" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1780" data-name="パス 1780"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-191" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1781" data-name="パス 1781"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-191" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1782" data-name="パス 1782"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-191" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1187" data-name="グループ 1187"
                   transform="translate(158.734 300.888)">
                  <path id="パス_95-192" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1783" data-name="パス 1783"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-192" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1784" data-name="パス 1784"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-192" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1785" data-name="パス 1785"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-192" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1099" data-name="グループ 1099"
                   transform="translate(159.492 99.894)">
                  <path id="パス_95-193" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1786" data-name="パス 1786"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-193" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1787" data-name="パス 1787"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-193" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1788" data-name="パス 1788"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-193" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1188" data-name="グループ 1188"
                   transform="translate(158.842 272.174)">
                  <path id="パス_95-194" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1789" data-name="パス 1789"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-194" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1790" data-name="パス 1790"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-194" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1791" data-name="パス 1791"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-194" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1116" data-name="グループ 1116"
                   transform="translate(159.6 71.181)">
                  <path id="パス_95-195" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1792" data-name="パス 1792"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-195" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1793" data-name="パス 1793"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-195" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1794" data-name="パス 1794"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-195" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1202" data-name="グループ 1202"
                   transform="translate(158.95 243.461)">
                  <path id="パス_95-196" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1795" data-name="パス 1795"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-196" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1796" data-name="パス 1796"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-196" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1797" data-name="パス 1797"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-196" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1133" data-name="グループ 1133"
                   transform="translate(159.708 42.467)">
                  <path id="パス_95-197" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1798" data-name="パス 1798"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-197" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1799" data-name="パス 1799"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-197" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1800" data-name="パス 1800"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-197" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1235" data-name="グループ 1235"
                   transform="translate(159.059 214.748)">
                  <path id="パス_95-198" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1801" data-name="パス 1801"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-198" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1802" data-name="パス 1802"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-198" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1803" data-name="パス 1803"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-198" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1149" data-name="グループ 1149"
                   transform="translate(159.817 13.754)">
                  <path id="パス_95-199" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1804" data-name="パス 1804"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-199" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1805" data-name="パス 1805"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-199" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1806" data-name="パス 1806"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-199" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1252" data-name="グループ 1252"
                   transform="translate(159.167 186.034)">
                  <path id="パス_95-200" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1807" data-name="パス 1807"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-200" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1808" data-name="パス 1808"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-200" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1809" data-name="パス 1809"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-200" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1044" data-name="グループ 1044"
                   transform="translate(228.122 171.418)">
                  <path id="パス_95-201" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1810" data-name="パス 1810"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-201" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1811" data-name="パス 1811"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-201" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1812" data-name="パス 1812"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-201" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1168" data-name="グループ 1168"
                   transform="translate(227.472 343.698)">
                  <path id="パス_95-202" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1813" data-name="パス 1813"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-202" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1814" data-name="パス 1814"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-202" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1815" data-name="パス 1815"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-202" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1065" data-name="グループ 1065"
                   transform="translate(228.23 142.704)">
                  <path id="パス_95-203" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1816" data-name="パス 1816"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-203" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1817" data-name="パス 1817"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-203" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1818" data-name="パス 1818"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-203" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1169" data-name="グループ 1169"
                   transform="translate(227.58 314.985)">
                  <path id="パス_95-204" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1819" data-name="パス 1819"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-204" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1820" data-name="パス 1820"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-204" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1821" data-name="パス 1821"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-204" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1071" data-name="グループ 1071"
                   transform="translate(228.339 113.991)">
                  <path id="パス_95-205" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1822" data-name="パス 1822"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-205" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1823" data-name="パス 1823"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-205" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1824" data-name="パス 1824"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-205" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1170" data-name="グループ 1170"
                   transform="translate(227.689 286.271)">
                  <path id="パス_95-206" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1825" data-name="パス 1825"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-206" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1826" data-name="パス 1826"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-206" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1827" data-name="パス 1827"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-206" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1088" data-name="グループ 1088"
                   transform="translate(228.447 85.277)">
                  <path id="パス_95-207" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1828" data-name="パス 1828"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-207" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1829" data-name="パス 1829"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-207" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1830" data-name="パス 1830"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-207" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1189" data-name="グループ 1189"
                   transform="translate(227.797 257.558)">
                  <path id="パス_95-208" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1831" data-name="パス 1831"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-208" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1832" data-name="パス 1832"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-208" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1833" data-name="パス 1833"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-208" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1108" data-name="グループ 1108"
                   transform="translate(228.555 56.564)">
                  <path id="パス_95-209" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1834" data-name="パス 1834"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-209" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1835" data-name="パス 1835"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-209" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1836" data-name="パス 1836"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-209" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1213" data-name="グループ 1213"
                   transform="translate(227.905 228.844)">
                  <path id="パス_95-210" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1837" data-name="パス 1837"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-210" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1838" data-name="パス 1838"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-210" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1839" data-name="パス 1839"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-210" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1125" data-name="グループ 1125"
                   transform="translate(228.663 27.851)">
                  <path id="パス_95-211" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1840" data-name="パス 1840"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-211" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1841" data-name="パス 1841"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-211" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1842" data-name="パス 1842"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-211" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1244" data-name="グループ 1244"
                   transform="translate(228.014 200.131)">
                  <path id="パス_95-212" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1843" data-name="パス 1843"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-212" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1844" data-name="パス 1844"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-212" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1845" data-name="パス 1845"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-212" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
                <g id="グループ_1139" data-name="グループ 1139"
                   transform="translate(228.772 -0.863)">
                  <path id="パス_95-213" data-name="パス 95"
                        d="M3.868,6.949a.122.122,0,0,0,.227-.005L6.41-.024l1.5-.006L5.175,7.582a1.208,1.208,0,0,1-1.282.826A1.209,1.209,0,0,1,2.64,7.486L0,.006,1.529,0Z"
                        transform="translate(17.226 0.148)"/>
                  <path id="パス_1846" data-name="パス 1846"
                        d="M0,0,1.373-.005,1.341,8.357l-1.373.005Z"
                        transform="translate(-0.001 0.217)"/>
                  <path id="パス_96-213" data-name="パス 96"
                        d="M7.008,2.516a.687.687,0,0,0-.727-.6.7.7,0,0,0-.654.677h0L5.616,5.045a2.042,2.042,0,0,1-2.09,2.091,2.023,2.023,0,0,1-1.775-.842A2.112,2.112,0,0,1,1.379,5L1.4-.005,0,0-.018,5.006a4.157,4.157,0,0,0,.028.524A3.142,3.142,0,0,0,1.563,7.964a3.709,3.709,0,0,0,2.294.478,3.426,3.426,0,0,0,2.172-.965,3.3,3.3,0,0,0,.985-2.431Z"
                        transform="translate(8.746 0.185)"/>
                  <path id="パス_1847" data-name="パス 1847"
                        d="M.7,0a.693.693,0,0,1,.7.7.706.706,0,0,1-.7.7A.693.693,0,0,1,0,.7.706.706,0,0,1,.7,0Z"
                        transform="translate(14.369 0.172)"/>
                  <path id="パス_97-213" data-name="パス 97"
                        d="M7.39,1.247A4.292,4.292,0,0,0,4.317-.016,4.355,4.355,0,0,0,2.394.436L2.984,1.7a2.937,2.937,0,0,1,4.27,2.622A2.98,2.98,0,0,1,4.29,7.289,2.926,2.926,0,0,1,1.376,4.768h0a.693.693,0,0,0-.692-.61.707.707,0,0,0-.7.7.685.685,0,0,0,.012.127h0a4.314,4.314,0,0,0,4.292,3.7A4.392,4.392,0,0,0,8.653,4.32,4.292,4.292,0,0,0,7.39,1.247Z"
                        transform="translate(25.781 -0.097)"/>
                  <path id="パス_1848" data-name="パス 1848"
                        d="M.705,0a.7.7,0,0,1,.7.7A.712.712,0,0,1,.7,1.408a.7.7,0,0,1-.7-.7A.712.712,0,0,1,.705,0Z"
                        transform="translate(25.901 2.109)"/>
                  <path id="パス_98-213" data-name="パス 98"
                        d="M1.6,4.278A.3.3,0,0,1,1.6,3.8L4.972-.019,3.252-.012.357,3.3a1.141,1.141,0,0,0,.012,1.7l2.9,3.367,1.8-.009Z"
                        transform="translate(2.044 0.208)"/>
                </g>
              </g>
            </g>
            <g id="グループ_1472" data-name="グループ 1472"
               transform="translate(14 83.298)">
              <text id="Music_Creators" data-name="Music Creators"
                    transform="translate(123 2298.52)" fill="#fff"
                    fontSize="14" fontFamily="Roboto-Bold, Roboto"
                    fontWeight="700"
              >
                <tspan x="1.237" y="15">Music Creators</tspan>
              </text>
              <g id="グループ_1471" data-name="グループ 1471"
                 transform="translate(-562.681 -947.68)"
              >
                <circle id="楕円形_48-4" data-name="楕円形 48" cx="7" cy="7"
                        r="7" transform="translate(787.681 3248.681)"
                        fill="#fff"/>
                <g id="グループ_1022-4" data-name="グループ 1022"
                   transform="translate(790.969 3251.969)">
                  <rect id="長方形_863-4" data-name="長方形 863" width="8"
                        height="2" transform="translate(-0.288 2.712)"/>
                  <rect id="長方形_864-4" data-name="長方形 864" width="2"
                        height="8" transform="translate(2.712 -0.288)"/>
                </g>
              </g>
            </g>

            <rect id="venue-cover" transform="translate(50 2260)" width="90"
                  height="37" fill="rgba(255,28,28,0.5)" opacity="0"
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
                  onClick={() => {openDialogForVenues();}}
            />

            <rect id="venue-cover" transform="translate(270 2260)" width="75"
                  height="37" fill="rgba(255,28,28,0.5)" opacity="0"
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
                  onClick={() => {openDialogForDJs();}}
            />

            <rect id="venue-cover" transform="translate(125 2375)" width="140"
                  height="37" fill="rgba(255,28,28,0.5)" opacity="0"
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
                  onClick={() => {openDialogForMusicCreators();}}
            />

            <rect id="venue-cover" transform="translate(150 2515)" width="90"
                  height="37" fill="rgba(255,28,28,0.5)" opacity="0"
                  style={{
                    pointerEvents: 'auto', cursor: 'pointer',
                  }}
                  onClick={() => {openDialogForCMOs();}}
            />

          </g>
        </svg>
        <SPKUVOLogo/>
        <p>KUVO supports music creators by empowering the industry with reliable
          data.</p>
      </SPWrap>
  );
}