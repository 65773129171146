import {useEffect, useState} from 'react';
import {useApp} from '../../../contexts/AppContext';
import {sendInquiry} from '../../../api';
import styled from 'styled-components';
import config from '../../../config';
import {
  PageMainTitle,
  SubInner,
  SectionTitle,
  InputLabel,
  FormTextArea,
  RequiredLabel,
  FormInput,
} from '../../atoms/CommonParts';
import DBDPullDown from '../../atoms/DBDPullDown';
import {
  useCustomDialog,
  ButtonLayout,
} from '../../../contexts/CustomDialogContext';
import {Link, useLocation} from 'react-router-dom';

const Page = styled.div`
  padding-top: 47px;
  @media ${config.layout.wide} {
    padding-top: 69px;
  }
`;

const PageFeatureTitle = styled.div`
  position: relative;

  h2 {
    font-size: 50px;
    font-weight: bold;

    span {
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      margin-top: 11px;
    }

    @media ${config.layout.wide} {
      font-size: 70px;
      span {
        display: inline-block;
        margin-top: 0;
        margin-left: 37px;
      }
    }
  }
`;

const Inner = styled.div`
  margin-top: 47px;
  @media ${config.layout.wide} {
    margin-top: 84px;
    padding: 0 64px;
  }
`;

const TopLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  a {
    color: unset;
    text-decoration: underline;
  }
  div {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 50px;
      @media ${config.layout.wide} {
        margin-bottom: 100px;
      }
    }
    
  }
`;

const ThanksTitle = styled.h3`
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  margin-top: 91px;
  margin-bottom: 40px;
  @media ${config.layout.wide} {
    margin-top: 85px;
  }
`;

const ThanksDescription = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  padding-bottom: 50px;
  @media ${config.layout.wide} {
    margin-bottom: 100px;
  }
`;

const SubmitButtonWrap = styled.div`
  @media ${config.layout.wide} {
    width: 350px;
    margin: 0 auto;
  }
`;

const KeepMeLogin = styled.div`
  text-align: center;
  margin: 50px auto;
  max-width: calc(100% - 30px);
  @media ${config.layout.wide} {
    max-width: 100%;
  }

  input {
    display: none;

    &:checked {
      & + label {
        &:before {
        }
      }
    }
  }

  label {
    position: relative;
    font-size: 12px;
    @media ${config.layout.wide} {
      font-size: 14px;
    }
    font-weight: 500;
    margin-left: 7px;
    padding-left: 28px;
    text-align: left;

    &:before {
      position: absolute;
      left: 0;
      top: 0px;
      @media ${config.layout.wide} {
        top: 2px;
      }
    }

    a {
      color: #000;
    }
  }
`;

function ContactSent() {
  return (
      <Page>
        <PageFeatureTitle>
          <h2>CONTACT</h2>
        </PageFeatureTitle>

        <ThanksTitle>Thank you!</ThanksTitle>
        <ThanksDescription>
          Your request was submitted successfully. We'll respond as soon as
          possible to the e-mail address of your AlphaTheta Account.
        </ThanksDescription>
        <SubmitButtonWrap>
          <Link
              className="dark-button back-button center-fixed"
              to={`/`}
              style={{
                'width': '100%',
              }}
          >
            <span>Top</span>
          </Link>
        </SubmitButtonWrap>
      </Page>
  );
}

function ContactUnauth({setSent}) {
  const {state} = useLocation();
  const [category, setCategory] = useState(
      (state && state.category) || 'default',
  );
  const {
    customDialog,
    errorDialogWithAPIError,
    alertDialog,
  } = useCustomDialog();
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    try {
      let st = window.history.state;
      if (st && st.usr && st.usr.category) {
        delete st.usr.category;
        window.history.replaceState(st, document.title);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const categories = [
    'Press',
    'KUVO account (created before Sep 2022)',
    'New venue account',
    'CMO account',
    'Others',
  ].map((v) => {
    return {
      value: v,
      label: v,
    };
  });

  function handleSubmit(event) {
    event.preventDefault();

    if (!agreed) {
      return;
    }

    let formData = new FormData(event.currentTarget);
    let name = (formData.get('name') || '').trim().substr(0, 256);
    let email = (formData.get('email') || '').trim().substr(0, 256);
    let description = (formData.get('description') || '').trim();
    let errors = [];
    if (!name) {
      errors.push('- Name is required.');
    }
    if (!email) {
      errors.push('- Email is required.');
    }
    if (category == 'default') {
      errors.push('- Choose a subject.');
    }
    if (!description) {
      errors.push('- Comment is required.');
    }

    if (errors.length) {
      (async function() {
        await alertDialog({
          //buttons: ButtonLayout.None,
          description_element: (
              <div className={'errors'}>
                <ul>
                  {errors.map((e, i) => {
                    return <li key={i}>{e}</li>;
                  })}
                </ul>
              </div>
          ),
        });
      })();
      return;
    }

    (async () => {
      try {
        await sendInquiry({
          name,
          email,
          category,
          description,
        });
        setSent(true);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      } catch (e) {
        console.error(e);
        await errorDialogWithAPIError(e);
      }
    })();
  }

  const onCategoryPullDownChange = (newValue) => {
    console.log(newValue);
    setCategory(newValue);
  };

  return (
      <Page>
        <PageFeatureTitle>
          <h2>CONTACT<span>Can’t find what you’re looking for?</span></h2>
        </PageFeatureTitle>

        <Inner>
          <TopLabel>
            <div>
              For questions about your AlphaTheta Account, submit your
              inquiry{' '}<a
                href="https://forums.pioneerdj.com/hc/requests/new?ticket_form_id=175743"
                target="_blank"
                rel="noopener"
            >
              here
            </a>.
            </div>
            <div>
              For inquiries about rekordbox or licenses, please contact{' '}
              <a
                  href="https://rekordbox.com/support/contact/"
                  target="_blank"
                  rel="noopener"
              >
                rekordbox support
              </a>.
            </div>

          </TopLabel>
          <form onSubmit={handleSubmit}>
            <RequiredLabel/>
            <InputLabel required={true}>Name</InputLabel>
            <FormInput type="text" required name="name" maxLength={64}
                       onInvalid={(e) => {
                         if (window.scrollY > 140) {
                           window.scrollTo(0, 140);
                         }
                       }}/>
            <InputLabel required={true}>E-mail address</InputLabel>
            <FormInput type="email" required name="email" maxLength={128}
                       onInvalid={(e) => {
                         if (window.scrollY > 140) {
                           window.scrollTo(0, 140);
                         }
                       }}/>
            <InputLabel required={true}>Subject</InputLabel>
            <DBDPullDown
                defaultLabel={'Select one'}
                value={category}
                onChange={onCategoryPullDownChange}
                items={categories}
                style={{
                  minWidth: '100%',
                }}
            ></DBDPullDown>
            <InputLabel required={true}>Questions/comments</InputLabel>
            <FormTextArea required={true} name="description" maxLength={1000}/>

            <KeepMeLogin>
              <input
                  id="keep_me_logged_in"
                  name="keep"
                  type="checkbox"
                  defaultChecked={agreed}
                  onChange={(e) => {
                    setAgreed(e.target.checked);
                  }}
              />
              <label htmlFor="keep_me_logged_in">
                I agree to the{' '}
                <a
                    href="https://alphatheta.com/company/privacy-policy/"
                    target="_blank"
                    rel="noopener"
                >
                  Privacy Policy
                </a>, including the transfer of my personal information to Japan
                and the U.S.
                for hosting purpose and to provide you with the service. I
                understand that these countries may not have
                the same data protection laws as the country from which I
                provide my personal information. I have the
                right to withdraw my consent at any time.
              </label>
            </KeepMeLogin>

            <SubmitButtonWrap>
              <button
                  type="submit"
                  className="dark-button center-fixed"
                  style={{
                    width: '100%',
                  }}
                  disabled={!agreed}
              >
                <span>Submit</span>
              </button>
            </SubmitButtonWrap>
          </form>
        </Inner>
      </Page>
  );
}

export default function ContactPage() {
  const app = useApp();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    app.setTitle('CONTACT');
  }, []);

  if (sent) {
    return <ContactSent/>;
  } else {
    return <ContactUnauth setSent={setSent}/>;
  }
}
