import styled from 'styled-components';
import {useRef, useState} from 'react';
import config from '../../../config';
import {
  useCustomDialog,
  ButtonLayout,
} from '../../../contexts/CustomDialogContext';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './howworks.scss';
import {Navigation} from 'swiper';

const PCWrap = styled.div`
  display: none;
  height: 700px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 50px;

  @media ${config.layout.topwide} {
    display: block;
  }

  svg {
    pointer-events: none;
    display: block;
    margin: 0 auto;
    width: 1160px;
    height: auto;
  }
`;

const SPWrap = styled.div`
  //height: 600px;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  overflow: hidden;
  @media ${config.layout.topwide} {
    display: none;
  }

  svg {
    pointer-events: none;
    display: block;
    margin: 10vw auto 0;
    width: calc(300vw - 40px);
    height: auto;
  }
`;

const SPBG = styled.div`
  z-index: 1;
  width: 100vw;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 181.53%;
  }
`;

const SPFG = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SPContent = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: -100vw;
  right: -100vw;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    z-index: 2;
  }
`;

const SPControlArea = styled.div`
  position: absolute;
  z-index: 5;
  top: -2px;
  left: 0;
  width: 100vw;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 10px;
`;

const SPControlSideArea = styled.div`
  flex: 1 1 auto;
  height: 30px;

  background-repeat: no-repeat;
  background-position: center;

  &.swiper-button-disabled {
    opacity: 0.3;
  }
`;

const SPControlCenterArea = styled.div`
  background-color: #F8F8F8;
  color: #000;
  height: 30px;
  line-height: 30px;
  border-radius: 0 0 4px 4px;
  padding: 0 10px;
  font-weight: 16px;
  font-weight: bold;
  flex: 0 0 auto;
`;

export function HowWorksImage() {
  return (<>
    <HowWorksImagePC/>
    <HowWorksImageSP/>
  </>);
}

function HowWorksImagePC({
  openDialog,
}) {
  return (
      <PCWrap>
        <HowWorksContents/>
      </PCWrap>
  );
}

function HowWorksImageSP({
  openDialog,
}) {
  return (
      <SPWrap>
        <SPBG/>
        <SPFG>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper"
                  initialSlide={1}
                  navigation={{
                    // パラメータを設定
                    prevEl: '#button_prev',
                    nextEl: '#button_next',
                  }}
          >
            <SwiperSlide></SwiperSlide>
            <SwiperSlide>
              <SPContent>
                <HowWorksContents/>
              </SPContent>
            </SwiperSlide>
            <SwiperSlide></SwiperSlide>
          </Swiper>
          <SPControlArea>
            <SPControlSideArea id={'button_prev'}>

            </SPControlSideArea>
            <SPControlCenterArea>
              Swipe left or right
            </SPControlCenterArea>
            <SPControlSideArea id={'button_next'}>

            </SPControlSideArea>
          </SPControlArea>
        </SPFG>
      </SPWrap>
  );
}

function HowWorksContents({
  ...props
}) {
  const [hover, setHover] = useState(false);
  const {customDialog} = useCustomDialog();

  const openDialog = async (description_element) => {
    await customDialog({
      buttons: ButtonLayout.None,
      description_element,
      for_top: true,
    });
  };

  const openPlayDataDialog = async () => {
    openDialog((
            <>
              <h3><span>Music Play Data</span></h3>
              <p>
                KUVO automates music play reporting for royalty purposes through
                technology that works discreetly in the background. It captures only
                the track data, which is never shared publicly and includes no
                details of which DJ played which tracks.
              </p>
            </>
        ),
    );
  };

  return (<svg
      id="\u30EC\u30A4\u30E4\u30FC_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 1160 600"
      style={{
        enableBackground: 'new 0 0 1160 600',
      }}
      xmlSpace="preserve"
      {...props}
  >
    <style>
      {
        '.st0{opacity:.7}.st1{fill:#fff}.st2{fill:none;stroke:#fff;stroke-miterlimit:10}.st3{opacity:.5}.st6{fill:#898989}.st10{opacity:.3}'
      }
    </style>
    <g className="st0">
      <path className="st1" d="m909.65 267.86 5.17-5.18-5.17-5.17"/>
      <path className="st2" d="M634.31 262.68h276.87M239.6 261.67h287.31"/>
    </g>
    <circle cx={580.45} cy={118.47} r={114.14} className="st3 framed-object"/>
    <path className="st1" d="m947.14 236.83 5.17 4.51 5.17-4.51"/>
    <path
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 0.7999,
          strokeMiterlimit: 10,
        }}
        d="M924.26 200.4h28.05v36.43"
    />
    <path
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 1.327,
          strokeMiterlimit: 10,
        }}
        d="M660.67 200.4h126.14"
    />
    <path
        className="st6"
        d="M472.45 157.34H347.14v-7.38h122.87zM891.9 157.34H688.66l4.15-7.38H891.9z"
    />
    <path
        transform="rotate(90 986.472 209.479)"
        className="st6"
        d="M949.61 205.79h72.2v7.38h-72.2z"
    />
    <path
        d="M333.86 595.13H214.09c-3.82 0-6.94-3.12-6.94-6.94V471.71c0-3.82 3.12-6.94 6.94-6.94h119.77c3.82 0 6.94 3.12 6.94 6.94V588.2c0 3.81-3.12 6.93-6.94 6.93zM132.1 594.67H12.33c-3.82 0-6.94-3.12-6.94-6.94V471.25c0-3.82 3.12-6.94 6.94-6.94H132.1c3.82 0 6.94 3.12 6.94 6.94v116.49c0 3.81-3.12 6.93-6.94 6.93z"
        className="st3 framed-object"
    />

    <path
        d="M232.29 377.17H111.23c-3.46 0-6.3-2.83-6.3-6.3V252.63c0-3.46 2.83-6.3 6.3-6.3h121.06c3.46 0 6.3 2.83 6.3 6.3v118.24c0 3.46-2.84 6.3-6.3 6.3z"
        className="st3 framed-object"
    />
    <path
        className="st6"
        d="m354.25 163.49-11.31-9.84 11.31-9.85zM916.72 363.72l-11.3-9.85v6.16H251.96v-6.16l-11.3 9.85 11.3 9.84v-6.15h653.46v6.15z"
    />
    <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={398.071}
        y1={863.663}
        x2={417.071}
        y2={863.663}
        gradientTransform="matrix(1 0 0 -1 70 1227.378)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_1_)',
        }}
        d="M468.07 360.03h19v7.38h-19z"
    />
    <linearGradient
        id="SVGID_00000093894846149300532660000007635668431659606424_"
        gradientUnits="userSpaceOnUse"
        x1={1291.929}
        y1={863.663}
        x2={1310.929}
        y2={863.663}
        gradientTransform="rotate(180 992.5 613.69)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000093894846149300532660000007635668431659606424_)',
        }}
        d="M674.07 360.03h19v7.38h-19z"
    />
    <linearGradient
        id="SVGID_00000033346570182532933480000006907050251571935386_"
        gradientUnits="userSpaceOnUse"
        x1={1276.929}
        y1={1073.729}
        x2={1296.929}
        y2={1073.729}
        gradientTransform="rotate(180 992.5 613.69)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000033346570182532933480000006907050251571935386_)',
        }}
        d="M688.07 157.34h19l1-7.38h-17z"
    />
    <path
        transform="rotate(-45.001 99.452 419.98)"
        className="st6"
        d="M80 416.29h38v7.38H80z"
    />
    <path
        className="st6"
        d="m105.19 400.31 14.96-1.03-1.03 14.96zM93.55 439.8l-14.95 1.03 1.03-14.95z"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000150793426936535443560000010126393541201098899_"
            className="st10"
            d="m114.76 409.88 4.35 4.35 1.03-14.95-14.95 1.03 4.35 4.35-25.57 25.57-4.35-4.35-1.03 14.95 14.96-1.03-6.97-6.96 2.61 2.61z"
        />
      </defs>
      <clipPath id="SVGID_00000157283076345545530520000002971894013730305921_">
        <use
            xlinkHref="#SVGID_00000150793426936535443560000010126393541201098899_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g // 左下
          id="g_x5F_d2v"
          style={{
            clipPath:
                'url(#SVGID_00000157283076345545530520000002971894013730305921_)',
          }}
      >
        <path
            id={'left_bottom_left_p'}
            d="m73.21 468.35-5.89 5.89-7.94-36.23 5.89-5.89zM86.02 455.54l-5.89 5.89-7.94-36.23 5.89-5.88zM98.83 442.73l-5.89 5.89L85 412.4l5.89-5.89zM111.63 429.93l-5.88 5.88-7.94-36.22 5.89-5.89zM124.44 417.12l-5.89 5.88-7.93-36.22 5.88-5.89z"/>
      </g>
    </g>
    <path
        transform="rotate(-45.001 250.433 419.981)"
        className="st6"
        d="M246.75 401h7.38v38h-7.38z"
    />
    <path
        className="st6"
        d="m244.7 400.31-14.96-1.03 1.03 14.96zM256.34 439.8l14.95 1.03-1.03-14.95z"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000049934138430581936910000015115793161032221612_"
            className="st10"
            d="m235.13 409.89-4.36 4.35-1.03-14.95 14.96 1.03-4.35 4.35 25.56 25.57 4.35-4.36 1.03 14.96-14.95-1.03 6.96-6.97-2.61 2.61z"
        />
      </defs>
      <clipPath id="SVGID_00000093856210358472990420000003093846167008004495_">
        <use
            xlinkHref="#SVGID_00000049934138430581936910000015115793161032221612_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g // 左下右
          id="g_x5F_p2v"
          style={{
            clipPath:
                'url(#SVGID_00000093856210358472990420000003093846167008004495_)',
          }}
      >
        <path
            id={'left_bottom_right_p'}
            d="m263.98 455.55 5.89 5.88 7.94-36.22-5.89-5.89zM276.79 468.36l5.88 5.88 7.94-36.22-5.88-5.89zM251.17 442.74l5.89 5.89L265 412.4l-5.89-5.88zM238.36 429.93l5.89 5.89 7.94-36.23-5.89-5.88zM225.56 417.12l5.88 5.89 7.94-36.22-5.88-5.89z"/>
      </g>
    </g>
    <path
        transform="rotate(-45.001 910.98 419.99)"
        className="st6"
        d="M892 416.29h38v7.38h-38z"
    />
    <path
        className="st6"
        d="m916.72 400.31 14.95-1.03-1.03 14.96zM905.08 439.8l-14.96 1.03 1.03-14.95z"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000108302734113764668980000007595471734340401316_"
            className="st10"
            d="m926.29 409.88 4.35 4.36 1.03-14.96-14.95 1.03 4.35 4.36-25.57 25.56-4.35-4.35-1.03 14.95 14.95-1.03-6.96-6.96 2.61 2.61z"
        />
      </defs>
      <clipPath id="SVGID_00000134207683736426699350000003307521464270378402_">
        <use
            xlinkHref="#SVGID_00000108302734113764668980000007595471734340401316_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g
          // 右下左
          id="g_x5F_m2c"
          style={{
            clipPath:
                'url(#SVGID_00000134207683736426699350000003307521464270378402_)',
          }}
      >
        <path
            id={'g_x5F_m2c_p'}
            d="m884.74 468.35-5.89 5.89-7.94-36.23 5.89-5.88zM897.55 455.54l-5.89 5.89-7.94-36.22 5.89-5.89zM910.35 442.74l-5.88 5.88-7.94-36.22 5.89-5.89zM923.16 429.93l-5.88 5.89-7.94-36.23 5.88-5.89zM935.97 417.12l-5.89 5.89-7.94-36.23 5.89-5.88z"/>
      </g>
    </g>
    <path
        transform="rotate(-45.001 1061.962 419.99)"
        className="st6"
        d="M1058.27 401h7.38v38h-7.38z"
    />
    <path
        className="st6"
        d="m1056.22 400.31-14.95-1.03 1.03 14.96zM1067.86 439.8l14.96 1.03-1.03-14.95z"
    />
    <g id="g_x5F_l2c" className="st10">
      <defs>
        <path
            id="SVGID_00000047034368571356220100000001883795733126484917_"
            className="st10"
            d="m1046.65 409.88-4.35 4.36-1.03-14.96 14.95 1.03-4.35 4.36 25.57 25.56 4.35-4.35 1.03 14.95-14.96-1.03 6.97-6.96-2.61 2.61z"
        />
      </defs>
      <clipPath id="SVGID_00000116227924581001227000000000262092927631373751_">
        <use
            xlinkHref="#SVGID_00000047034368571356220100000001883795733126484917_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g
          style={{
            clipPath:
                'url(#SVGID_00000116227924581001227000000000262092927631373751_)',
          }}
      >
        <path
            id={'right_bottom_p'}
            d="m1075.51 456.54 5.88 5.89 7.94-36.22-5.89-5.89zM1088.31 469.35l5.89 5.89 7.94-36.22-5.89-5.89zM1062.7 443.74l5.88 5.88 7.94-36.22-5.88-5.89zM1049.89 430.93l5.89 5.89 7.93-36.23-5.88-5.88zM1037.08 418.12l5.89 5.89 7.94-36.23-5.89-5.88z"/>
      </g>
    </g>
    <path
        d="M1148.57 595.13H1028.8c-3.82 0-6.94-3.12-6.94-6.94V471.71c0-3.82 3.12-6.94 6.94-6.94h119.77c3.82 0 6.94 3.12 6.94 6.94V588.2c0 3.81-3.12 6.93-6.94 6.93zM946.81 594.67H827.04c-3.82 0-6.94-3.12-6.94-6.94V471.25c0-3.82 3.12-6.94 6.94-6.94h119.77c3.82 0 6.94 3.12 6.94 6.94v116.49c0 3.81-3.12 6.93-6.94 6.93z"
        className="st3 framed-object"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000000936996501858395610000009521182367554319540_"
            transform="rotate(90 986.47 209.48)"
            className="st10"
            d="M949.61 205.79h73.72v7.38h-73.72z"
        />
      </defs>
      <clipPath id="SVGID_00000033340876311600005060000017693925307392888982_">
        <use
            xlinkHref="#SVGID_00000000936996501858395610000009521182367554319540_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g
          id="g_x5F_c2r"
          style={{
            clipPath:
                'url(#SVGID_00000033340876311600005060000017693925307392888982_)',
          }}
      >
        <path
            id="g_x5F_c2r_p"
            d="M1002.08 270.66v8.32l-31.22-20v-8.32zM1002.08 252.54v8.33l-31.22-20v-8.33zM1002.08 234.43v8.33l-31.22-20v-8.33zM1002.08 216.32v8.32l-31.22-20v-8.32zM1002.08 198.21v8.32l-31.22-20v-8.33zM1002.08 180.09v8.33l-31.22-20v-8.33z"/>
      </g>
    </g>
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000144308622041246968270000016366788822205756566_"
            className="st10"
            d="M354.25 157.34h118.2l-2.44-7.38H354.25v-6.16l-7.07 6.16h-.04v.04l-4.2 3.65 4.2 3.65v.04h.04l7.07 6.15z"
        />
      </defs>
      <clipPath id="SVGID_00000109738816180350983840000003722259886465522073_">
        <use
            xlinkHref="#SVGID_00000144308622041246968270000016366788822205756566_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g // 上左
          id="g_x5F_r2t1"
          style={{
            clipPath:
                'url(#SVGID_00000109738816180350983840000003722259886465522073_)',
          }}
      >
        <path
            id={'g_x5F_r2t1_p'}
            d="M340.82 169.26h-8.33l20.01-31.22h8.32zM358.93 169.26h-8.32l20-31.22h8.32zM377.05 169.26h-8.33l20-31.22h8.33zM395.16 169.26h-8.33l20-31.22h8.33zM413.27 169.26h-8.32l20-31.22h8.32zM431.38 169.26h-8.32l20-31.22h8.33zM449.5 169.26h-8.33l20-31.22h8.33zM467.61 169.26h-8.32l20-31.22h8.32zM485.72 169.26h-8.32l20-31.22h8.32z"/>
      </g>
    </g>
    <g className="st0">
      <path className="st1" d="m247.93 316.54-5.17-5.18 5.17-5.17"/>
      <path className="st2" d="M245.35 311.36h257.48M657.02 311.36h261.8"/>
    </g>
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000142140253785394677480000017622997832061054395_"
            className="st10"
            d="M786.18 157.34h-97.52l1.99-7.38h95.53z"
        />
      </defs>
      <clipPath id="SVGID_00000178926816496269479230000000981527764800193171_">
        <use
            xlinkHref="#SVGID_00000142140253785394677480000017622997832061054395_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
    </g>
    <path
        d="M1047 377.17H925.94c-3.46 0-6.3-2.83-6.3-6.3V252.63c0-3.46 2.83-6.3 6.3-6.3H1047c3.46 0 6.3 2.83 6.3 6.3v118.24c0 3.46-2.84 6.3-6.3 6.3z"
        className="st3 framed-object"
    />
    <path
        transform="rotate(90 301.684 319.383)"
        className="st6"
        d="M172.77 315.69h259v7.38H172.77z"
    />
    <path className="st6" d="m311.53 448.29-9.85 11.31-9.84-11.31z"/>
    <linearGradient
        id="SVGID_00000103229842165352239330000017468639355942224552_"
        gradientUnits="userSpaceOnUse"
        x1={1166.64}
        y1={863.663}
        x2={1185.64}
        y2={863.663}
        gradientTransform="rotate(180 745.5 613.69)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000103229842165352239330000017468639355942224552_)',
        }}
        d="M305.36 360.03h19v7.38h-19z"
    />
    <linearGradient
        id="SVGID_00000166645571307226763200000005608971095555809715_"
        gradientUnits="userSpaceOnUse"
        x1={301.685}
        y1={219.817}
        x2={301.685}
        y2={190.474}
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000166645571307226763200000005608971095555809715_)',
        }}
        d="M298 190.47h7.38v29.34H298z"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000139273718469204604160000016162376960120327080_"
            className="st10"
            d="M305.37 448.29V190.47H298v257.82h-6.16l9.84 11.31 9.85-11.31z"
        />
      </defs>
      <clipPath id="SVGID_00000091731255127922015560000004903175016394473636_">
        <use
            xlinkHref="#SVGID_00000139273718469204604160000016162376960120327080_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g // 左右
          id="g_x5F_t2p"
          style={{
            clipPath:
                'url(#SVGID_00000091731255127922015560000004903175016394473636_)',
          }}
      >
        <path
            id="g_x5F_t2p_p"
            d="M316.84 460.27v8.33l-31.23-20v-8.33zM316.84 442.16v8.32l-31.23-20v-8.32zM316.84 424.05v8.32l-31.23-20v-8.32zM316.84 405.93v8.33l-31.23-20v-8.33zM316.84 387.82v8.33l-31.23-20.01v-8.32zM316.84 369.71v8.32l-31.23-20v-8.32zM316.84 351.59v8.33l-31.23-20v-8.33zM316.84 333.48v8.33l-31.23-20v-8.33zM316.84 316.29v8.33l-31.23-20v-8.33zM316.84 298.18v8.32l-31.23-20v-8.32zM316.84 280.07v8.32l-31.23-20v-8.33zM316.84 261.95v8.33l-31.23-20v-8.33zM316.84 243.84v8.33l-31.23-20.01v-8.32zM316.84 225.73v8.32l-31.23-20v-8.32zM316.84 207.61v8.33l-31.23-20v-8.33zM316.84 189.5v8.33l-31.23-20v-8.33zM316.84 171.39v8.32l-31.23-20v-8.32z"/>
      </g>
    </g>
    <linearGradient
        id="SVGID_00000033369672631657186440000005248252851816387515_"
        gradientUnits="userSpaceOnUse"
        x1={596.935}
        y1={625.835}
        x2={596.935}
        y2={596.492}
        gradientTransform="rotate(90 756.118 312.833)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000033369672631657186440000005248252851816387515_)',
        }}
        d="M443.12 157.34v-7.38h26.9l2.44 7.38z"
    />
    <linearGradient
        id="SVGID_00000168812743668511785630000014824987222912699577_"
        gradientUnits="userSpaceOnUse"
        x1={596.935}
        y1={206.389}
        x2={596.935}
        y2={177.046}
        gradientTransform="rotate(90 756.118 312.833)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000168812743668511785630000014824987222912699577_)',
        }}
        d="M862.56 149.96h29.34v7.38h-29.34z"
    />
    <path
        transform="rotate(90 47.457 319.383)"
        className="st6"
        d="M-81.45 315.69h259v7.38H-81.45z"
    />
    <path className="st6" d="m57.3 448.29-9.84 11.31-9.85-11.31z"/>
    <linearGradient
        id="SVGID_00000093860413483010436270000010733260306094185614_"
        gradientUnits="userSpaceOnUse"
        x1={47.457}
        y1={219.817}
        x2={47.457}
        y2={190.474}
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000093860413483010436270000010733260306094185614_)',
        }}
        d="M43.77 190.47h7.38v29.34h-7.38z"
    />
    <linearGradient
        id="SVGID_00000018939975957962183380000002469414334388069541_"
        gradientUnits="userSpaceOnUse"
        x1={782.593}
        y1={529.661}
        x2={801.593}
        y2={529.661}
        gradientTransform="rotate(-90 715.5 258.69)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000018939975957962183380000002469414334388069541_)',
        }}
        d="M982.78 172.6h7.38v19h-7.38z"
    />
    <linearGradient
        id="SVGID_00000176758576587533772580000012709733693589074330_"
        gradientUnits="userSpaceOnUse"
        x1={494.19}
        y1={1207.341}
        x2={512.823}
        y2={1207.341}
        gradientTransform="rotate(90 1230.5 963.31)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000176758576587533772580000012709733693589074330_)',
        }}
        d="M982.78 227h7.38v18.63h-7.38z"
    />
    <path
        d="M1062.07 172.62H910.88c-10.43 0-18.97-8.54-18.97-18.97s8.54-18.97 18.97-18.97h151.19c10.43 0 18.97 8.54 18.97 18.97s-8.54 18.97-18.97 18.97z"
        style={{
          fill: '#282828',
          stroke: '#fff',
          strokeWidth: 0.8671,
          strokeMiterlimit: 10,
        }}
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000028292835762769648290000010899793465578013590_"
            className="st10"
            d="M51.15 448.29V190.47h-7.38v257.82h-6.16l9.85 11.31 9.84-11.31z"
        />
      </defs>
      <clipPath id="SVGID_00000141425287823667688560000007677241076709031100_">
        <use
            xlinkHref="#SVGID_00000028292835762769648290000010899793465578013590_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g // 左左
          id="g_x5F_t2d"
          style={{
            clipPath:
                'url(#SVGID_00000141425287823667688560000007677241076709031100_)',
          }}
      >
        <path
            id="g_x5F_t2d_p"
            d="M62.61 460.27v8.33l-31.23-20v-8.33zM62.61 442.16v8.32l-31.23-20v-8.32zM62.61 424.05v8.32l-31.23-20v-8.32zM62.61 405.93v8.33l-31.23-20v-8.33zM62.61 387.82v8.33l-31.23-20.01v-8.32zM62.61 369.71v8.32l-31.23-20v-8.32zM62.61 351.59v8.33l-31.23-20v-8.33zM62.61 333.48v8.33l-31.23-20v-8.33zM62.61 316.29v8.33l-31.23-20v-8.33zM62.61 298.18v8.32l-31.23-20v-8.32zM62.61 280.07v8.32l-31.23-20v-8.33zM62.61 261.95v8.33l-31.23-20v-8.33zM62.61 243.84v8.33l-31.23-20.01v-8.32zM62.61 225.73v8.32l-31.23-20v-8.32zM62.61 207.61v8.33l-31.23-20v-8.33zM62.61 189.5v8.33l-31.23-20v-8.33zM62.61 171.39v8.32l-31.23-20v-8.32z"/>
      </g>
    </g>
    <linearGradient
        id="SVGID_00000090294812874096479420000003560944880692544669_"
        gradientUnits="userSpaceOnUse"
        x1={530.548}
        y1={863.663}
        x2={549.548}
        y2={863.663}
        gradientTransform="matrix(1 0 0 -1 -251.553 1227.378)"
    >
      <stop
          offset={0}
          style={{
            stopColor: '#000',
            stopOpacity: 0,
          }}
      />
      <stop
          offset={1}
          style={{
            stopColor: '#000',
          }}
      />
    </linearGradient>
    <path
        style={{
          fill: 'url(#SVGID_00000090294812874096479420000003560944880692544669_)',
        }}
        d="M279 360.03h19v7.38h-19z"
    />
    <path
        d="M332.46 190.47H11.06c-3.12 0-5.67-2.55-5.67-5.67v-62.31c0-3.12 2.55-5.67 5.67-5.67h321.4c3.12 0 5.67 2.55 5.67 5.67v62.31c0 3.12-2.55 5.67-5.67 5.67z"
        className="st3 framed-object"
    />
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000054973801499013105310000007675622608183334284_"
            className="st10"
            d="M890.54 157.35H690.29l4.16-7.38h196.09z"
            // d="M893.54 157.35H690.29l4.16-7.38h199.09z"
        />
      </defs>
      <clipPath id="SVGID_00000159431271297307963420000000334080837402939555_">
        <use
            xlinkHref="#SVGID_00000054973801499013105310000007675622608183334284_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g
          id="g_x5F_r2t2"
          style={{
            clipPath:
                'url(#SVGID_00000159431271297307963420000000334080837402939555_)',
          }}
      >
        <path
            id="g_x5F_r2t2_p"
            d="M687.49 170.27h-8.32l20-31.22h8.33zM705.61 170.27h-8.33l20-31.22h8.33zM723.72 170.27h-8.33l20.01-31.22h8.32zM741.83 170.27h-8.32l20-31.22h8.32zM759.95 170.27h-8.33l20-31.22h8.33zM778.06 170.27h-8.33l20-31.22h8.33zM796.17 170.27h-8.32l20-31.22h8.32zM814.28 170.27h-8.32l20-31.22h8.33zM832.4 170.27h-8.33l20-31.22h8.33zM850.51 170.27h-8.33l20.01-31.22h8.32zM868.62 170.27h-8.32l20-31.22h8.32zM886.74 170.27h-8.33l20-31.22h8.33zM904.85 170.27h-8.33l20-31.22h8.33z"/>
      </g>
    </g>
    <g className="st10">
      <defs>
        <path
            id="SVGID_00000022543240051822506080000013463384417639168945_"
            className="st10"
            d="m251.96 353.87-11.3 9.85 11.3 9.85v-6.16H298v-7.38h-46.04v-6.16zm664.76 9.85-11.3-9.85v6.16H305.38v7.38h600.04v6.16l11.3-9.85z"
        />
      </defs>
      <clipPath id="SVGID_00000175322787343172309110000012240968533475800707_">
        <use
            xlinkHref="#SVGID_00000022543240051822506080000013463384417639168945_"
            style={{
              overflow: 'visible',
            }}
        />
      </clipPath>
      <g
          id="g_x5F_v2c"
          style={{
            clipPath:
                'url(#SVGID_00000175322787343172309110000012240968533475800707_)',
          }}
      >
        <path
            id="g_x5F_v2c_p"
            d="M201.73 379.33h-8.32l20-31.23h8.33zM219.85 379.33h-8.33l20-31.23h8.33zM237.96 379.33h-8.33l20.01-31.23h8.32zM256.07 379.33h-8.32l20-31.23h8.32zM274.19 379.33h-8.33l20-31.23h8.33zM292.3 379.33h-8.33l20-31.23h8.33zM310.41 379.33h-8.32l20-31.23h8.32zM328.52 379.33h-8.32l20-31.23h8.33zM345.72 379.33h-8.33l20-31.23h8.33zM363.83 379.33h-8.33l20-31.23h8.33zM381.94 379.33h-8.32l20-31.23h8.32zM400.05 379.33h-8.32l20-31.23h8.33zM418.17 379.33h-8.33l20-31.23h8.33zM436.28 379.33h-8.33l20.01-31.23h8.32zM454.39 379.33h-8.32l20-31.23h8.32zM472.51 379.33h-8.33l20-31.23h8.33zM490.62 379.33h-8.33l20-31.23h8.33zM508.73 379.33h-8.32l20-31.23h8.32zM526.84 379.33h-8.32l20-31.23h8.33zM544.96 379.33h-8.33l20-31.23h8.33zM563.07 379.33h-8.33l20.01-31.23h8.32zM581.18 379.33h-8.32l20-31.23h8.32zM599.3 379.33h-8.33l20-31.23h8.33zM617.41 379.33h-8.33l20-31.23h8.33zM779.26 379.33h-8.33l20-31.23h8.33zM797.37 379.33h-8.33l20-31.23h8.33zM815.48 379.33h-8.32l20-31.23h8.32zM833.59 379.33h-8.32l20-31.23h8.33zM851.71 379.33h-8.33l20-31.23h8.33zM869.82 379.33h-8.33l20.01-31.23h8.32zM887.93 379.33h-8.32l20-31.23h8.32zM906.05 379.33h-8.33l20-31.23h8.33zM634.6 379.33h-8.33l20-31.23h8.33zM652.71 379.33h-8.32l20-31.23h8.32zM670.82 379.33h-8.32l20-31.23h8.33zM688.94 379.33h-8.33l20-31.23h8.33zM707.05 379.33h-8.32l20-31.23h8.32zM725.16 379.33h-8.32l20-31.23h8.32zM743.28 379.33h-8.33l20-31.23h8.33zM761.39 379.33h-8.33l20.01-31.23h8.32z"/>
      </g>
    </g>

    <g>
      <g
          onClick={() => {
            openPlayDataDialog();
          }}
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          style={{
            pointerEvents: 'initial',
            cursor: 'pointer',
          }}
      >


        <g>
          <path d="M655.53,383.13H504.34c-10.43,0-18.97-8.54-18.97-18.97v0c0-10.43,8.54-18.97,18.97-18.97h151.19
			c10.43,0,18.97,8.54,18.97,18.97v0C674.5,374.6,665.97,383.13,655.53,383.13z"
                style={{
                  fill: '#282828',
                  stroke: '#fff',
                  strokeMiterlimit: 10,
                }}
          />

          <text transform="translate(519 370)" fill="#fff" fontSize="16"
                fontFamily="Roboto-Bold, Roboto" fontWeight="700">
            <tspan x="0" y="0">Music Play Data</tspan>
          </text>

          <g id="グループ_1034" transform="translate(-788 -3249)">
            <g id="グループ_1489">
              <circle id="楕円形_48" className="st1" cx="1444.65" cy="3612.96"
                      r="9"/>
              <g id="グループ_1022" transform="translate(16 1)">
                <rect id="長方形_863" x="1423.65" y="3610.96" width="10"
                      height="2"/>
                <rect id="長方形_864" x="1427.65" y="3606.96" width="2"
                      height="10"/>
              </g>
            </g>
          </g>
        </g>

        <g id="playdata-hover" className={hover ? 'hover' : ''}>
          <path d="M655.53,383.13H504.34c-10.43,0-18.97-8.54-18.97-18.97v0c0-10.43,8.54-18.97,18.97-18.97h151.19
			c10.43,0,18.97,8.54,18.97,18.97v0C674.5,374.6,665.97,383.13,655.53,383.13z"
                style={{
                  fill: '#fff',
                  stroke: '#000',
                  strokeMiterlimit: 10,
                }}
          />

          <text transform="translate(519 370)" fill="#000" fontSize="16"
                fontFamily="Roboto-Bold, Roboto" fontWeight="700">
            <tspan x="0" y="0">Music Play Data</tspan>
          </text>

          <g id="グループ_1034" transform="translate(-788 -3249)">
            <g id="グループ_1489">
              <circle id="楕円形_48" className="st1" cx="1444.65" cy="3612.96"
                      r="9"/>
              <g id="グループ_1022" transform="translate(16 1)">
                <rect id="長方形_863" x="1423.65" y="3610.96" width="10"
                      height="2"/>
                <rect id="長方形_864" x="1427.65" y="3606.96" width="2"
                      height="10"/>
              </g>
            </g>
          </g>
        </g>

        <rect id="venue-cover" transform="translate(475 335)" width="212"
              height="55" fill="rgba(255,28,28,0.5)" opacity="0"
        />
      </g>
      <g>

        <circle className="st1" cx="579.94" cy="383.1" r="3.94"/>
        <rect x="579.44" y="384.04" className="st1" width="1" height="81"/>

        <g transform="translate(336 -15)">
          <g transform="translate(165.494 489.167)">
            <g transform="translate(0.021)">
              <path
                  d="M253.846,521.391a.55.55,0,0,0,1.02-.02l10.306-31.328h6.742l-12.171,34.219a5.386,5.386,0,0,1-5.756,3.7c-2.961-.085-4.6-1.272-5.66-4.173l-12.009-33.712H243.2Z"
                  transform="translate(-158.774 -489.083)" fill="#fff"/>
              <rect width="6.18" height="37.642" transform="translate(0 0.974)"
                    fill="#fff"/>
              <path
                  d="M233.052,501.5a3.12,3.12,0,0,0-6.215.3h0l-.007,11.057a9.223,9.223,0,0,1-1.75,5.646,9.967,9.967,0,0,1-15.624-.09,9.556,9.556,0,0,1-1.7-5.811l0-22.55h-6.3l.007,22.532a19.039,19.039,0,0,0,.13,2.358,14.291,14.291,0,0,0,7.033,10.987,16.779,16.779,0,0,0,10.337,2.193,15.3,15.3,0,0,0,9.758-4.306,14.74,14.74,0,0,0,4.393-10.926Z"
                  transform="translate(-162.091 -489.082)" fill="#fff"/>
              <circle cx="3.148" cy="3.148" r="3.148"
                      transform="translate(64.687 1.016)" fill="#fff"/>
              <path
                  d="M304.767,494.9a19.621,19.621,0,0,0-22.5-3.735l2.677,5.713a13.294,13.294,0,1,1-7.185,13.772h0a3.149,3.149,0,1,0-6.22.966h0A19.593,19.593,0,1,0,304.767,494.9Z"
                  transform="translate(-155.427 -489.167)" fill="#fff"/>
              <circle cx="3.175" cy="3.175" r="3.175"
                      transform="translate(116.63 9.933)" fill="#fff"/>
              <path
                  d="M181.177,509.335a1.374,1.374,0,0,1,.024-2.15l15.093-17.134h-7.739l-12.978,14.843c-2.244,2.747-2.214,4.945.084,7.639l13.1,15.2,8.093-.01Z"
                  transform="translate(-164.713 -489.083)" fill="#fff"/>
            </g>
            <g transform="translate(0 55.521)">
              <path
                  d="M166.233,543.557v2.195h-.739v-5.606h2.068a2.079,2.079,0,0,1,1.442.47,1.592,1.592,0,0,1,.521,1.243,1.57,1.57,0,0,1-.51,1.257,2.165,2.165,0,0,1-1.461.441Zm0-.6h1.328a1.321,1.321,0,0,0,.908-.28,1.019,1.019,0,0,0,.317-.807,1.053,1.053,0,0,0-.317-.8,1.244,1.244,0,0,0-.866-.312h-1.37Z"
                  transform="translate(-165.494 -539.836)" fill="#fff"/>
              <path
                  d="M169.619,543.51a2.468,2.468,0,0,1,.24-1.1,1.794,1.794,0,0,1,.67-.755,1.827,1.827,0,0,1,.979-.265,1.749,1.749,0,0,1,1.377.589,2.264,2.264,0,0,1,.527,1.566v.05a2.492,2.492,0,0,1-.233,1.091,1.773,1.773,0,0,1-.667.753,1.842,1.842,0,0,1-1,.269,1.753,1.753,0,0,1-1.372-.589,2.253,2.253,0,0,1-.525-1.56Zm.715.085a1.781,1.781,0,0,0,.322,1.113,1.09,1.09,0,0,0,1.723-.005,1.957,1.957,0,0,0,.32-1.193,1.767,1.767,0,0,0-.326-1.109,1.033,1.033,0,0,0-.864-.426,1.021,1.021,0,0,0-.85.419A1.934,1.934,0,0,0,170.334,543.6Z"
                  transform="translate(-165.101 -539.718)" fill="#fff"/>
              <path
                  d="M177.474,544.644l.8-3.184h.712l-1.212,4.166H177.2l-1.013-3.157-.987,3.157h-.577l-1.208-4.166h.707l.82,3.119.97-3.119h.574Z"
                  transform="translate(-164.74 -539.711)" fill="#fff"/>
              <path
                  d="M180.763,545.709a1.819,1.819,0,0,1-1.378-.556,2.064,2.064,0,0,1-.531-1.488v-.13a2.506,2.506,0,0,1,.237-1.107,1.851,1.851,0,0,1,.661-.762,1.675,1.675,0,0,1,.923-.275,1.559,1.559,0,0,1,1.262.534,2.312,2.312,0,0,1,.452,1.532v.3h-2.823a1.484,1.484,0,0,0,.36,1,1.128,1.128,0,0,0,.875.379,1.235,1.235,0,0,0,.64-.154,1.615,1.615,0,0,0,.458-.407l.436.34A1.752,1.752,0,0,1,180.763,545.709Zm-.088-3.734a.952.952,0,0,0-.724.313,1.526,1.526,0,0,0-.363.88h2.087v-.054a1.363,1.363,0,0,0-.292-.842A.9.9,0,0,0,180.675,541.975Z"
                  transform="translate(-164.222 -539.718)" fill="#fff"/>
              <path
                  d="M184.847,542.105a2.176,2.176,0,0,0-.35-.026.937.937,0,0,0-.951.6v2.958h-.713v-4.166h.693l.012.481a1.11,1.11,0,0,1,.993-.557.743.743,0,0,1,.315.053Z"
                  transform="translate(-163.844 -539.718)" fill="#fff"/>
              <path
                  d="M187.014,545.709a1.819,1.819,0,0,1-1.378-.556,2.064,2.064,0,0,1-.531-1.488v-.13a2.506,2.506,0,0,1,.237-1.107,1.851,1.851,0,0,1,.661-.762,1.674,1.674,0,0,1,.923-.275,1.559,1.559,0,0,1,1.262.534,2.312,2.312,0,0,1,.452,1.532v.3h-2.823a1.489,1.489,0,0,0,.359,1,1.132,1.132,0,0,0,.876.379,1.235,1.235,0,0,0,.64-.154,1.614,1.614,0,0,0,.458-.407l.436.34A1.752,1.752,0,0,1,187.014,545.709Zm-.088-3.734a.952.952,0,0,0-.724.313,1.526,1.526,0,0,0-.363.88h2.087v-.054a1.363,1.363,0,0,0-.292-.842A.9.9,0,0,0,186.926,541.975Z"
                  transform="translate(-163.627 -539.718)" fill="#fff"/>
              <path
                  d="M188.926,543.66a2.443,2.443,0,0,1,.455-1.542,1.547,1.547,0,0,1,2.349-.082v-2.173h.712v5.915h-.654l-.036-.448a1.445,1.445,0,0,1-1.189.525,1.426,1.426,0,0,1-1.18-.592,2.468,2.468,0,0,1-.457-1.549Zm.713.081a1.846,1.846,0,0,0,.292,1.108.946.946,0,0,0,.809.4,1.04,1.04,0,0,0,.989-.608v-1.914a1.048,1.048,0,0,0-.982-.589.948.948,0,0,0-.816.405A2.034,2.034,0,0,0,189.639,543.741Z"
                  transform="translate(-163.264 -539.863)" fill="#fff"/>
              <path
                  d="M198.455,543.741a2.479,2.479,0,0,1-.439,1.534,1.4,1.4,0,0,1-1.178.579,1.454,1.454,0,0,1-1.221-.559l-.034.482h-.655v-5.915h.713v2.207a1.445,1.445,0,0,1,1.189-.534,1.411,1.411,0,0,1,1.192.573,2.551,2.551,0,0,1,.434,1.572Zm-.712-.081a1.922,1.922,0,0,0-.281-1.124.932.932,0,0,0-.809-.4,1.046,1.046,0,0,0-1.012.655v1.8a1.074,1.074,0,0,0,1.02.654.926.926,0,0,0,.8-.4A2.042,2.042,0,0,0,197.743,543.66Z"
                  transform="translate(-162.693 -539.863)" fill="#fff"/>
              <path
                  d="M200.368,544.582l.971-3.122h.761l-1.675,4.809q-.388,1.038-1.235,1.039l-.135-.012-.266-.049v-.577l.193.014a.947.947,0,0,0,.564-.146,1.054,1.054,0,0,0,.333-.536l.159-.423-1.487-4.12h.778Z"
                  transform="translate(-162.348 -539.711)" fill="#fff"/>
            </g>
          </g>
          <g transform="translate(216.188 538.001)">
            <path
                d="M221.289,537.2V534.95a1.055,1.055,0,0,1,1.194-1.193h.791a1.049,1.049,0,0,1,1.182,1.193v9.9a4.758,4.758,0,0,1-4.749,4.76h-3.165a4.758,4.758,0,0,1-4.76-4.76v-3.166a4.767,4.767,0,0,1,4.76-4.76h3.165A4.6,4.6,0,0,1,221.289,537.2Zm-1.583,2.9h-3.165a1.6,1.6,0,0,0-1.595,1.583v3.166a1.6,1.6,0,0,0,1.595,1.594h3.165a1.606,1.606,0,0,0,1.583-1.594v-3.2a1.565,1.565,0,0,0-.484-1.093A1.511,1.511,0,0,0,219.707,540.1Z"
                transform="translate(-211.782 -533.757)" fill="#fff"/>
            <path
                d="M225.747,545.628V539.7a1.054,1.054,0,0,1,1.193-1.193h.791a1.054,1.054,0,0,1,1.193,1.193v9.909a4.546,4.546,0,0,1-1.41,3.355,4.605,4.605,0,0,1-3.35,1.393h-1.972a.107.107,0,0,1-.039-.005.182.182,0,0,0-.05-.005h-.022a1.051,1.051,0,0,1-1.07-1.171v-.814a1.044,1.044,0,0,1,1.182-1.181h1.572a3.385,3.385,0,0,0,1.092-.157,1.537,1.537,0,0,0,.893-1.4v-3.991Zm1.193-11.871h.791a1.054,1.054,0,0,1,1.193,1.193v.791a1.049,1.049,0,0,1-1.193,1.182h-.791a1.049,1.049,0,0,1-1.193-1.182v-.791A1.054,1.054,0,0,1,226.939,533.757Z"
                transform="translate(-210.904 -533.757)" fill="#fff"/>
            <path
                d="M236.856,536.647h1.584a4.577,4.577,0,0,1,3.165,1.227,4.593,4.593,0,0,1,3.177-1.227h3.165a4.777,4.777,0,0,1,4.76,4.76v6.733a1.054,1.054,0,0,1-1.193,1.193h-.792a1.054,1.054,0,0,1-1.193-1.193v-6.733a1.6,1.6,0,0,0-1.583-1.583h-3.165a1.6,1.6,0,0,0-1.595,1.583v6.733a1.049,1.049,0,0,1-1.181,1.193h-.792a1.054,1.054,0,0,1-1.193-1.193v-6.733a1.609,1.609,0,0,0-1.583-1.583h-3.166a1.6,1.6,0,0,0-1.593,1.583v6.733a1.05,1.05,0,0,1-1.182,1.193h-.792a1.054,1.054,0,0,1-1.193-1.193v-6.733a4.767,4.767,0,0,1,4.76-4.76Z"
                transform="translate(-209.999 -533.482)" fill="#fff"/>
            <path
                d="M256.546,536.647h3.165a4.768,4.768,0,0,1,4.749,4.76v3.166a4.758,4.758,0,0,1-4.749,4.76h-3.165a4.758,4.758,0,0,1-4.76-4.76v-3.166a4.768,4.768,0,0,1,4.76-4.76Zm0,3.177a1.6,1.6,0,0,0-1.595,1.583v3.166a1.6,1.6,0,0,0,1.595,1.594h3.165a1.606,1.606,0,0,0,1.583-1.594v-3.166a1.607,1.607,0,0,0-1.583-1.583Z"
                transform="translate(-207.974 -533.482)" fill="#fff"/>
            <path
                d="M270.85,536.647h1.583a4.767,4.767,0,0,1,4.749,4.76v6.733A1.05,1.05,0,0,1,276,549.333h-.792a1.054,1.054,0,0,1-1.193-1.193v-6.733a1.607,1.607,0,0,0-1.583-1.583h-3.165a1.6,1.6,0,0,0-1.595,1.583v6.733a1.049,1.049,0,0,1-1.182,1.193H265.7a1.054,1.054,0,0,1-1.193-1.193v-6.733a4.767,4.767,0,0,1,4.76-4.76Z"
                transform="translate(-206.763 -533.482)" fill="#fff"/>
            <path
                d="M278.423,533.757h.792a1.05,1.05,0,0,1,1.182,1.193v.791a1.044,1.044,0,0,1-1.182,1.182h-.792a1.049,1.049,0,0,1-1.193-1.182v-.791A1.054,1.054,0,0,1,278.423,533.757Zm0,4.76h.792A1.045,1.045,0,0,1,280.4,539.7v8.717a1.05,1.05,0,0,1-1.182,1.193h-.792a1.054,1.054,0,0,1-1.193-1.193V539.7A1.049,1.049,0,0,1,278.423,538.517Z"
                transform="translate(-205.552 -533.757)" fill="#fff"/>
            <path
                d="M284.294,544.848a1.308,1.308,0,0,0,.6,1.281,2.272,2.272,0,0,0,1,.312v-.011h.39a1.054,1.054,0,0,1,1.193,1.193v.792a1.049,1.049,0,0,1-1.193,1.181h-.245a.732.732,0,0,1-.145.012,4.758,4.758,0,0,1-4.76-4.76v-9.9a1.054,1.054,0,0,1,1.193-1.193h.792a1.05,1.05,0,0,1,1.182,1.193v1.972h1.983a1.054,1.054,0,0,1,1.193,1.193v.792a1.054,1.054,0,0,1-1.193,1.193h-1.983Z"
                transform="translate(-205.181 -533.757)" fill="#fff"/>
            <path
                d="M292.85,536.647h3.165a4.767,4.767,0,0,1,4.749,4.76v3.166a4.758,4.758,0,0,1-4.749,4.76H292.85a4.758,4.758,0,0,1-4.76-4.76v-3.166a4.767,4.767,0,0,1,4.76-4.76Zm0,3.177a1.6,1.6,0,0,0-1.595,1.583v3.166a1.6,1.6,0,0,0,1.595,1.594h3.165a1.606,1.606,0,0,0,1.583-1.594v-3.166a1.607,1.607,0,0,0-1.583-1.583Z"
                transform="translate(-204.519 -533.482)" fill="#fff"/>
            <path
                d="M303.977,541.407v6.733a1.049,1.049,0,0,1-1.181,1.193H302a1.054,1.054,0,0,1-1.193-1.193v-6.733a4.767,4.767,0,0,1,4.76-4.76.456.456,0,0,1,.067.005.41.41,0,0,0,.078.005h.245a1.054,1.054,0,0,1,1.193,1.193v.792a1.049,1.049,0,0,1-1.193,1.182h-.39a2.3,2.3,0,0,0-1,.307A1.3,1.3,0,0,0,303.977,541.407Z"
                transform="translate(-203.308 -533.482)" fill="#fff"/>
          </g>
        </g>

      </g>
    </g>

    <text transform="translate(513 95)" fill="#fff" fontSize="20"
          fontFamily="Roboto-Bold, Roboto" fontWeight="700">
      <tspan x="0" y="0">Music Creators</tspan>
    </text>
    <g transform="translate(120 293)">
      <text transform="translate(20.147 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">Ve</tspan>
        <tspan y="0" letterSpacing="0.029em">n</tspan>
        <tspan y="0" letterSpacing="0.03em">ue</tspan>
        <tspan y="0" letterSpacing="0.029em">s&nbsp;</tspan>
        <tspan y="0">/</tspan>
      </text>
      <text transform="translate(-3 32.827)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">Mu</tspan>
        <tspan y="0" letterSpacing="0.029em">sic&nbsp;</tspan>
        <tspan y="0" letterSpacing="0.03em">F</tspan>
        <tspan y="0" letterSpacing="0.029em">es</tspan>
        <tspan y="0" letterSpacing="0.03em">t</tspan>
        <tspan y="0" letterSpacing="0.029em">iva</tspan>
        <tspan y="0">l</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>
    <g transform="translate(810 192)">
      <text transform="translate(0 11.977)" fill="#fff" fontSize="14"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">Assignment of</tspan>
      </text>
      <text transform="translate(24.08 31.982)" fill="#fff" fontSize="14"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">Rights</tspan>
      </text>
    </g>
    <g transform="translate(60 520)">
      <text transform="translate(0 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">DJ</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>
    <g transform="translate(235 511)">
      <text transform="translate(19.032 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">Mu</tspan>
        <tspan y="0" letterSpacing="0.029em">si</tspan>
        <tspan y="0">c</tspan>
      </text>
      <text transform="translate(-0.003 32.827)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.029em">Pe</tspan>
        <tspan y="0" letterSpacing="0.03em">r</tspan>
        <tspan y="0" letterSpacing="0.029em">fo</tspan>
        <tspan y="0" letterSpacing="0.03em">rm</tspan>
        <tspan y="0" letterSpacing="0.029em">e</tspan>
        <tspan y="0" letterSpacing="0.03em">r</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>

    <g transform="translate(513 136)">
      <text transform="translate(0 10.266)" fill="#fff" fontSize="12"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.039em">(&nbsp;</tspan>
        <tspan y="0" letterSpacing="0.024em">W</tspan>
        <tspan y="0" letterSpacing="0.036em">r</tspan>
        <tspan y="0" letterSpacing="0.039em">i</tspan>
        <tspan y="0" letterSpacing="0.03em">t</tspan>
        <tspan y="0" letterSpacing="0.046em">e</tspan>
        <tspan y="0" letterSpacing="0.043em">r</tspan>
        <tspan y="0" letterSpacing="0.039em">s /&nbsp;</tspan>
        <tspan y="0" letterSpacing="0.044em">C</tspan>
        <tspan y="0" letterSpacing="0.041em">omp</tspan>
        <tspan y="0" letterSpacing="0.038em">o</tspan>
        <tspan y="0" letterSpacing="0.034em">s</tspan>
        <tspan y="0" letterSpacing="0.046em">e</tspan>
        <tspan y="0" letterSpacing="0.043em">r</tspan>
        <tspan y="0" letterSpacing="0.039em">s&nbsp;</tspan>
        <tspan y="0">)</tspan>
      </text>
      <text transform="translate(8.813 31.266)" fill="#fff" fontSize="12"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.039em">(&nbsp;</tspan>
        <tspan y="0" letterSpacing="0.029em">R</tspan>
        <tspan y="0" letterSpacing="0.045em">e</tspan>
        <tspan y="0" letterSpacing="0.041em">co</tspan>
        <tspan y="0" letterSpacing="0.042em">r</tspan>
        <tspan y="0" letterSpacing="0.039em">d</tspan>
        <tspan y="0" letterSpacing="0.041em">in</tspan>
        <tspan y="0" letterSpacing="0.04em">g</tspan>
        <tspan y="0" letterSpacing="0.039em">&nbsp;</tspan>
        <tspan y="0" letterSpacing="0.048em">A</tspan>
        <tspan y="0" letterSpacing="0.064em">r</tspan>
        <tspan y="0" letterSpacing="0.036em">ti</tspan>
        <tspan y="0" letterSpacing="0.048em">s</tspan>
        <tspan y="0" letterSpacing="0.045em">t</tspan>
        <tspan y="0" letterSpacing="0.039em">s&nbsp;</tspan>
        <tspan y="0">)</tspan>
      </text>
    </g>

    <g transform="translate(850 511)">
      <text transform="translate(16.535 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">Mu</tspan>
        <tspan y="0" letterSpacing="0.029em">si</tspan>
        <tspan y="0">c</tspan>
      </text>
      <text transform="translate(0.005 33.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.029em">P</tspan>
        <tspan y="0" letterSpacing="0.03em">ub</tspan>
        <tspan y="0" letterSpacing="0.029em">lis</tspan>
        <tspan y="0" letterSpacing="0.03em">h</tspan>
        <tspan y="0" letterSpacing="0.029em">e</tspan>
        <tspan y="0" letterSpacing="0.03em">r</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>
    <g transform="translate(1065 520)">
      <text transform="translate(0 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.03em">L</tspan>
        <tspan y="0" letterSpacing="0.029em">a</tspan>
        <tspan y="0" letterSpacing="0.03em">b</tspan>
        <tspan y="0" letterSpacing="0.029em">el</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>

    <g transform="translate(965 303)">
      <text transform="translate(0 12.832)" fill="#fff" fontSize="15"
            fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0" letterSpacing="0.029em">C</tspan>
        <tspan y="0" letterSpacing="0.03em">M</tspan>
        <tspan y="0" letterSpacing="0.029em">O</tspan>
        <tspan y="0">s</tspan>
      </text>
    </g>

    <text transform="translate(922 159)" fill="#fff" fontSize="15"
          fontFamily="Roboto-Bold, Roboto" fontWeight="700">
      <tspan x="0" y="0" letterSpacing="0.029em">Ro</tspan>
      <tspan y="0" letterSpacing="0.03em">y</tspan>
      <tspan y="0" letterSpacing="0.029em">al</tspan>
      <tspan y="0" letterSpacing="0.03em">ty</tspan>
      <tspan y="0" letterSpacing="0.029em"> Pa</tspan>
      <tspan y="0" letterSpacing="0.03em">yme</tspan>
      <tspan y="0" letterSpacing="0.029em">n</tspan>
      <tspan y="0" letterSpacing="0.03em">t</tspan>
      <tspan y="0">s</tspan>
    </text>

    <text transform="translate(82 161)" fill="#fff" fontSize="15"
          fontFamily="Roboto-Bold, Roboto" fontWeight="700">
      <tspan x="0" y="0" letterSpacing="0.017em">R</tspan>
      <tspan y="0" letterSpacing="0.034em">e</tspan>
      <tspan y="0" letterSpacing="0.031em">c</tspan>
      <tspan y="0" letterSpacing="0.03em">o</tspan>
      <tspan y="0" letterSpacing="0.031em">r</tspan>
      <tspan y="0" letterSpacing="0.028em">d</tspan>
      <tspan y="0" letterSpacing="0.029em">s /&nbsp;</tspan>
      <tspan y="0" letterSpacing="-0.049em">T</tspan>
      <tspan y="0" letterSpacing="0.032em">r</tspan>
      <tspan y="0" letterSpacing="0.026em">a</tspan>
      <tspan y="0" letterSpacing="0.032em">c</tspan>
      <tspan y="0" letterSpacing="0.018em">k</tspan>
      <tspan y="0" letterSpacing="0.029em">s /&nbsp;</tspan>
      <tspan y="0" letterSpacing="0.031em">S</tspan>
      <tspan y="0" letterSpacing="0.032em">o</tspan>
      <tspan y="0" letterSpacing="0.029em">n</tspan>
      <tspan y="0" letterSpacing="0.028em">g</tspan>
      <tspan y="0">s</tspan>
    </text>

    <text fillOpacity={0.7}
          transform="translate(543 267)" fill="#fff" fontSize="14"
          fontFamily="Roboto-Bold, Roboto" fontWeight="700">
      <tspan x="0" y="0">Licence Fee</tspan>
    </text>

    <text transform="translate(522 316)" fill="#fff" fontSize="14"
          fillOpacity={0.7}
          fontFamily="Roboto-Bold, Roboto" fontWeight="700">
      <tspan x="0" y="0">Music Use Licence</tspan>
    </text>

    <g transform="translate(127 404)">
      <text transform="translate(25.484 11.977)" fill="#fff" fillOpacity={0.7}
            fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">Music</tspan>
      </text>
      <text transform="translate(0.004 31.982)" fill="#fff" fillOpacity={0.7}
            fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">Performances</tspan>
      </text>
    </g>

    <g transform="translate(950 404)">
      <text transform="translate(0 11.977)" fillOpacity={0.7} fill="#fff"
            fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">Assignment</tspan>
      </text>
      <text transform="translate(7.658 31.982)" fillOpacity={0.7} fill="#fff"
            fontSize="14" fontFamily="Roboto-Bold, Roboto" fontWeight="700">
        <tspan x="0" y="0">of Rights</tspan>
      </text>
    </g>

  </svg>);
}